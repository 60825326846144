import {
  AppointmentTypeScheduleDto,
  ScheduleAvailabilityDto,
  UpdateAppointmentTypeScheduleDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import { logError } from 'assets/logging/logger';
import AppointmentService from '../../api/AppointmentService';
import { useToast } from '../../common/hooks/useToast';
import { useAppStateStore } from '../../store/app-store';
import { useAvailabilitiesListState } from './availabilities-list-store';

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : 'An error occurred while trying to load the availabilities. Please try again.';

  logError(error);
  useAvailabilitiesListState.setState({
    error: {
      message: message,
    },
    status: 'error',
  });

  toast('Error', { type: 'error', content: message });
};

export const getSchedules = async () => {
  const gridApi = useAvailabilitiesListState.getState().gridApi;

  useAvailabilitiesListState.setState({ status: 'loading' });
  gridApi?.showLoadingOverlay();

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    const response = await AppointmentService.findAppointmentTypeSchedules(
      pharmacyId,
      locationId,
    );

    useAvailabilitiesListState.setState({
      status: 'success',
      schedules: response,
    });
  } catch (error: any) {
    errorOccurred(error);
  } finally {
    gridApi?.hideOverlay();
  }
};

export const resetAvailabilitiesList = () => {
  setReset(true);
};

export const setReset = (reset: boolean) => {
  useAvailabilitiesListState.setState({ reset });
};

export const setContextMenuSchedule = (
  schedule: AppointmentTypeScheduleDto,
) => {
  useAvailabilitiesListState.setState({ contextMenuSchedule: schedule });
};

export const setDefaultSchedule = async (
  schedule: AppointmentTypeScheduleDto,
) => {
  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();
    const { schedules } = useAvailabilitiesListState.getState();
    const scheduleIndex = schedules?.findIndex(
      (item) => item.id === schedule.id,
    );

    const newSchedules = schedules?.map((item) => ({
      ...item,
      isDefault: false,
    }));

    const updatedSchedule: AppointmentTypeScheduleDto = {
      ...schedule,
      isDefault: !schedule.isDefault,
    };

    const updatedScheduleDto: UpdateAppointmentTypeScheduleDto = {
      ...updatedSchedule,
      availability: parseAvailabilities(schedule.availability),
    };

    if (newSchedules && scheduleIndex !== undefined) {
      newSchedules[scheduleIndex] = updatedSchedule;
    }

    useAvailabilitiesListState.setState({ schedules: newSchedules });

    await AppointmentService.updateAppointmentTypeSchedule(
      pharmacyId,
      locationId,
      schedule.id,
      updatedScheduleDto,
    );
  } catch (error: any) {
    errorOccurred(
      error,
      'An error occurred while trying to set the default availability.',
    );
  }
};

const parseAvailabilities = (availabilities: ScheduleAvailabilityDto[]) => {
  return availabilities.map((availability) => ({
    startTime: availability.startTime,
    endTime: availability.endTime,
    days: availability.days,
    date: availability.date ? availability.date : undefined,
  }));
};
