import React from 'react';
import { Platform } from 'react-native';
import { TypeaheadNative } from './TypeaheadNative';
import { TypeaheadWeb } from './TypeaheadWeb';
import { TypeaheadBaseItem, TypeaheadProps } from './types';

export const Typeahead = <T extends string | TypeaheadBaseItem>({
  platform = Platform.OS,
  ...rest
}: TypeaheadProps<T>) => {
  return platform === 'web' ? (
    <TypeaheadWeb {...rest} platform={platform} />
  ) : (
    <TypeaheadNative {...rest} platform={platform} />
  );
};
