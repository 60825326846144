import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { makeStyles, useTheme } from '../../theme';
import { Text } from '../text';
import { Typeahead } from './Typeahead';
import { TypeaheadBaseItem, TypeaheadProps } from './types';

export const TypeaheadFormControl: FunctionComponent<
  PropsWithChildren<TypeaheadFormControlProps>
> = ({ name, rules, typeaheadProps }) => {
  const theme = useTheme();
  const styles = useStyles();
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error('TypeaheadFormControl must have a parent form context');
  }

  const { control, formState } = formContext;
  const error = formState.errors[name];

  return (
    <>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <Typeahead
            {...typeaheadProps}
            onChange={(e) => {
              onChange(e);
            }}
            defaultValue={value}
          />
        )}
        name={name}
        rules={rules}
      />
      {!!error && (
        <Text
          testID={TypeaheadFormControlTestIDs.error}
          style={styles.errorMessage}
        >
          {error.message}
        </Text>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 14,
    marginTop: theme.getSpacing(1),
  },
}));

export interface TypeaheadFormControlProps {
  name: string;
  rules?: RegisterOptions;
  typeaheadProps: TypeaheadProps<TypeaheadBaseItem | string>;
}

export const TypeaheadFormControlTestIDs = {
  error: 'typeahead-formControl-error',
};
