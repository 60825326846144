import React, { FunctionComponent, PropsWithChildren } from 'react';
import * as Clipboard from 'expo-clipboard';
import { IconButton } from '../icon-button/IconButton';
import { CopyToClipboardIcon } from '../../icons/CopyToClipboardIcon';

export const CopyToClipboard: FunctionComponent<
  PropsWithChildren<CopyToClipboardProps>
> = ({ stringToCopy, logger, fetchFromClipboard, color, size }) => {
  const copyToClipboard = async () => {
    await Clipboard.setStringAsync(stringToCopy);
    fetchFromClipboard && fetchFromClipboard();
  };

  return (
    <IconButton
      icon={CopyToClipboardIcon}
      onPress={copyToClipboard}
      logger={logger}
      color={color}
      size={size}
      testID={CopyToClipboardTestIDs.copyToClipboard}
    />
  );
};

export interface CopyToClipboardProps {
  stringToCopy: string;
  logger: { id: string; data?: Object | (() => Object) };
  fetchFromClipboard?: () => void;
  color?: string;
  size?: number;
}

export const CopyToClipboardTestIDs = {
  copyToClipboard: 'copy-to-clipboard',
};
