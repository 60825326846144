import {
  ClipboardIcon,
  HeartIcon,
  MedicationIcon,
  YoutubeIcon,
} from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useVideosState } from '../../store/videos-store';
import VideosFilterButton from './VideosFilterButton';

type VideoFilterButton = {
  video_type: number;
  name: string;
  icon: React.FunctionComponent;
};

const CategoryFilter: React.FC = () => {
  const styles = useStyles();
  const { category, setCategory } = useVideosState();
  const VideosFilterButtonList: VideoFilterButton[] = [
    { video_type: 0, icon: YoutubeIcon, name: getText('videos-all') },
    { video_type: 1, icon: MedicationIcon, name: getText('videos-medication') },
    {
      video_type: 3,
      icon: ClipboardIcon,
      name: getText('videos-administration'),
    },
    { video_type: 5, icon: HeartIcon, name: getText('videos-general-health') },
  ];

  const handleCharSelection = (video_type: number) => {
    setCategory(video_type);
  };

  return (
    <View style={styles.sidebar}>
      {VideosFilterButtonList.map((button) => (
        <TouchableOpacity
          key={button.video_type}
          onPress={() => handleCharSelection(button.video_type)}
        >
          <VideosFilterButton
            icon={button.icon}
            name={button.name}
            active={button.video_type == category}
          />
        </TouchableOpacity>
      ))}
    </View>
  );
};

export default CategoryFilter;

const useStyles = makeStyles(() => ({
  sidebar: {
    backgroundColor: '#F5F9FD',
    margin: 0,
  },
  avatar: {},
}));
