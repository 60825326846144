import { ListFormDto } from '@digitalpharmacist/forms-service-client-axios';
import create from 'zustand';
import { ApiError, AsyncStatus } from '../../store/types';
import { GridApi } from '@ag-grid-community/core';

export const useFormsDataTableState = create<FormsDataTableState>((set) => ({
  status: 'idle',
  activeForms: undefined,
  inactiveForms: undefined,
  gridApi: undefined,
  downloadLoading: undefined,
}));

interface FormsDataTableState {
  status: AsyncStatus;
  error?: ApiError;
  activeForms?: ListFormDto[];
  gridApi?: GridApi;
  inactiveForms?: ListFormDto[];
  downloadLoading?: string;
  contextMenuFormDetails?: ListFormDto;
}
