export class TypeaheadService {
  private selectedItems: any[] = [];

  setSelectedItems(newValue: any[]) {
    this.selectedItems = newValue;
  }

  getSelectedItems() {
    return this.selectedItems;
  }
}
