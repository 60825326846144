import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  useEffect,
} from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { View } from 'react-native';
import { makeStyles } from '../../../../../packages/assets/theme';
import { zIndexAuto } from '../../common/theme';

export const ComboInput: FunctionComponent<
  PropsWithChildren<ComboInputProps>
> = ({
  conditionInput,
  input,
  name,
  mode = ComboInputMode.Disable,
  layout = ComboInputLayout.Horizontal,
}) => {
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error('Date Time Picker must have a parent form context');
  }

  const { control, setValue } = formContext;
  const conditionWatch = useWatch({ name: `condition-${name}` });
  const inputWatch = useWatch({ name: `input-${name}` });
  const showInput = mode === ComboInputMode.Disable ? true : conditionWatch;
  const styles = useStyles();

  useEffect(() => {
    setValue(name, null);
  }, []);

  useEffect(() => {
    if (!conditionWatch || !inputWatch) {
      setValue(name, null);
    } else {
      setValue(name, inputWatch);
    }
  }, [conditionWatch, inputWatch]);

  return (
    <View style={{ zIndex: zIndexAuto }}>
      <Controller
        control={control}
        name={`${name}`}
        render={({ field }) => (
          <View
            style={[
              styles.container,
              layout === ComboInputLayout.Horizontal &&
                styles.containerHorizontal,
            ]}
          >
            <View style={{ marginRight: 20 }}>
              {React.createElement(conditionInput, {
                ...field,
                name: `condition-${name}`,
              })}
            </View>
            {showInput && (
              <View style={{ zIndex: zIndexAuto, flex: 1 }}>
                {React.createElement(input, {
                  ...field,
                  disabled: !conditionWatch,
                  name: `input-${name}`,
                })}
              </View>
            )}
          </View>
        )}
      />
    </View>
  );
};

export enum ComboInputMode {
  Hide = 'hide',
  Disable = 'disable',
}

export enum ComboInputLayout {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export interface ComboInputProps {
  conditionInput: FunctionComponent<PropsWithChildren<any>>;
  input: FunctionComponent<PropsWithChildren<any>>;
  name: string;
  mode?: ComboInputMode;
  layout?: ComboInputLayout;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    zIndex: zIndexAuto,
  },
  containerHorizontal: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
