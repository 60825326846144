import React, { FunctionComponent, PropsWithChildren } from 'react';
import { ScreenContainer } from 'assets/layout';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import { Button } from 'assets/components/button';
import { logout } from '../login/login-actions';
import { useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';

export const Dashboard: FunctionComponent<PropsWithChildren<DashboardProps>> = (
  props,
) => {
  const theme = useTheme();
  return (
    <ScreenContainer showVersion={false}>
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={{ marginBottom: theme.getSpacing(4) }}>
          {getText('dashboard')}
        </Text>
        <Button
          hierarchy="primary"
          onPress={() => logout()}
          logger={{ id: 'logout-button' }}
          style={{
            backgroundColor: theme.colors.pharmacyPrimary,
            borderColor: theme.colors.pharmacyPrimary,
          }}
        >
          {getText('logout')}
        </Button>
      </View>
    </ScreenContainer>
  );
};

interface DashboardProps {}
