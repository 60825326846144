import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { View } from 'react-native';
import { makeStyles } from 'assets/theme';
import { Tab, TabProps } from './Tab';
import { TabContent, TabContentProps } from './TabContent';

export const Tabs: FunctionComponent<PropsWithChildren<TabsProps>> = ({
  tabs,
  defaultTab,
  onTabChange,
}) => {
  const styles = useStyles();
  const [activeTab, setActiveTab] = useState<string>(defaultTab);

  const handlePress = (pressedTab: string) => {
    setActiveTab(pressedTab);

    if (onTabChange) {
      onTabChange(pressedTab);
    }
  };

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);

  return (
    <View>
      <View style={styles.topBar}>
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            title={tab.title}
            id={tab.id}
            isActive={tab.id === activeTab}
            icon={tab.icon}
            onPress={handlePress}
          />
        ))}
      </View>
      <View style={styles.content}>
        {tabs.map((tab) => (
          <TabContent key={tab.id} isActive={tab.id === activeTab}>
            {tab.children}
          </TabContent>
        ))}
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  topBar: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.gray[100],
    paddingTop: theme.getSpacing(2),
    paddingHorizontal: theme.getSpacing(2),
  },
  content: {
    backgroundColor: theme.palette.white,
    paddingTop: theme.getSpacing(1),
  },
}));

export interface TabsProps {
  tabs: Omit<TabProps & TabContentProps, 'isActive' | 'onPress'>[];
  defaultTab: string;
  onTabChange?: (activeTab: string) => void;
}
