import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { makeStyles, useTheme } from '../../theme';
import { CheckIcon } from '../../icons';
import { Text } from '../text';
import Select from 'react-select';

export const DropdownSelectWeb: FunctionComponent<
  PropsWithChildren<DropdownSelectWebProps>
> = ({
  label,
  options,
  disabled,
  fieldName,
  formContext,
  placeholder,
  isSearchable,
  navBarDropdown,
  fontColor,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const handleOnchange = (option: { label?: string; value: any }) => {
    formContext.setValue(fieldName, option.value);
  };

  const value = formContext.watch(fieldName);
  const inputText = options.find((x) => x.value === value)?.label;

  return (
    <>
      <View>
        {label && (
          <Text style={styles.label} testID={DropdownSelectWebTestIDs.label}>
            {label}
          </Text>
        )}
      </View>
      <Select
        menuPlacement="auto"
        value={options.find((x) => x.value === value)}
        isSearchable={isSearchable}
        isDisabled={disabled}
        menuPortalTarget={document.body}
        placeholder={inputText || placeholder}
        aria-label="Select"
        styles={{
          menu: (provided) => ({ ...provided }),
          dropdownIndicator: (base, state) => ({
            ...base,
            transition: 'all .2s ease',
            transform: state.selectProps.menuIsOpen
              ? ('rotate(180deg)' as any)
              : null,
          }),
          control: (base) => ({
            ...base,
            borderRadius: theme.roundness,
            fontFamily: theme.fonts.regular.fontFamily,
            backgroundColor: navBarDropdown
              ? theme.palette.primary[800]
              : undefined,
            border: navBarDropdown ? 0 : undefined,
            width: navBarDropdown ? '255px' : undefined,
            textAlign: 'left',
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused
              ? theme.palette.primary[50]
              : undefined,
          }),
          singleValue: (provided) => ({
            ...provided,
            color: fontColor || theme.palette.gray[900],
          }),
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
        options={
          options.map((option) => ({
            ...option,
            label: (
              <View style={styles.options}>
                <Text style={styles.optionsLabel}>{option.label}</Text>
                {value === option.value && (
                  <View style={styles.checkIcon}>
                    <CheckIcon size={20} color={theme.colors.primary} />
                  </View>
                )}
              </View>
            ),
          })) as any
        }
        onChange={(option) => {
          if (!!option) {
            handleOnchange(option);
          }
        }}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.gray[700],
    fontSize: 14,
    marginBottom: theme.getSpacing(1),
  },
  options: {
    flexDirection: 'row',
  },
  optionsLabel: {
    flex: 9,
  },
  checkIcon: {
    flex: 1,
  },
}));

export interface DropdownSelectWebProps {
  label?: string;
  disabled?: boolean;
  options: Array<{ label: string; value: string }>;
  fieldName: string;
  formContext: any;
  placeholder?: string;
  isSearchable?: boolean;
  navBarDropdown?: boolean;
  fontColor?: string;
}

export const DropdownSelectWebTestIDs = {
  label: 'dropdown-label',
};
