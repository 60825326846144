import { TaskDto } from '@digitalpharmacist/tasks-service-client-axios';
import create from 'zustand';
import { PharmacyUser } from '../../api';
import { ApiError, AsyncStatus } from '../../store/types';

export const useTaskModalState = create<TaskModalState>((set) => ({
  showModal: false,
  assigneeOptions: [],
  status: 'idle',
  editingTask: false,
  taskStatus: 'idle',
}));

interface TaskModalState {
  showModal: boolean;
  assigneeOptions: PharmacyUser[];
  status: AsyncStatus;
  error?: ApiError;
  editingTask: boolean;
  editTaskData?: TaskDto;
  taskStatus: AsyncStatus;
}
