import create from 'zustand';

export const useNewChatModalState = create<INewChatModalState>(() => ({
  templatesFilter: '',
  showEmojis: false,
  errorFields: {},
  inputFieldsData: {},
  cursorPosition: {},
}));

interface INewChatModalState {
  templatesFilter: string;
  showEmojis: boolean;
  errorFields: Record<string, string>;
  inputFieldsData: Record<string, any>;
  cursorPosition: Record<string, number>;
}
