export const uniqueWithMap = (options: any, getValue: (o: any) => string) => {
  if (!options) return [];

  const mapObject: any = {};

  options.forEach((option: any) => {
    mapObject[getValue(option)] = option;
  });

  return Object.values(mapObject);
};
