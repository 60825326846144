import create from 'zustand';
import {
  PatientState,
  SidebarDetails,
  LocationPatientRecordDtoWithFullName,
} from './patient-types';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';

// create state store
const usePatientStore = create<PatientState>((set) => ({
  patients: [],
  setPatients: (patients: LocationPatientRecordDtoWithFullName[]) =>
    set((state) => ({
      ...state,
      patients,
    })),
  updatePatientByIndex: (
    patientNumberInList: number,
    patient: LocationPatientRecordDtoWithFullName,
  ) =>
    set((state) => {
      const newPatients = [...state.patients];
      newPatients[patientNumberInList] = patient;

      return { ...state, patients: newPatients };
    }),
  showSidebar: false,
  setShowSidebar: (showSidebar) =>
    set((state) => ({
      ...state,
      showSidebar: showSidebar ? true : false,
    })),
  sidebarDetails: undefined,
  setSidebarDetails: (sidebarDetails: SidebarDetails) =>
    set((state) => ({
      ...state,
      sidebarDetails,
    })),
  patientRecord: undefined,
  setPatientRecord: (patientRecord: PatientRecordDto) =>
    set((state) => ({
      ...state,
      patientRecord,
    })),
}));

export default usePatientStore;
