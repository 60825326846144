import React, { FunctionComponent, PropsWithChildren } from 'react';
import { TextInput, View } from 'react-native';
import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';

export const InputElement: FunctionComponent<
  PropsWithChildren<InputElementProps>
> = ({ label, placeholder, longInput }) => {
  const styles = useStyles();

  return (
    <View style={styles.wrapper}>
      <TextInput
        numberOfLines={longInput ? 3 : 1}
        multiline={!!longInput}
        placeholder={placeholder}
      ></TextInput>
      <Text style={styles.label} selectable>
        {label}
      </Text>
    </View>
  );
};

interface InputElementProps {
  label?: string;
  placeholder?: string;
  longInput?: boolean;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexDirection: 'column',
    flexGrow: 1,
    gap: 5,
    width: '50%',
  },
  label: {
    fontSize: 14,
    color: theme.palette.gray['500'],
  },
}));
