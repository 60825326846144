import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Svg, Path, Rect } from 'react-native-svg';
import { IconProps } from 'assets/icons/types';

export const FormImportPreviewIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 109 143"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Rect width="109" height="143" fill="white" />
      <Path
        d="M47 83.5L57 93.5M57 93.5L67 83.5M57 93.5V71M79.2 86.225C81.3735 84.6966 83.0034 82.5154 83.8532 79.9979C84.703 77.4804 84.7285 74.7575 83.926 72.2246C83.1235 69.6916 81.5347 67.4802 79.3902 65.9114C77.2457 64.3426 74.6571 63.4979 72 63.5H68.85C68.0981 60.5697 66.6912 57.8481 64.7353 55.5402C62.7794 53.2323 60.3254 51.3981 57.558 50.1759C54.7907 48.9536 51.7821 48.3751 48.7588 48.4838C45.7355 48.5925 42.7763 49.3857 40.1039 50.8036C37.4315 52.2215 35.1156 54.2271 33.3305 56.6696C31.5454 59.112 30.3376 61.9276 29.7982 64.9044C29.2587 67.8811 29.4015 70.9415 30.2159 73.8551C31.0304 76.7686 32.4951 79.4595 34.5 81.725"
        stroke="#9CAFB9"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
