import React, { FunctionComponent } from 'react';
import { View } from 'react-native';

import { makeStyles } from 'assets/theme';
import { CalendarAppointments } from '../../../schedule/appointments-calendar/CalendarAppointments';

export const AppointmentsCalendar: FunctionComponent = () => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <CalendarAppointments />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: theme.getSpacing(4),
    height: '100%',
  },
}));
