import { AxiosRequestConfig } from 'axios';
import { BaseApiService } from 'assets/core/api';
import { USER_API_BASE_URL } from '../common/constants';
import { CreatePasswordForm } from '../screens/create-password/create-password-actions';
import { UserToken } from '../screens/login/login-actions';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';

export interface IRegisterService {
  createPassword(
    values: CreatePasswordForm,
    tempPassword: string,
  ): Promise<UserToken>;
}

export class RegisterService
  extends BaseApiService
  implements IRegisterService
{
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = false,
  ) {
    super(baseUrl, config, enableAuth);
  }

  async createPassword(values: CreatePasswordForm): Promise<UserToken> {
    const { data } = await this.axiosInstance.post(
      `/user/pharmacist/password_set`,
      values,
      {
        disableAuth: false,
      },
    );
    return data;
  }
}

export default new RegisterService(
  USER_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
