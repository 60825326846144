import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path, G, Circle, Defs, Rect } from 'react-native-svg';
import { IconProps } from './types';
import Group from 'react-native-svg';
import ClipPath from 'react-native-svg';

export const PauseCircleFilledIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Group clipPath="url(#clip0_8822_45217)">
        <Path
          d="M16.5 8.31002V9.00002C16.4991 10.6173 15.9754 12.191 15.007 13.4864C14.0386 14.7818 12.6775 15.7294 11.1265 16.1879C9.57557 16.6465 7.91794 16.5914 6.40085 16.031C4.88376 15.4705 3.58849 14.4346 2.70822 13.0778C1.82795 11.721 1.40984 10.1161 1.51626 8.50226C1.62267 6.88844 2.24791 5.35227 3.29871 4.12283C4.34951 2.89338 5.76959 2.03656 7.34714 1.68013C8.92469 1.3237 10.5752 1.48677 12.0525 2.14502M16.5 3.00002L9 10.5075L6.75 8.25752"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Group>
      <Circle cx="9" cy="9" r="8" fill="currentColor" />
      <Path d="M7 6V12" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <Path d="M11 6V12" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <Defs>
        <ClipPath id="clip0_8822_45217">
          <Rect width="18" height="18" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
