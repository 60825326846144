import React from 'react';
import theme, { makeStyles } from 'assets/theme';
import { View } from 'react-native';
import { CheckCircleIcon } from 'assets/icons';
import { Text } from 'assets/components/text';
import TaskModal from '../task-modal/TaskModal';

export default function NoTasks() {
  const styles = useStyles();
  return (
    <View style={styles.wrapper}>
      <View style={styles.container}>
        <View style={styles.iconContainer}>
          <CheckCircleIcon size={128} color={theme.palette.gray[300]} />
        </View>
        <Text style={styles.title} selectable>
          Create your first task
        </Text>
        <TaskModal />
      </View>
    </View>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexDirection: 'column',
    height: '100%',
    margin: theme.getSpacing(4),
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.getSpacing(4),
  },
  title: {
    fontSize: 25,
    fontWeight: '700',
    paddingBottom: theme.getSpacing(4),
    paddingTop: theme.getSpacing(4),
  },
  iconContainer: {
    marginLeft: theme.getSpacing(3),
  },
}));
