import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerNavigationProp,
  DrawerContentComponentProps,
} from '@react-navigation/drawer';
import {
  useRoute,
  getFocusedRouteNameFromRoute,
} from '@react-navigation/native';
import { getText } from 'assets/localization/localization';
import Inbox from '../screens/messages/Inbox';
import { Button } from 'assets/components/button';
import { useTheme } from 'assets/theme';
import Send from '../screens/messages/Send';
import Auto from '../screens/messages/Auto';
import { Tabs } from '../components/tabs/Tabs';
import { MessagesFilters } from '../screens/messages/components/messages-filters/MessagesFilters';
import { AutoIcon, InboxIcon, SendIcon } from 'assets/icons';
import {
  openNewChatModal,
  removeSelectedPatient,
} from '../screens/messages/inbox-store/inbox-actions';

const DrawerNavigator =
  createDrawerNavigator<MessagesDrawerNavigationParamList>();

export const MessagesDrawer: FunctionComponent<MessagesDrawerProps> = (
  props,
) => {
  return (
    <DrawerNavigator.Navigator
      screenOptions={{
        drawerType: 'permanent',
        headerShown: false,
        sceneContainerStyle: {
          overflow: 'scroll',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
      initialRouteName="inbox"
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      <DrawerNavigator.Screen name="inbox" component={Inbox} />
      <DrawerNavigator.Screen name="send" component={Send} />
      <DrawerNavigator.Screen name="auto" component={Auto} />
    </DrawerNavigator.Navigator>
  );
};

interface MessagesDrawerProps {}

export type MessagesDrawerNavigationParamList = {
  inbox: undefined;
  send: undefined;
  auto: undefined;
};

export type MessagesDrawerNavigationProp =
  DrawerNavigationProp<MessagesDrawerNavigationParamList>;

function getMessagesTabs(theme: ReactNativePaper.Theme) {
  const onNewChat = () => {
    removeSelectedPatient();
    openNewChatModal();
  };

  return [
    {
      title: getText('inbox'),
      id: 'inbox',
      children: (
        <>
          <View>
            <Button
              hierarchy="pharmacy-primary"
              size="small"
              logger={{ id: 'new-chat-button' }}
              style={{ margin: theme.getSpacing(2) }}
              onPress={onNewChat}
            >
              {getText('new-chat')}
            </Button>
            <MessagesFilters />
          </View>
        </>
      ),
      icon: InboxIcon,
    },
    {
      title: getText('send'),
      id: 'send',
      children: (
        <>
          <div>Not Yet Implemented</div>
        </>
      ),
      icon: SendIcon,
    },
    {
      title: getText('auto'),
      id: 'auto',
      children: (
        <>
          <div>Not Yet Implemented</div>
        </>
      ),
      icon: AutoIcon,
    },
  ];
}

export const CustomDrawerContent = (props: DrawerContentComponentProps) => {
  const theme = useTheme();
  const route = useRoute();
  const activeRoute = getFocusedRouteNameFromRoute(route);

  const handleTabChange = (activeTab: string) => {
    props.navigation.navigate(
      activeTab as keyof MessagesDrawerNavigationParamList,
    );
  };

  return (
    <>
      <DrawerContentScrollView {...props}>
        <Tabs
          tabs={getMessagesTabs(theme)}
          onTabChange={handleTabChange}
          defaultTab={activeRoute ? activeRoute : 'inbox'}
        />
      </DrawerContentScrollView>
    </>
  );
};
