import { SubmissionFullDataDto } from '@digitalpharmacist/forms-service-client-axios';
import create from 'zustand';
import { ApiError, AsyncStatus } from '../../store/types';

export const useViewSubmissionStore = create<ViewSubmissionStore>((set) => ({
  status: 'idle',
  error: undefined,
  submission: undefined,
}));

interface ViewSubmissionStore {
  status: AsyncStatus;
  error?: ApiError;
  submission?: SubmissionFullDataDto;
}
