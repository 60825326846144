import { Modal } from 'assets/components/modal';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'assets/components/text';

export const PharmacyConfirmationModal: FunctionComponent<
  PropsWithChildren<PharmacyConfirmationModalProps>
> = ({ show, onDismiss, onAccepted, message }) => {
  return (
    <Modal
      title="Please confirm your choice"
      cancelButtonProps={{
        onPress: onDismiss,
        logger: { id: 'confirmation-cancel-button' },
      }}
      okButtonProps={{
        onPress: onAccepted,
        logger: { id: 'confirmation-accept-button' },
        hierarchy: 'pharmacy-primary',
      }}
      show={show}
      size="sm"
    >
      <Text selectable>{message}</Text>
    </Modal>
  );
};

export interface PharmacyConfirmationModalProps {
  show: boolean;
  message: string;
  onDismiss?: () => void;
  onAccepted?: () => void;
}

export default PharmacyConfirmationModal;
