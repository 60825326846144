import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Icon } from 'assets/components/icon';
import { CheckIcon } from 'assets/icons';

export const StepperProgress: FunctionComponent<
  PropsWithChildren<WizardProgressProps>
> = (props) => {
  const styles = useStyles();
  const theme = useTheme();
  return (
    <View style={styles.root}>
      {new Array(props.numberOfSteps).fill(0).map((x, index) => (
        <View
          key={index}
          style={[
            styles.item,
            { flex: index < props.numberOfSteps - 1 ? props.numberOfSteps : 1 },
          ]}
        >
          <View style={[getShadowStyle(index, props.currentStep)]}>
            <View
              style={[
                styles.marker,
                getMarkerProgressStyle(index, props.currentStep),
              ]}
            >
              {props.currentStep <= index + 1 && (
                <View style={[styles.dot]}></View>
              )}
              {props.currentStep > index + 1 && (
                <View style={[styles.icon]}>
                  <Icon icon={CheckIcon} color={theme.palette.white} />
                </View>
              )}
            </View>
          </View>

          {index < props.numberOfSteps - 1 && (
            <View
              style={[
                styles.line,
                getLineProgressStyle(index, props.currentStep),
              ]}
            ></View>
          )}
        </View>
      ))}
    </View>
  );
};

const getShadowStyle = (index: number, currentStep: number) => {
  const styles = useStyles();
  if (index + 1 == currentStep) {
    return styles.shadow;
  } else return styles.noShadow;
};

const getMarkerProgressStyle = (index: number, currentStep: number) => {
  const styles = useStyles();
  if (currentStep > index) {
    return styles.markerComplete;
  } else {
    return styles.markerIncomplete;
  }
};

const getLineProgressStyle = (index: number, currentStep: number) => {
  const styles = useStyles();
  return index > currentStep - 2 ? styles.lineIncomplete : styles.lineComplete;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flex: 1,
  },
  marker: {
    width: 16,
    height: 16,
    borderRadius: 8,
  },
  markerComplete: {
    backgroundColor: theme.colors.pharmacyPrimary,
    width: 32,
    height: 32,
    borderRadius: 16,
  },
  markerIncomplete: {
    backgroundColor: theme.palette.gray[200],
    width: 32,
    height: 32,
    borderRadius: 16,
  },
  lineComplete: {
    backgroundColor: theme.colors.pharmacyPrimary,
  },
  lineIncomplete: {
    backgroundColor: theme.palette.gray[200],
  },
  line: {
    height: 2,
    width: '85%',
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: theme.getSpacing(1),
  },
  dot: {
    backgroundColor: theme.palette.white,
    width: 8,
    height: 8,
    borderRadius: 4,
    margin: 12,
  },
  icon: {
    marginLeft: theme.getSpacing(0.5),
    marginTop: theme.getSpacing(0.5),
  },
  shadow: {
    backgroundColor: theme.colors.pharmacyPrimaryDisabled,
    width: 40,
    height: 40,
    borderRadius: 20,
    padding: theme.getSpacing(0.5),
  },
  noShadow: {
    backgroundColor: 'transparent',
  },
}));

export interface WizardProgressProps {
  numberOfSteps: number;
  currentStep: number;
}
