import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  View,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  Pressable,
} from 'react-native';
import { useTheme, makeStyles } from '../../theme';
import { Text } from '../text';
import BaseRadioButton, { BaseRadioButtonProps } from './BaseRadioButton';

export const RadioButton: FunctionComponent<
  PropsWithChildren<RadioButtonProps>
> = ({
  selected,
  disabled,
  onValueChange,
  value,
  text,
  isLast,
  description,
  mode = RadioButtonInputMode.OUTLINE,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const currentStateColor = disabled
    ? theme.palette.gray[300]
    : selected
    ? theme.palette.primary[500]
    : theme.palette.gray[700];

  return (
    <RadioButtonWrapper
      style={[
        mode === RadioButtonInputMode.OUTLINE && styles.outline,
        styles.touchableContainer,
        selected && !disabled && styles.selected,
        isLast && styles.touchableContainerLast,
        description !== undefined && styles.touchableContainerWithDescription,
      ]}
      mode={mode}
      onPress={() => {
        if (onValueChange) {
          onValueChange(value);
        }
      }}
      disabled={disabled}
    >
      <Pressable
        style={styles.radioButtonContainer}
        onPress={() => {
          if (onValueChange) {
            onValueChange(value);
          }
        }}
      >
        <BaseRadioButton selected={selected} disabled={disabled} />
      </Pressable>
      <View style={styles.textContainer}>
        <View style={{ marginTop: theme.getSpacing(2) }}>
          <Text style={[styles.text, { color: currentStateColor }]}>
            {text}
          </Text>
        </View>
        <View>
          <Text style={styles.description}>{description}</Text>
        </View>
      </View>
    </RadioButtonWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  radioButtonContainer: {
    margin: theme.getSpacing(2),
  },
  touchableContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: theme.getSpacing(0.5),
    marginBottom: theme.getSpacing(0.5),
    padding: 0,
  },
  outline: {
    borderRadius: theme.roundness,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.gray[300],
  },
  touchableContainerWithDescription: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: theme.getSpacing(0.5),
    marginBottom: theme.getSpacing(0.5),
    padding: 0,
    borderRadius: theme.roundness,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.gray[300],
    paddingBottom: theme.getSpacing(2),
  },
  touchableContainerLast: {
    marginBottom: 0,
  },
  selected: {
    color: theme.palette.primary[500],
    borderColor: theme.palette.primary[500],
  },
  textContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: theme.getSpacing(2),
    flexWrap: 'wrap',
    flex: 1,
  },
  text: {
    alignSelf: 'center',
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '500',
  },
  description: {
    color: theme.palette.gray[600],
    marginTop: theme.getSpacing(1),
  },
}));

export interface RadioButtonProps extends BaseRadioButtonProps {
  text: string;
  value: string;
  onValueChange: (value: string) => void;
  isLast?: boolean;
  description?: string;
  mode?: RadioButtonInputMode;
}

export default RadioButton;

interface RadioButtonWrapperProps {
  mode?: RadioButtonInputMode;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
  onPress?: () => void;
}

const RadioButtonWrapper: FunctionComponent<
  PropsWithChildren<RadioButtonWrapperProps>
> = ({ children, mode, style, disabled, onPress }) => {
  // TODO: find a better way to write this with generics
  return mode === RadioButtonInputMode.FLAT ? (
    <View style={style}>{children}</View>
  ) : (
    <TouchableOpacity disabled={disabled} style={style} onPress={onPress}>
      {children}
    </TouchableOpacity>
  );
};

export enum RadioButtonInputMode {
  FLAT = 'flat',
  OUTLINE = 'outline',
}
