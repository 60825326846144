import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerNavigationProp,
  DrawerScreenProps,
  createDrawerNavigator,
} from '@react-navigation/drawer';
import {
  getFocusedRouteNameFromRoute,
  useRoute,
} from '@react-navigation/native';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

import { GridIcon, ListIcon } from 'assets/icons';
import { makeStyles } from 'assets/theme';
import { Tabs, TabsProps } from '../components/tabs/Tabs';
import VideosFilter from '../components/videos/VideosFilter';
import VideoDetails from '../screens/videos/VideoDetails';
import { resetFilters } from '../tasks/tasks-filters/tasks-filters-actions';
import VideosBrowse from '../screens/videos/videosBrowse';
import VideosList from '../screens/videos/videosList';
import * as Linking from 'expo-linking';
import { VideosLocation } from '../api/VideoService';

const DrawerNavigator =
  createDrawerNavigator<VideosDrawerNavigationParamList>();

export const VideosDrawer: FunctionComponent<
  PropsWithChildren<VideosDrawerProps>
> = () => {
  return (
    <DrawerNavigator.Navigator
      screenOptions={{
        drawerType: 'permanent',
        headerShown: false,
        sceneContainerStyle: {
          overflow: 'scroll',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
      initialRouteName="browse"
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      <DrawerNavigator.Screen name="browse" component={VideosBrowse} />
      <DrawerNavigator.Screen name="list" component={VideosList} />
      <DrawerNavigator.Screen
        name="detail"
        component={VideoDetails}
        options={{ headerTitle: () => null }}
      />
    </DrawerNavigator.Navigator>
  );
};

interface VideosDrawerProps {}

export type VideoDetailParamsProp = {
  video_id: string;
  collection_page: string;
  locale: VideosLocation;
};

export type VideosDrawerNavigationParamList = {
  browse: undefined;
  list: undefined;
  detail: VideoDetailParamsProp;
};

export type VideosDrawerNavigationProp =
  DrawerNavigationProp<VideosDrawerNavigationParamList>;

export type VideoListScreenRouteProp = DrawerScreenProps<
  VideosDrawerNavigationParamList,
  'list'
>;

export type VideoDetailScreenRouteProp = DrawerScreenProps<
  VideosDrawerNavigationParamList,
  'detail'
>;

export type VideoBrowseScreenRouteProp = DrawerScreenProps<
  VideosDrawerNavigationParamList,
  'browse'
>;

const tasksTabs: TabsProps['tabs'] = [
  {
    title: 'Browse',
    id: 'browse',
    children: (
      <>
        <VideosFilter />
      </>
    ),
    icon: GridIcon,
  },
  {
    title: 'List',
    id: 'list',
    children: (
      <>
        <VideosFilter />
      </>
    ),
    icon: ListIcon,
  },
];

export const CustomDrawerContent = (props: DrawerContentComponentProps) => {
  const styles = useStyles();
  const route = useRoute();
  const activeRoute = getFocusedRouteNameFromRoute(route);
  const [activeTab, setActiveTab] = useState('browse');

  useEffect(() => {
    if (!activeRoute) return;
    (async () => {
      if (activeRoute === 'detail') {
        const url = await Linking.getInitialURL();
        if (!url) return;
        const { queryParams, path } = Linking.parse(url);
        const collectionParam = (queryParams as any)?.collection_page;
        if (collectionParam) setActiveTab(collectionParam);
        else setActiveTab(path?.split('/')[1]!);
      } else setActiveTab(activeRoute);
    })();
  }, [activeRoute]);

  const handleTabChange = (activeTab: string) => {
    resetFilters(activeTab);

    props.navigation.navigate(
      activeTab as keyof VideosDrawerNavigationParamList,
    );
  };

  return (
    <>
      <DrawerContentScrollView {...props} style={styles.sidebar}>
        <Tabs
          tabs={tasksTabs}
          onTabChange={handleTabChange}
          defaultTab={activeTab}
        />
      </DrawerContentScrollView>
    </>
  );
};

const useStyles = makeStyles(() => ({
  sidebar: {
    backgroundColor: '#F5F9FD',
    margin: 0,
  },
}));
