import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const LumistryIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 50 50"
      fill="none"
      testID={testID}
    >
      <Path
        d="M16.0447 29.0412H12.2309C9.28739 29.0412 6.89311 31.4475 6.89311 34.4058C6.89311 37.3641 9.28739 39.7704 12.2309 39.7704H36.1192L32.145 44.6881H12.2294C6.58978 44.6866 2 40.0753 2 34.4058C2 28.7363 6.58978 24.125 12.2294 24.125H16.0462L16.0432 29.0427L16.0447 29.0412Z"
        fill="#FFFFFF"
      />
      <Path
        d="M16.0462 24.1251V29.0427L20.9393 24.0555L20.9305 20.9048C20.9305 17.9465 23.3247 15.5402 26.2668 15.5402C29.2088 15.5402 31.6046 17.9465 31.6046 20.9048L31.5678 24.7881L36.4977 20.9048C36.4977 15.2354 31.9079 10.624 26.2668 10.624C20.6257 10.624 16.0374 15.2368 16.0374 20.9122L16.0462 24.1251Z"
        fill="#5CC4FF"
      />
      <Path
        d="M36.6037 5.79662L33.2332 10.6255L30.5032 8.89699L33.619 3.80469L36.6037 5.79662Z"
        fill="#5CC4FF"
      />
      <Path
        d="M15.9077 5.79662L19.1531 10.6255L22.0495 8.89699L18.8925 3.80469L15.9077 5.79662Z"
        fill="#5CC4FF"
      />
      <Path
        d="M27.9366 8.44997H24.5749L24.2039 0.5H28.2974L27.9366 8.44997Z"
        fill="#5CC4FF"
      />
    </Svg>
  );
};
