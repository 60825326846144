import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { PHARMACY_API_BASE_URL } from '../common/constants';
import {
  DefaultApi as PharmacyServiceClient,
  PharmacyLocationDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';

export interface IPharmacyService {
  pharmacyLocationFindOne(
    locationId: string,
    pharmacyId: string,
  ): Promise<PharmacyLocationDto>;

  pharmacyLocationFindAll(pharmacyId: string): Promise<PharmacyLocationDto[]>;
}

export class PharmacyService
  extends BaseApiService
  implements IPharmacyService
{
  private pharmacyServiceClient: PharmacyServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.pharmacyServiceClient = new PharmacyServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async pharmacyLocationFindOne(
    locationId: string,
    pharmacyId: string,
  ): Promise<PharmacyLocationDto> {
    const { data } = await this.pharmacyServiceClient.pharmacyLocationFindOne(
      locationId,
      { headers: { 'x-pharmacy-id': pharmacyId } },
    );
    return data;
  }

  async pharmacyLocationFindAll(
    pharmacyId: string,
  ): Promise<PharmacyLocationDto[]> {
    const { data } = await this.pharmacyServiceClient.pharmacyLocationFind({
      headers: { 'x-pharmacy-id': pharmacyId },
    });
    return data;
  }
}

export default new PharmacyService(
  PHARMACY_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
