import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { View } from 'react-native';
import Select from 'react-select';
import { useFocusEffect } from '@react-navigation/native';
import { AppointmentTypeDto } from '@digitalpharmacist/appointment-service-client-axios';
import {
  DatesSetArg,
  EventClickArg,
  EventContentArg,
  EventInput,
} from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import shallow from 'zustand/shallow';

import { Text } from 'assets/components/text';
import { makeStyles, useTheme } from 'assets/theme';
import { getText } from '../../../../../packages/assets/localization/localization';
import { CheckIcon } from '../../../../../packages/assets/icons';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import { useAppointmentsCalendarState } from './appointments-calendar-store';
import {
  getAppointments,
  groupByUniqueServicesAndCount,
} from './appointments-calendar-actions';
import { useServicesListState } from '../services-list/services-list-store';
import './calendar.css';
import { getServices } from '../services-list/services-list-actions';
import { Tooltip } from 'react-tooltip';
import { ColoredBadge } from '../../components/ColoredBadge';
import { showAppointmentDetails } from '../appointments-list/appointments-list-actions';
import { AppointmentDetailsSidebar } from '../appointments-list/AppointmentDetailsSidebar';
import { useProSidebar } from 'react-pro-sidebar';
import { EventImpl } from '@fullcalendar/core/internal';
import {
  DEFAULT_UTC_OFFSET,
  formatDateTimeWithTimezone,
} from '../../common/datetime-utils';

function innerContentRenderer(innerProps: EventContentArg) {
  return (
    <div
      className="fc-event fc-event-start fc-event-end fc-event-past fc-daygrid-event fc-daygrid-dot-event fc-text-ellipsis"
      id={innerProps.event.id}
    >
      <div className="fc-daygrid-event-dot" />
      {innerProps.timeText && (
        <div className="fc-event-time">{innerProps.timeText}</div>
      )}
      <div className="fc-event-title fc-text-ellipsis">
        {innerProps.event.title || <>&nbsp;</>}
      </div>
    </div>
  );
}

export const CalendarAppointments: FunctionComponent = () => {
  const styles = useStyles();
  const theme = useTheme();
  const { collapseSidebar } = useProSidebar();
  const calendarRef = useRef<FullCalendar>(null);

  const [sortingValue, setSortingValue] = useState<
    EventInput | undefined | null
  >();
  const { services, isServicesLoading } = useServicesListState(
    (state) => ({
      services: state.services
        ? state.services.filter((service) => service.enabled)
        : [],
      isServicesLoading: state.status === 'loading',
    }),
    shallow,
  );

  const selectTransform = (menuIsOpen?: boolean) =>
    menuIsOpen ? 'rotate(180deg)' : undefined;

  const isLoading = useAppointmentsCalendarState(
    (state) => state.status === 'loading',
  );

  const { events, totalCount, appointments, dateRange } =
    useAppointmentsCalendarState(
      (state) => ({
        events: state.appointments?.map((booking) => ({
          title: `${booking.title}: ${booking.patient_record_first_name} ${booking.patient_record_last_name}`,
          id: booking.id,
          start: formatDateTimeWithTimezone(
            booking.startTime,
            DEFAULT_UTC_OFFSET, // TODO: Change to pharmacy timezone when it's implemented
            'YYYY-MM-DDTHH:mm:ss',
          ),
          end: formatDateTimeWithTimezone(
            booking.endTime,
            DEFAULT_UTC_OFFSET, // TODO: Change to pharmacy timezone when it's implemented
            'YYYY-MM-DDTHH:mm:ss',
          ),
        })),
        appointments: state.appointments,
        totalCount: state.totalCount,
        dateRange: state.dateRange,
      }),
      shallow,
    );

  useFocusEffect(
    useCallback(() => {
      if (!services.length) {
        void getServices({ withoutNextAvailableSlot: true });
      }
    }, [services.length]),
  );

  useFocusEffect(
    useCallback(() => {
      calendarRef.current?.requestResize();
    }, []),
  );

  const handleChange = (option: AppointmentTypeDto | null) => {
    setSortingValue(option);

    if (dateRange) {
      void getAppointments(
        {
          startStr: dateRange.startDate,
          endStr: dateRange.endDate,
        },
        option?.id,
      );
    }
  };

  const { totalAppointmentServices, upcomingAppointmentServices } =
    groupByUniqueServicesAndCount(appointments) ?? {};

  const upcomingCount = upcomingAppointmentServices?.reduce((prev, current) => {
    return (prev += current.count);
  }, 0);

  const handleEventClick = (data: EventClickArg) => {
    collapseSidebar(false);
    showAppointmentDetails(data.event._def.publicId);

    setTimeout(() => calendarRef.current?.requestResize(), 300);
  };

  const handleEventCancel = (bookingId: string) => {
    const calendarApi = calendarRef.current?.getApi();
    const events = calendarApi?.getEvents() as EventImpl[];
    const deletedEvent = events.find(
      (event) => event._def.publicId === bookingId,
    );

    if (deletedEvent) {
      deletedEvent.remove();
    }

    handleSidebarCollapse();
  };

  const handleSidebarCollapse = () => {
    setTimeout(() => calendarRef.current?.requestResize(), 300);
  };

  return (
    <>
      <View style={styles.flexOne}>
        {isLoading && <LoadingOverlay />}
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <View style={styles.headerContainer}>
              <Text style={styles.title}>Appointments Calendar</Text>

              <Select
                menuPlacement="auto"
                value={sortingValue}
                isSearchable
                isClearable
                menuPortalTarget={document.body}
                placeholder={`${getText('select')} ${getText(
                  'service',
                ).toLowerCase()}`}
                aria-label="Select"
                isDisabled={isServicesLoading}
                isLoading={isServicesLoading}
                styles={{
                  menu: (provided) => ({ ...provided }),
                  dropdownIndicator: (base, state) => ({
                    ...base,
                    transition: 'all .2s ease',
                    transform: selectTransform(state.selectProps.menuIsOpen),
                  }),
                  control: (base) => ({
                    ...base,
                    ...styles.control,
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused
                      ? theme.palette.primary[50]
                      : undefined,
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: theme.palette.gray[100],
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    ...styles.valueContainerPadding,
                  }),
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
                options={services.map((option) => ({
                  ...option,
                  label: (
                    <View style={styles.options}>
                      <Text style={styles.optionsLabel}>{option.title}</Text>
                      {sortingValue?.id === option.id && (
                        <View style={styles.flexOne}>
                          <CheckIcon size={20} color={theme.colors.primary} />
                        </View>
                      )}
                    </View>
                  ),
                }))}
                onChange={handleChange}
              />
            </View>
            {!!totalCount && (
              <View style={{ position: 'relative' }}>
                <View style={styles.appointmentsCount}>
                  <ColoredBadge
                    label={`${totalCount} in total`}
                    color={theme.palette.gray[700]}
                    backgroundColor={theme.palette.gray[100]}
                    nativeID="total-calendar-monthly-events-tooltip"
                    textStyle={styles.badgeText}
                  />
                  <ColoredBadge
                    label={`${upcomingCount ?? 0} remaining`}
                    color={theme.palette.gray[700]}
                    backgroundColor={theme.palette.gray[100]}
                    nativeID="upcoming-calendar-monthly-events-tooltip"
                    textStyle={styles.badgeText}
                  />
                </View>
              </View>
            )}
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: 'prev,next today title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay',
              }}
              buttonText={{
                today: getText('today'),
                month: getText('month'),
                week: getText('week'),
                day: getText('day'),
                list: getText('list'),
              }}
              height="80vh"
              initialView="dayGridMonth"
              editable={false}
              events={events}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              timeZone="UTC"
              datesSet={(event: DatesSetArg) =>
                void getAppointments(event, sortingValue?.id)
              }
              slotDuration="00:15:00"
              nowIndicator={true}
              eventClick={handleEventClick}
              eventContent={(arg) => {
                return (
                  <>
                    <Tooltip
                      place="top"
                      anchorId={arg.event.id}
                      style={styles.tooltipPosition}
                    >
                      <Text
                        key={`${arg.event.id}-${arg.event.title}`}
                        style={styles.textColor}
                      >
                        {arg.event.title}
                      </Text>
                    </Tooltip>
                    {innerContentRenderer(arg)}
                  </>
                );
              }}
              ref={calendarRef}
            />
          </View>
          <AppointmentDetailsSidebar
            onCancel={handleEventCancel}
            onCollapse={handleSidebarCollapse}
          />
        </View>
      </View>
      {totalAppointmentServices && (
        <Tooltip
          place="bottom"
          anchorId={`total-calendar-monthly-events-tooltip`}
          style={styles.tooltipPosition}
        >
          {totalAppointmentServices.map((service) => (
            <Text
              key={`${service.count}-${service.title}`}
              style={styles.textColor}
            >
              {service.count} {service.title}
            </Text>
          ))}
        </Tooltip>
      )}
      {upcomingAppointmentServices && (
        <Tooltip
          place="bottom"
          anchorId={`upcoming-calendar-monthly-events-tooltip`}
          style={styles.tooltipPosition}
        >
          {upcomingAppointmentServices.map((service) => (
            <Text
              key={`${service.count}-${service.title}`}
              style={styles.textColor}
            >
              {service.count} {service.title}
            </Text>
          ))}
        </Tooltip>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.fonts.regular,
    fontSize: 26,
    fontWeight: '600',
    lineHeight: 31,
    color: theme.palette.gray[900],
    marginRight: theme.getSpacing(2),
    alignItems: 'center',
    display: 'flex',
  },
  appointmentsCount: {
    position: 'absolute',
    gap: theme.getSpacing(1) + theme.getSpacing(0.5),
    zIndex: 10,
    right: 195,
    top: 12,
    display: 'flex',
    flexDirection: 'row',
  },
  cellContainer: {
    marginTop: theme.getSpacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.getSpacing(3),
    alignItems: 'center',
    marginBottom: theme.getSpacing(3),
  },
  flexOne: {
    flex: 1,
  },
  options: {
    flexDirection: 'row',
  },
  optionsLabel: {
    flex: 9,
  },
  control: {
    borderRadius: theme.roundness,
    fontFamily: theme.fonts.regular.fontFamily,
    width: '255px',
    textAlign: 'left',
    zIndex: 10,
  },
  valueContainerPadding: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  badgeText: {
    ...theme.fonts.regular,
    fontSize: 14,
    paddingLeft: theme.getSpacing(1),
    paddingRight: theme.getSpacing(1),
    cursor: 'pointer',
  },
  tooltipPosition: {
    display: 'flex',
    flexDirection: 'column',
    opacity: 1,
    backgroundColor: theme.palette.black,
    zIndex: 10,
  },
  textColor: {
    color: theme.palette.white,
  },
  calendarContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  sidebarContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    height: '100%',
    zIndex: 1,
  },
}));
