import create from 'zustand';
import {
  TaskPriority,
  TaskSortOrder,
  TaskStatus,
  TaskVisibility,
} from '@digitalpharmacist/tasks-service-client-axios';

export const useTasksFiltersState = create<TasksFiltersState>(() => ({
  showModal: false,
  filters: { non_resolved_only: true },
  disabled: false,
  count: {},
  activeTab: 'all_tasks',
  isGrid: false,
  isCustomFilter: false,
}));

export interface TaskFilters {
  dueAfter?: string;
  dueBefore?: string;
  offset?: number;
  limit?: number;
  sortBy?: Array<string>;
  orderBy?: TaskSortOrder;
  assigned_user_id?: string;
  status?: TaskStatus;
  flagged?: boolean;
  recurring?: boolean;
  created_by_user_id?: string;
  priority?: TaskPriority;
  due_date?: string;
  min_due_date?: string;
  max_due_date?: string;
  deleted_only?: boolean;
  non_resolved_only?: boolean;
  visibility?: TaskVisibility;
  task_type_id?: string;
  search_term?: string;
}

export interface FiltersCount {
  total?: number;
  flagged?: number;
  unresolved?: number;
}
interface TasksFiltersState {
  showModal: boolean;
  filters: TaskFilters;
  disabled: boolean;
  count: FiltersCount;
  activeTab: string;
  isGrid: boolean;
  isCustomFilter: boolean;
}
