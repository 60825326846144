import {
  LocationRole,
  Role,
} from '@digitalpharmacist/role-service-client-axios';

export const roleDisplay = (role: Role) => {
  let label;
  if (role === Role.NoAccess) {
    label = 'No Access';
  } else {
    label = (role.charAt(0) + role.slice(1).toLowerCase()).replace('_', ' ');
  }

  return label;
};

export const locationRoleOptions = () => {
  const options = [];
  for (const role of Object.values(LocationRole)) {
    let label = roleDisplay(role);

    options.push({
      label: label,
      value: role,
    });
  }
  return options;
};
