import create from 'zustand';

export const useLoadingState = create<LoadingState>(() => ({
  loadingObject: {},
}));

interface ApiLoading {
  isLoading: boolean;
}

export type LoadingObject = Record<string, ApiLoading>;

interface LoadingState {
  loadingObject: LoadingObject;
}
