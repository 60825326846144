import React, { FunctionComponent } from 'react';
import { View } from 'react-native';

import { makeStyles } from 'assets/theme';
import { ServicesList } from '../../../schedule/services-list/ServicesList';

export const Services: FunctionComponent<ServicesProps> = (props) => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <ServicesList />
      </View>
    </View>
  );
};

interface ServicesProps {}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: theme.getSpacing(4),
    height: '100%',
  },
  cellContainer: {
    marginTop: theme.getSpacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
}));
