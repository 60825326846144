import { FunctionComponent } from 'react';
import { View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { Avatar } from 'assets/components/avatar';
import { DirectMessagePatientDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import { formatMessageDate } from '../utils';

export const PatientConversation: FunctionComponent<
  PatientConversationProps
> = ({ patient, isSelected = false, isViewed }) => {
  const styles = useStyles();
  const theme = useTheme();
  const fullName = patient.first_name + ' ' + patient.last_name;

  return (
    <View style={[styles.container, isSelected && styles.selected]}>
      <View style={styles.avatar}>
        <Avatar size={32} name={fullName} color={theme.palette.gray[100]} />
      </View>
      <View style={styles.text}>
        <Text style={[styles.name, !isViewed && styles.unread && styles.bold]}>
          {fullName}
        </Text>
        <Text style={[styles.preview, !isViewed && styles.unread]}>
          {patient.most_recent_qualifying_message}
        </Text>
      </View>
      <Text style={styles.date}>
        {formatMessageDate(patient.most_recent_qualifying_message_date)}
      </Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    paddingVertical: theme.getSpacing(1) + theme.getSpacing(0.5),
    paddingHorizontal: theme.getSpacing(0.5),
  },
  selected: {
    backgroundColor: theme.palette.gray[100],
  },
  avatar: {
    borderWidth: 2,
    borderColor: theme.palette.white,
    borderRadius: 16,
    flexBasis: '36px',
    height: 'fit-content',
  },
  text: {
    marginLeft: theme.getSpacing(1),
    marginRight: theme.getSpacing(1),
    flex: 100,
    justifyContent: 'center',
  },
  name: {
    color: theme.palette.gray[600],
    textOverflow: 'ellipsis',
    fontSize: 13,
  },
  unread: {
    color: theme.palette.black,
  },
  bold: {
    fontWeight: '700',
  },
  preview: {
    color: theme.palette.gray[500],
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: 13,
  },
  date: {
    alignSelf: 'center',
    color: theme.palette.gray[600],
    flex: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

interface PatientConversationProps {
  patient: DirectMessagePatientDto;
  isViewed: boolean;
  isSelected?: boolean;
}
