import React, { FunctionComponent, PropsWithChildren } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { makeStyles, useTheme } from '../../theme';
import { Text } from '../text';

export const TypeaheadNativeListItem: FunctionComponent<
  PropsWithChildren<TypeaheadNativeListItemProps>
> = ({ disabled, onPress, option, isLast, getOptionText, rightIcon: icon }) => {
  const theme = useTheme();
  const styles = useStyles();
  return (
    <TouchableOpacity
      testID={TypeaheadNativeListItemIds.container}
      style={[
        styles.touchableContainer,
        isLast && styles.touchableContainerLast,
      ]}
      onPress={(event) => onPress(option)}
      disabled={disabled}
    >
      <View style={{ flexDirection: 'row', width: '100%' }}>
        <View style={{ alignSelf: 'center', width: '100%' }}>
          <Text
            testID={TypeaheadNativeListItemIds.text}
            style={{
              alignSelf: 'center',
              fontSize: 16,
              lineHeight: 24,
              fontWeight: '500',
              marginLeft: 16,
              paddingRight: 48,
              width: '100%',
              color: theme.palette.gray[900],
            }}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {getOptionText(option)}
          </Text>
        </View>
        <View style={{ height: 48, width: 48, right: 48 }}>
          <View
            style={{
              margin: theme.getSpacing(2),
              height: 16,
              width: 16,
            }}
          >
            {icon}
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const useStyles = makeStyles((theme) => ({
  touchableContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 0,
    height: 48,
    borderStyle: 'solid',
    backgroundColor: theme.palette.white,
    width: '100%',
  },
  touchableContainerLast: {
    marginBottom: 0,
  },
}));

export interface TypeaheadNativeListItemProps {
  option: any;
  onPress: (value: any) => void;
  disabled?: boolean;
  isLast?: boolean;
  getOptionText: (option: any) => string;
  rightIcon?: React.ReactNode;
}

export const TypeaheadNativeListItemIds = {
  container: 'TypeaheadNativeListItem-container',
  text: 'TypeaheadNativeListItem-text',
};

export default TypeaheadNativeListItem;
