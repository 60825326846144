import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const ThreedotsIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 16 5"
      fill="none"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        d="M8 3.333a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666ZM13.833 3.333a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666ZM2.167 3.333a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667Z"
        stroke="#374D58"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
