import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const MessageCircleIcon: FunctionComponent<IconProps> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 50 50"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        d="M47.5 23.75C47.5086 27.0497 46.7377 30.3047 45.25 33.25C43.4861 36.7794 40.7744 39.7479 37.4186 41.8232C34.0629 43.8985 30.1956 44.9985 26.25 45C22.9503 45.0086 19.6953 44.2377 16.75 42.75L2.5 47.5L7.25 33.25C5.76233 30.3047 4.9914 27.0497 5 23.75C5.00153 19.8044 6.10153 15.9371 8.1768 12.5814C10.2521 9.22563 13.2206 6.51393 16.75 4.75001C19.6953 3.26234 22.9503 2.49141 26.25 2.50001H27.5C32.7109 2.78749 37.6326 4.98691 41.3229 8.67716C45.0131 12.3674 47.2125 17.2891 47.5 22.5V23.75Z"
        stroke="#9CAFB9"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
