import { RenderFunction, Vido } from 'gantt-schedule-timeline-calendar';
import moment from 'moment';
import { getIcon } from './tasks-grid-utils';

export const itemSlot = (vido: unknown, props: unknown): RenderFunction => {
  const { html, onChange, update, unsafeHTML } = vido as Vido;

  onChange((newProps) => {
    props = newProps;
    update();
  });

  return () => {
    return html`<div class="item-wrapper">
      ${unsafeHTML(getIcon((props as any).item.statusIcon))}
    </div>`;
  };
};

export const cellSlot = (vido: unknown, props: unknown) => {
  const { html, onChange, update } = vido as Vido;

  onChange((newProps) => {
    props = newProps;
    update();
  });

  return () => {
    if ((props as any).time.leftGlobalDate.$d.getDay() === 0) {
      return html`<div class="divider"></div>`;
    }
  };
};

export const cellSlotOuter = (vido: unknown, props: unknown) => {
  const { html, onChange, update } = vido as Vido;

  onChange((newProps) => {
    props = newProps;
    update();
  });

  return (content: unknown) => {
    if (
      moment((props as any).time.leftGlobalDate.$d).isoWeek() <
      moment().isoWeek()
    ) {
      return html`<div class="timeline-grid-row-cell-wrapper past-week">
        ${content}
      </div>`;
    }

    return html`${content}`;
  };
};

export const calendarSlot = (vido: unknown, props: unknown) => {
  const { html, onChange, update } = vido as Vido;

  onChange((newProps) => {
    props = newProps;
    update();
  });

  return (content: unknown) => {
    const classList = [];

    if (
      moment((props as any).date.leftGlobalDate.$d).isoWeek() <
      moment().isoWeek()
    ) {
      classList.push('past-week');
    }

    if ((props as any).date.leftGlobalDate.$d.getDay() === 0) {
      classList.push('last');
    }

    if (
      (props as any).date.leftGlobalDate.$d.getDate() === 1 &&
      (props as any).level === 1
    ) {
      classList.push('calendar-first-day');
    }

    if ((props as any).level === 0) {
      return html`${content}`;
    } else {
      return html`<div class="calendar-cell-wrapper ${classList.join(' ')}">
        ${content}
        ${(props as any).date.leftGlobalDate.$d.getDay() === 0
          ? html`<div class="divider"></div>`
          : null}
      </div>`;
    }
  };
};
