import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View, Text } from 'react-native';
import { ChevronsRightIcon } from 'assets/icons';
import { IconButton } from 'assets/components/icon-button';
import { makeStyles, useTheme } from 'assets/theme';
import {
  CSSObject,
  Sidebar,
  sidebarClasses,
  useProSidebar,
} from 'react-pro-sidebar';
import { LoadingOverlay } from './LoadingOverlay';

interface PharmacySidebarProps {
  title: string;
  dataExists: boolean;
  children: React.ReactChild;
  defaultCollapsed?: boolean;
  rootStyle?: CSSObject;
  onCollapse?: () => void;
}

export const PharmacySidebar: FunctionComponent<
  PropsWithChildren<PharmacySidebarProps>
> = ({
  title,
  dataExists,
  children,
  defaultCollapsed = false,
  rootStyle,
  onCollapse,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const { collapseSidebar } = useProSidebar();

  function SidebarHeader() {
    return (
      <View style={styles.header}>
        <View style={{ flex: 1 }}>
          <Text style={styles.headerTitleText} selectable>
            {title}
          </Text>
        </View>
        <View>
          <IconButton
            icon={ChevronsRightIcon}
            size={18}
            color={theme.palette.gray[400]}
            logger={{
              id: 'task-details-sidebar--close',
            }}
            onPress={() => {
              collapseSidebar(true);

              onCollapse && onCollapse();
            }}
          />
        </View>
      </View>
    );
  }

  return (
    <Sidebar
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          backgroundColor: theme.palette.white,
          borderRightWidth: 0,
          borderLeftWidth: 1,
          borderLeftColor: theme.palette.gray[200],
          borderLeftStyle: 'solid',
        },
        height: '100%',
        ...rootStyle,
      }}
      width="420px"
      rtl={false}
      collapsedWidth="0"
      defaultCollapsed={defaultCollapsed}
    >
      <View style={styles.detailsContainer}>
        {dataExists ? (
          <>
            <SidebarHeader />
            {children}
          </>
        ) : (
          <LoadingOverlay />
        )}
      </View>
    </Sidebar>
  );
};

export const useStyles = makeStyles((theme) => ({
  rowView: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    flex: 1,
  },
  boldSubtext: {
    fontWeight: '600',
    paddingBottom: theme.getSpacing(0.5),
  },
  comments: {
    color: theme.palette.gray[600],
    backgroundColor: theme.palette.gray[50],
    padding: theme.getSpacing(1),
    borderRadius: 4,
    paddingBottom: theme.getSpacing(4),
  },
  subtext: {
    paddingBottom: theme.getSpacing(2),
  },
  container: {
    paddingBottom: theme.getSpacing(2),
  },
  sectionHeader: {
    paddingTop: theme.getSpacing(2),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    paddingBottom: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },

  link: {
    color: theme.palette.primary[500],
    textDecorationLine: 'none',
  },
  detailsContainer: {
    height: '100%',
    padding: theme.getSpacing(2),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  headerTitleText: {
    color: theme.palette.gray[400],
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
    justifyContent: 'center',
    alignItems: 'center',
  },
  fieldLabel: {
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 21,
    color: theme.palette.gray[900],
    minWidth: 100,
  },
  sectionHeaderLabel: {
    fontWeight: '600',
    fontSize: 12,
    textTransform: 'uppercase',
    color: theme.palette.gray[900],
  },
}));
