import React, { useEffect } from 'react';

import SettingsPageLayout from './components/SettingsPageLayout';
import SettingsSection from './components/SettingsSection';
import TaskTypeItemRenderer from './components/TaskTypeItemRenderer';
import { getTaskTypes, updateShowModalState } from './task-settings-actions';
import { useTaskSettingsState } from './task-settings-store';
import TaskTypeModal from './components/TaskTypeModal';

export default function TaskSettings() {
  const { taskTypes } = useTaskSettingsState();

  useEffect(() => {
    getTaskTypes();
  }, []);

  return (
    <SettingsPageLayout title="Tasks">
      <SettingsSection
        title="Task types"
        subtitle="Libero et velit interdum, ac aliquet odio mattis."
        contentHeader={{
          title: 'Types',
          button: {
            hierarchy: 'secondary-gray',
            logger: { id: 'settings-header-button' },
            buttonTitle: 'New',
            onPress: () => updateShowModalState(true),
          },
        }}
        contentData={taskTypes}
        contentItem={TaskTypeItemRenderer}
      />
      <TaskTypeModal />
    </SettingsPageLayout>
  );
}
