import React from 'react';
import {
  FormProvider as ReactHookFormProvider,
  FormProviderProps as ReactHookFormProviderProps,
  FieldValues,
} from 'react-hook-form';
import { Platform } from 'react-native';

export { useForm } from 'react-hook-form';

export const FormProvider = <TFieldValues extends FieldValues, TContext = any>(
  props: ReactHookFormProviderProps<TFieldValues, TContext> & {
    handleFormSubmit?: any;
  },
) => {
  // For web, ensure our form is wrapped in a form tag
  if (Platform.OS === 'web') {
    return (
      <ReactHookFormProvider {...props}>
        <form
          onSubmit={
            props.handleFormSubmit ?? props.handleSubmit(props.handleFormSubmit)
          }
        >
          {props.children}
        </form>
      </ReactHookFormProvider>
    );
  }
  // For native, there is no concept of a form tag, so render without one
  return (
    <ReactHookFormProvider {...props}>{props.children}</ReactHookFormProvider>
  );
};
