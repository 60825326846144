import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const InboxIcon: FunctionComponent<IconProps> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 16"
      fill="none"
      color={color}
      testID={testID}
    >
      <Path
        d="M18.3337 8.00004H13.3337L11.667 10.5H8.33366L6.66699 8.00004H1.66699M18.3337 8.00004V13C18.3337 13.4421 18.1581 13.866 17.8455 14.1786C17.5329 14.4911 17.109 14.6667 16.667 14.6667H3.33366C2.89163 14.6667 2.46771 14.4911 2.15515 14.1786C1.84259 13.866 1.66699 13.4421 1.66699 13V8.00004M18.3337 8.00004L15.4587 2.25837C15.3207 1.9807 15.108 1.74702 14.8445 1.58361C14.5809 1.4202 14.2771 1.33354 13.967 1.33337H6.03366C5.72359 1.33354 5.41971 1.4202 5.1562 1.58361C4.89268 1.74702 4.67997 1.9807 4.54199 2.25837L1.66699 8.00004"
        stroke="#0093E8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
