import React, { FunctionComponent, PropsWithChildren } from 'react';
import { FormProvider } from 'assets/form';
import { UseFormReturn } from 'react-hook-form';
import { FormRow, FormRowProps } from './FormRow';
import { FormColumn, FormColumnProps } from './FormColumn';
import { FormAlert, FormAlertProps } from './FormAlert';
import { FormActions, FormActionsProps } from './FormActions';
import { FormContent, FormContentProps } from './FormContent';

export const Form: FormFunctionComponent = ({ methods, children }) => {
  return <FormProvider {...methods}>{children}</FormProvider>;
};

export interface FormProps {
  methods: UseFormReturn<any, any>;
}

interface FormFunctionComponent
  extends FunctionComponent<PropsWithChildren<FormProps>> {
  /**
   * Container for Form Columns, adds styling for proper spacing.
   */
  Row: FunctionComponent<PropsWithChildren<FormRowProps>>;
  /**
   * Container for Fields, do not use for other content.
   */
  Column: FunctionComponent<PropsWithChildren<FormColumnProps>>;
  /**
   * Display an alert related to the form.
   */
  Alert: FunctionComponent<PropsWithChildren<FormAlertProps>>;
  /**
   * Container for holding actions, typically a submit button.
   */
  Actions: FunctionComponent<PropsWithChildren<FormActionsProps>>;
  /**
   * A last resort container for non form fields, use *rarely*. Useful for longer forms that need multiple text headers throughout.
   */
  Content: FunctionComponent<PropsWithChildren<FormContentProps>>;
}

// Allow for a style similar to RNP Card component
Form.Row = FormRow;
Form.Column = FormColumn;
Form.Alert = FormAlert;
Form.Actions = FormActions;
Form.Content = FormContent;
