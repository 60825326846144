/* tslint:disable */
/* eslint-disable */
/**
 * user
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SendPasswordResetLink
 */
export interface SendPasswordResetLink {
  /**
   * User's email
   * @type {string}
   * @memberof SendPasswordResetLink
   */
  email: string;
}

export function SendPasswordResetLinkFromJSON(
  json: any,
): SendPasswordResetLink {
  return SendPasswordResetLinkFromJSONTyped(json, false);
}

export function SendPasswordResetLinkFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SendPasswordResetLink {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json['email'],
  };
}

export function SendPasswordResetLinkToJSON(
  value?: SendPasswordResetLink | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
  };
}
