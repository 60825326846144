import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  useRoute,
  useNavigation,
  useIsFocused,
} from '@react-navigation/native';
import { FieldArrayWithId, useFieldArray } from 'react-hook-form';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { useForm } from 'assets/form';
import { Form } from 'assets/layout';
import { DailyAvailabilityInput } from '../daily-availability-input/DailyAvailabilityInput';
import { zIndexAuto } from '../../common/theme';
import { CheckboxField, CheckboxInputMode } from 'assets/components/checkbox';
import { TextField } from 'assets/components/text-field';
import { useAvailabilityFormState } from './availability-form-store';
import {
  availabilitiesToFormData,
  availabilityFields,
  formDataToAvailabilities,
  getAvailabilityOverridesFromAvailabilities,
} from './availability-form-utils';
import { CreateAppointmentTypeScheduleDto } from '@digitalpharmacist/appointment-service-client-axios';
import {
  createSchedule,
  getSchedule,
  resetSchedule,
  setOverrideData,
  setSubmit,
  updateSchedule,
} from './availability-form-actions';
import { ScheduleDrawerNavigationProp } from '../../layout/ScheduleDrawer';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { View } from 'react-native';
import { resetAvailabilitiesList } from '../availabilities-list/availabilities-list-actions';
import { makeStyles, useTheme } from 'assets/theme';
import { Button } from 'assets/components/button';
import { DataGrid } from 'assets/components/data-grid';
import { IconButton } from 'assets/components/icon-button';
import { PencilIcon, TrashIcon } from 'assets/icons';
import { DateOverrideModal } from './DateOverrideModal';
import { Text } from 'assets/components/text';
import { formatDate, formatTimeSpan } from '../../common/datetime-utils';

const defaultFormValues = {
  title: '',
  isDefault: false,
  monday: null,
  tuesday: null,
  wednesday: null,
  thursday: null,
  friday: null,
  saturday: null,
  sunday: null,
};

const AvailabilityDateRenderer = (props: { data: AvailabilityOverride }) => {
  const rowData = props.data;
  const styles = useStyles();

  return (
    <View style={styles.cellContainer}>
      <Text>{formatDate(rowData.date, 'ddd, D MMM')}</Text>
    </View>
  );
};

const AvailabilityHoursRenderer = (props: { data: AvailabilityOverride }) => {
  const rowData = props.data;
  const styles = useStyles();

  return (
    <View style={styles.cellContainer}>
      <Text>
        {rowData.time.map((hours, i) => (
          <>
            {hours && formatTimeSpan(hours?.start, hours?.end)}
            {i + 1 !== rowData.time.length && ', '}
          </>
        ))}
      </Text>
    </View>
  );
};

export const AvailabilityForm: FunctionComponent<AvailabilityFormProps> = (
  props,
) => {
  const { submit, schedule, status } = useAvailabilityFormState();
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();
  const methods = useForm<AvailabilityFormData>({
    defaultValues: defaultFormValues,
  });
  const { fields, append, update, remove } = useFieldArray({
    control: methods.control,
    name: 'availabilityOverrides',
  });
  const route = useRoute<any>();
  const isFocused = useIsFocused();
  const availabilityId = route.params?.availabilityId;
  const edit = !!availabilityId;
  const theme = useTheme();
  const [showDateModal, setShowDateModal] = useState(false);
  const [overrideIndex, setOverrideIndex] = useState<number | null>(null);
  const styles = useStyles();

  useEffect(() => {
    if (availabilityId && isFocused) {
      getSchedule(route.params.availabilityId);
    }

    return () => {
      methods.reset(defaultFormValues);
      resetSchedule();
    };
  }, [availabilityId, isFocused]);

  useEffect(() => {
    if (submit) {
      methods.handleSubmit(handleSubmit)();
      setSubmit(false);
    }
  }, [submit]);

  useEffect(() => {
    if (schedule && edit) {
      const availabilities = availabilitiesToFormData(
        schedule.availability.filter((availability) => !availability.date),
      );
      const availabilityOverrides = getAvailabilityOverridesFromAvailabilities(
        schedule.availability,
      );
      const formData: AvailabilityFormData = {
        title: schedule.title,
        isDefault: schedule.isDefault,
        ...availabilities,
        availabilityOverrides: availabilityOverrides,
      };

      methods.reset(formData);
    }
  }, [schedule]);

  const handleSubmit = async () => {
    const formValue = methods.getValues();
    const data: CreateAppointmentTypeScheduleDto = {
      title: formValue.title,
      isDefault: formValue.isDefault,
      availability: formDataToAvailabilities(formValue),
    };

    if (edit) {
      await updateSchedule(availabilityId, data);
    } else {
      await createSchedule(data);
    }

    resetAvailabilitiesList();

    if (!edit) {
      navigation.navigate('availabilities');
    }
  };

  const handleEditDateClick = (overrideData: AvailabilityOverride) => {
    const overrideIndex = methods
      .getValues()
      .availabilityOverrides.findIndex(
        (field) => field.date === overrideData.date,
      );

    setOverrideIndex(overrideIndex);
    setOverrideData(overrideData);
    setShowDateModal(true);
  };

  const handleDeleteDateClick = (overrideData: AvailabilityOverride) => {
    const overrideIndex = methods
      .getValues()
      .availabilityOverrides.findIndex(
        (field) => field.date === overrideData.date,
      );

    remove(overrideIndex);
  };

  const ActionButtonsRenderer = (props: { data: AvailabilityOverride }) => {
    const rowData = props.data;
    const styles = useStyles();

    return (
      <View style={styles.cellContainer}>
        <IconButton
          icon={PencilIcon}
          logger={{ id: `edit-availability-override` }}
          onPress={() => handleEditDateClick(rowData)}
        />
        <IconButton
          icon={TrashIcon}
          logger={{ id: `delete-availability-override` }}
          onPress={() => handleDeleteDateClick(rowData)}
        />
      </View>
    );
  };

  const [columnDefs] = useState([
    {
      width: 150,
      maxWidth: 150,
      headerName: 'Date',
      cellRenderer: AvailabilityDateRenderer,
      cellStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
      },
    },
    {
      field: 'availability',
      headerName: 'Hours',
      cellRenderer: AvailabilityHoursRenderer,
      cellStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
      },
    },
    {
      width: 150,
      maxWidth: 150,
      field: 'actions',
      headerName: 'Actions',
      cellStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      headerClass: 'data-grid-header-right-aligned',
      cellRenderer: ActionButtonsRenderer,
    },
  ] as (ColDef | ColGroupDef)[]);

  const handleDateModalClose = () => {
    setOverrideIndex(null);
    setShowDateModal(false);
  };

  const handleDateModalSubmit = (time: AvailabilityHours[], date: string) => {
    if (overrideIndex !== null) {
      update(overrideIndex, { time: time, date: date });
    } else {
      append({ time: time, date: date });
    }

    setShowDateModal(false);
  };

  return (
    <>
      {status === 'loading' ? (
        <View style={{ alignItems: 'center' }}>
          <LoadingIndicator color={theme.colors.pharmacyPrimary} />
        </View>
      ) : (
        <View style={{ flex: 1, maxWidth: 530 }}>
          <DateOverrideModal
            show={showDateModal}
            onClose={handleDateModalClose}
            onSubmit={handleDateModalSubmit}
          />
          <Form methods={methods}>
            <Form.Row style={{ marginBottom: theme.getSpacing(2) }}>
              <Form.Column>
                <CheckboxField
                  name="isDefault"
                  label="Set to default"
                  mode={CheckboxInputMode.FLAT}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row style={{ marginBottom: theme.getSpacing(3) }}>
              <Form.Column>
                <TextField
                  name="title"
                  label="Availability name"
                  rules={{ required: 'Name is required' }}
                />
              </Form.Column>
            </Form.Row>
            {availabilityFields.map((field) => (
              <Form.Row style={{ zIndex: zIndexAuto }} key={field.name}>
                <Form.Column style={{ zIndex: zIndexAuto }}>
                  <DailyAvailabilityInput
                    name={field.name}
                    label={field.label}
                  />
                </Form.Column>
              </Form.Row>
            ))}
            <View style={{ marginTop: theme.getSpacing(4) }}>
              <DataGrid
                gridOptions={{
                  onGridReady(event) {
                    event.api.setDomLayout('autoHeight');
                  },
                  rowData: fields,
                  columnDefs: columnDefs,
                  enableCellTextSelection: true,
                  suppressMovableColumns: true,
                  suppressContextMenu: true,
                  defaultColDef: { sortable: false, menuTabs: [] },
                  pagination: false,
                  loadingOverlayComponent: 'loadingIndicator',
                  loadingOverlayComponentParams: {
                    color: theme.colors.pharmacyPrimary,
                  },
                  components: {
                    loadingIndicator: LoadingIndicator,
                  },
                }}
                gridToolbarProps={{
                  titleProps: {
                    title: 'Date overrides',
                  },
                }}
              />
              <View style={styles.buttonContainer}>
                <Button
                  hierarchy="secondary-gray"
                  size="small"
                  logger={{ id: 'add-override-button' }}
                  onPress={() => setShowDateModal(true)}
                >
                  Add override
                </Button>
              </View>
            </View>
          </Form>
        </View>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  cellContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  loadingContainer: {
    marginTop: theme.getSpacing(4),
  },
  buttonContainer: {
    marginTop: theme.getSpacing(3),
    display: 'flex',
    flexDirection: 'row',
  },
}));
export interface AvailabilityFormData {
  title: string;
  isDefault: boolean;
  monday: AvailabilityHours;
  tuesday: AvailabilityHours;
  wednesday: AvailabilityHours;
  thursday: AvailabilityHours;
  friday: AvailabilityHours;
  saturday: AvailabilityHours;
  sunday: AvailabilityHours;
  availabilityOverrides: AvailabilityOverride[];
}

export type AvailabilityHours = {
  start: string;
  end: string;
} | null;

export type AvailabilityOverride = {
  time: AvailabilityHours[];
  date: string;
};

export type AvailabilityOverrideField = FieldArrayWithId<AvailabilityOverride>;

export interface AvailabilityFormProps {
  edit?: boolean;
}
