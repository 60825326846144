import { TaskTypeDto } from '@digitalpharmacist/tasks-service-client-axios';
import create from 'zustand';
import { ApiError, AsyncStatus } from '../../../store/types';

export const useTaskSettingsState = create<TaskSettingsState>((set) => ({
  showModal: false,
  taskTypes: [],
  status: 'idle',
  editingTaskType: false,
}));

interface TaskSettingsState {
  showModal: boolean;
  taskTypes: TaskTypeDto[];
  status: AsyncStatus;
  error?: ApiError;
  editingTaskType: boolean;
  editTaskTypeId?: string;
}
