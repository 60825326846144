import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { makeStyles, useTheme } from 'assets/theme';
import React from 'react';
import { View } from 'react-native';

const VideosFilterButton: React.FC<VideosFilterButtonProps> = ({
  active,
  name,
  icon,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  return (
    <>
      <View>
        <View style={[styles.filter, active && styles.filterActive]}>
          <Icon
            icon={icon}
            color={
              active ? theme.palette.primary[600] : theme.palette.gray[500]
            }
            size={20}
          />
          <Text
            style={[styles.filterText, active && styles.filterTextActive]}
            selectable
          >
            {name}
          </Text>
        </View>
      </View>
    </>
  );
};

export default VideosFilterButton;

export interface VideosFilterButtonProps {
  active: boolean;
  name: string;
  icon: React.FunctionComponent;
}

const useStyles = makeStyles((theme) => ({
  filter: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: theme.getSpacing(1),
    paddingHorizontal: theme.getSpacing(3),
    alignItems: 'center',
    cursor: 'pointer',
  },
  filterActive: {
    backgroundColor: theme.palette.primary[100],
    color: theme.palette.primary[600],
  },
  filterText: {
    marginLeft: theme.getSpacing(1),
    marginRight: 'auto',
    marginVertical: 5,
    color: theme.palette.gray[900],
    fontWeight: '500',
  },
  filterTextActive: {
    color: theme.palette.primary[600],
    fontWeight: '700',
  },
}));
