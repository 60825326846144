/* tslint:disable */
/* eslint-disable */
/**
 * user
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Object containing an accessToken for user identification.
 * @export
 * @interface UserAccessToken
 */
export interface UserAccessToken {
  /**
   * AccessToken for user identification.
   * @type {string}
   * @memberof UserAccessToken
   */
  accessToken: string;
}

export function UserAccessTokenFromJSON(json: any): UserAccessToken {
  return UserAccessTokenFromJSONTyped(json, false);
}

export function UserAccessTokenFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserAccessToken {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    accessToken: json['accessToken'],
  };
}

export function UserAccessTokenToJSON(value?: UserAccessToken | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    accessToken: value.accessToken,
  };
}
