/* tslint:disable */
/* eslint-disable */
/**
 * user
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';

/**
 * List of prescriptions for each patient in the user account
 * @export
 * @interface Medication
 */
export interface Medication {
  /**
   *
   * @type {string}
   * @memberof Medication
   */
  rx: string;
  /**
   *
   * @type {string}
   * @memberof Medication
   */
  patientName?: string;
  /**
   *
   * @type {string}
   * @memberof Medication
   */
  drugName: string;
  /**
   *
   * @type {Date}
   * @memberof Medication
   */
  lastFillDate: Date;
  /**
   *
   * @type {Date}
   * @memberof Medication
   */
  nextFillableDate: Date;
  /**
   *
   * @type {number}
   * @memberof Medication
   */
  refillsRemaining?: number;
  /**
   *
   * @type {number}
   * @memberof Medication
   */
  daysSupply?: number;
  /**
   *
   * @type {string}
   * @memberof Medication
   */
  sig?: string;
  /**
   *
   * @type {string}
   * @memberof Medication
   */
  autoRefillStatus?: string;
  /**
   *
   * @type {string}
   * @memberof Medication
   */
  prescriber?: string;
  /**
   *
   * @type {string}
   * @memberof Medication
   */
  prescriberPhone?: string;
  /**
   *
   * @type {Date}
   * @memberof Medication
   */
  dob?: Date;
  rx_message?: string;
  rx_status?: string;
}

export function MedicationFromJSON(json: any): Medication {
  return MedicationFromJSONTyped(json, false);
}

export function MedicationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Medication {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    rx: json['rx'],
    patientName: !exists(json, 'patientName') ? undefined : json['patientName'],
    drugName: json['drugName'],
    lastFillDate: new Date(json['lastFillDate']),
    nextFillableDate: new Date(json['nextFillableDate']),
    refillsRemaining: !exists(json, 'refillsRemaining')
      ? undefined
      : json['refillsRemaining'],
    daysSupply: !exists(json, 'daysSupply') ? undefined : json['daysSupply'],
    sig: !exists(json, 'sig') ? undefined : json['sig'],
    autoRefillStatus: !exists(json, 'autoRefillStatus')
      ? undefined
      : json['autoRefillStatus'],
    prescriber: !exists(json, 'prescriber') ? undefined : json['prescriber'],
    prescriberPhone: !exists(json, 'prescriberPhone')
      ? undefined
      : json['prescriberPhone'],
    dob: !exists(json, 'dob') ? undefined : new Date(json['dob']),
  };
}

export function MedicationToJSON(value?: Medication | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    rx: value.rx,
    patientName: value.patientName,
    drugName: value.drugName,
    lastFillDate: value.lastFillDate.toISOString().substr(0, 10),
    nextFillableDate: value.nextFillableDate.toISOString().substr(0, 10),
    refillsRemaining: value.refillsRemaining,
    daysSupply: value.daysSupply,
    sig: value.sig,
    autoRefillStatus: value.autoRefillStatus,
    prescriber: value.prescriber,
    prescriberPhone: value.prescriberPhone,
    dob:
      value.dob === undefined
        ? undefined
        : value.dob.toISOString().substr(0, 10),
  };
}
