/* tslint:disable */
/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull frontend'
 *
 * Required dependencies: @amplitude/analytics-react-native@^0.4.0, @react-native-async-storage/async-storage@^1.17.9
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: react-native:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/implementation/frontend)
 */

import * as amplitude from '@amplitude/analytics-react-native';

export type ReactNativeClient = amplitude.Types.ReactNativeClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
export type ReactNativeOptions = amplitude.Types.ReactNativeOptions;

export type Environment = 'production' | 'development' | 'staging';

export const ApiKey: Record<Environment, string> = {
  production: 'd4341537b88ea33aea6da1eae70fab23',
  development: 'a798881857ab5def879e60af9d368994',
  staging: '764c88e9dcbb6b9ff724c13e29b867b5',
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: ReactNativeOptions = {
  plan: {
    version: '1',
    branch: 'main',
    source: 'frontend',
    versionId: '87fb8f78-fd40-4cd2-a972-cd18ac02039e',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'react-native-typescript-ampli',
      sourceVersion: '2.0.0',
    },
  },
};

export interface LoadOptionsBase {
  disabled?: boolean;
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment;
  client?: { configuration?: ReactNativeOptions };
};
export type LoadOptionsWithApiKey = LoadOptionsBase & {
  client: { apiKey: string; configuration?: ReactNativeOptions };
};
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
  client: { instance: ReactNativeClient };
};

export type LoadOptions =
  | LoadOptionsWithEnvironment
  | LoadOptionsWithApiKey
  | LoadOptionsWithClientInstance;

export interface FormCreatedProperties {
  formAccess: string;
  formSavedTime: string;
  formStatus: string;
  formType: string;
}

export interface FormDownloadedProperties {
  formDownloadedTime: string;
}

export interface FormEditedProperties {
  deviceId: string;
  formEditedTime: string;
  formEditedType: string;
  formName: string;
  formType: string;
  userid: string;
  userType: string;
}

export interface FormNotificationProperties {
  deviceId: string;
  formNotificationMethod: string;
  formNotificationStatus: string;
  userid: string;
  userType: string;
}

export interface FormSubmissionViewedProperties {
  formSubmissionViewedTime: string;
}

export interface UserLoginProperties {
  method: string;
  pharmacy_id: string;
  status: string;
}

export interface PharmacyUserProperties {
  deviceId: string;
  userid: string;
  userType: string;
}

export class FormCreated implements BaseEvent {
  event_type = 'formCreated';

  constructor(public event_properties: FormCreatedProperties) {
    this.event_properties = event_properties;
  }
}

export class FormDownloaded implements BaseEvent {
  event_type = 'formDownloaded';

  constructor(public event_properties: FormDownloadedProperties) {
    this.event_properties = event_properties;
  }
}

export class FormEdited implements BaseEvent {
  event_type = 'formEdited';

  constructor(public event_properties: FormEditedProperties) {
    this.event_properties = event_properties;
  }
}

export class FormNotification implements BaseEvent {
  event_type = 'formNotification';

  constructor(public event_properties: FormNotificationProperties) {
    this.event_properties = event_properties;
  }
}

export class FormStatus implements BaseEvent {
  event_type = 'formStatus';
}

export class FormSubmissionViewed implements BaseEvent {
  event_type = 'formSubmissionViewed';

  constructor(public event_properties: FormSubmissionViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class UserLogin implements BaseEvent {
  event_type = 'UserLogin';

  constructor(public event_properties: UserLoginProperties) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: ReactNativeClient;

  get client(): ReactNativeClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();

    return this.amplitude!.identify(amplitudeIdentify, options);
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Flush pending events.
   */
  flush(): PromiseResult<void> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * formCreated
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/formCreated)
   *
   * Owner: Priti Jagger
   *
   * @param properties The event's properties (e.g. formAccess)
   * @param options Amplitude event options.
   */
  formCreated(
    properties: FormCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FormCreated(properties), options);
  }

  /**
   * formDownloaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/formDownloaded)
   *
   * Owner: Priti Jagger
   *
   * @param properties The event's properties (e.g. formDownloadedTime)
   * @param options Amplitude event options.
   */
  formDownloaded(
    properties: FormDownloadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FormDownloaded(properties), options);
  }

  /**
   * formEdited
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/formEdited)
   *
   * Owner: Priti Jagger
   *
   * @param properties The event's properties (e.g. deviceId)
   * @param options Amplitude event options.
   */
  formEdited(
    properties: FormEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FormEdited(properties), options);
  }

  /**
   * formNotification
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/formNotification)
   *
   * Owner: Priti Jagger
   *
   * @param properties The event's properties (e.g. deviceId)
   * @param options Amplitude event options.
   */
  formNotification(
    properties: FormNotificationProperties,
    options?: EventOptions,
  ) {
    return this.track(new FormNotification(properties), options);
  }

  /**
   * formStatus
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/formStatus)
   *
   * Owner: Priti Jagger
   *
   * @param options Amplitude event options.
   */
  formStatus(
    options?: EventOptions,
  ) {
    return this.track(new FormStatus(), options);
  }

  /**
   * formSubmissionViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/formSubmissionViewed)
   *
   * Owner: Priti Jagger
   *
   * @param properties The event's properties (e.g. formSubmissionViewedTime)
   * @param options Amplitude event options.
   */
  formSubmissionViewed(
    properties: FormSubmissionViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FormSubmissionViewed(properties), options);
  }

  /**
   * UserLogin
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/UserLogin)
   *
   * Owner: Eldrin Sekiraqa
   *
   * @param properties The event's properties (e.g. method)
   * @param options Amplitude event options.
   */
  userLogin(
    properties: UserLoginProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserLogin(properties), options);
  }
}

export const ampli = new Ampli();
