import { CreateAppointmentTypeDto } from '@digitalpharmacist/appointment-service-client-axios';
import { logError } from '../../../../../packages/assets/logging/logger';
import AppointmentService from '../../api/AppointmentService';
import { useToast } from '../../common/hooks/useToast';
import { useAppStateStore } from '../../store/app-store';
import { useServiceFormState } from './service-form-store';
import FormsService from '../../api/FormsService';
import { FormStatus } from '@digitalpharmacist/forms-service-client-axios';

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : 'An error occurred while trying to load the service. Please try again.';

  logError(error);
  useServiceFormState.setState({
    error: {
      message: message,
    },
    status: 'error',
  });

  toast('Error', { type: 'error', content: message });
};

export const getAppointmentGroups = async () => {
  useServiceFormState.setState({ status: 'loading' });

  try {
    const { pharmacyId } = useAppStateStore.getState();

    const response = await AppointmentService.findAppointmentGroups(pharmacyId);

    useServiceFormState.setState({
      status: 'success',
      appointmentGroups: response,
    });
  } catch (error: any) {
    errorOccurred(error);
  }
};

export const resetAppointmentGroups = () => {
  useServiceFormState.setState({ appointmentGroups: undefined });
};

export const getSchedules = async () => {
  useServiceFormState.setState({ status: 'loading' });

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    const response = await AppointmentService.findAppointmentTypeSchedules(
      pharmacyId,
      locationId,
    );

    useServiceFormState.setState({
      status: 'success',
      schedules: response,
    });
  } catch (error: any) {
    errorOccurred(error);
  }
};

export const resetSchedules = () => {
  useServiceFormState.setState({ schedules: undefined });
};

export const createAppointmentType = async (
  appointmentType: CreateAppointmentTypeDto,
) => {
  const { toast } = useToast();
  useServiceFormState.setState({ status: 'loading' });

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    const response = await AppointmentService.createAppointmentType(
      pharmacyId,
      locationId,
      appointmentType,
    );

    useServiceFormState.setState({
      status: 'success',
      appointmentType: response,
    });

    toast('Service created', { type: 'success' });
  } catch (error: any) {
    errorOccurred(
      error,
      'An error occurred while trying to create the service. Please try again.',
    );
  }
};

export const updateAppointmentType = async (
  appointmentId: string,
  appointmentType: CreateAppointmentTypeDto,
) => {
  const { toast } = useToast();
  useServiceFormState.setState({ status: 'loading' });

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    const response = await AppointmentService.updateAppointmentType(
      pharmacyId,
      locationId,
      appointmentId,
      appointmentType,
    );

    useServiceFormState.setState({
      status: 'success',
      appointmentType: response,
    });

    toast('Service updated', { type: 'success' });
  } catch (error: any) {
    errorOccurred(
      error,
      'An error occurred while trying to update the service. Please try again.',
    );
  }
};

export const getAppointmentType = async (appointmentId: string) => {
  useServiceFormState.setState({ status: 'loading' });

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    const response = await AppointmentService.findAppointmentTypeById(
      pharmacyId,
      locationId,
      appointmentId,
    );

    useServiceFormState.setState({
      status: 'success',
      appointmentType: response,
    });
  } catch (error: any) {
    errorOccurred(error);
  }
};

export const resetAppointmentType = () => {
  useServiceFormState.setState({ appointmentType: undefined });
};

export const deleteAppointmentType = async (appointmentId: string) => {
  useServiceFormState.setState({ status: 'loading' });

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    await AppointmentService.deleteAppointmentType(
      pharmacyId,
      locationId,
      appointmentId,
    );

    useServiceFormState.setState({
      status: 'success',
    });
  } catch (error: any) {
    errorOccurred(
      error,
      'An error occurred while trying to delete the service. Please try again.',
    );
  }
};

export const submitForm = () => {
  setSubmit(true);
};

export const setSubmit = (submit: boolean) => {
  useServiceFormState.setState({ submit });
};

export const getForms = async () => {
  useServiceFormState.setState({ formsStatus: 'loading' });

  try {
    const { locationId } = useAppStateStore.getState();

    const response = await FormsService.getFormsForLocation(
      locationId,
      FormStatus.Enabled,
    );

    useServiceFormState.setState({
      forms: response,
      formsStatus: 'success',
    });
  } catch (error) {
    errorOccurred(error);
    useServiceFormState.setState({ formsStatus: 'error' });
  }
};
