import {
  AppointmentGroupDto,
  AppointmentTypeDto,
  AppointmentTypeScheduleDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import { ListFormDto } from '@digitalpharmacist/forms-service-client-axios';
import create from 'zustand';
import { ApiError, AsyncStatus } from '../../store/types';

export const useServiceFormState = create<ServiceFormState>(() => ({
  status: 'idle',
  formsStatus: 'idle',
  submit: false,
  limitBookingFrequenciesEnabled: false,
  limitTotalBookingDurationEnabled: false,
}));

interface ServiceFormState {
  status: AsyncStatus;
  formsStatus: AsyncStatus;
  submit: boolean;
  error?: ApiError;
  appointmentGroups?: AppointmentGroupDto[];
  schedules?: AppointmentTypeScheduleDto[];
  appointmentType?: AppointmentTypeDto;
  limitBookingFrequenciesEnabled: boolean;
  limitTotalBookingDurationEnabled: boolean;
  forms?: ListFormDto[];
}
