import { AppointmentTypeDto } from '@digitalpharmacist/appointment-service-client-axios';
import { ApiError, AsyncStatus } from '../../store/types';
import create from 'zustand';
import { GridApi } from '@ag-grid-community/core';

export const useServicesListState = create<ServicesListState>(() => ({
  status: 'idle',
  error: undefined,
  services: undefined,
  gridApi: undefined,
}));

interface ServicesListState {
  status: AsyncStatus;
  error?: ApiError;
  services?: AppointmentTypeDto[];
  gridApi?: GridApi;
}
