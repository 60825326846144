import create from 'zustand';
import { ApiError, AsyncStatus } from '../../store/types';

export const useCreatePasswordState = create<CreatePasswordState>((set) => ({
  status: 'idle',
  data: undefined,
  updateData: (data: CreatePasswordState) => {
    set({ ...data });
  },
}));

interface CreatePasswordState {
  status: AsyncStatus;
  error?: ApiError;
}
