import React from 'react';
import { Separator } from 'react-contexify';
import SearchFilter from '../../components/videos/SearchFilter';
import VideosBrowseCollection from '../../components/videos/VideosBrowseCollection';

const VideosBrowse: React.FC = () => {
  return (
    <>
      <SearchFilter />
      <Separator />
      <VideosBrowseCollection />
    </>
  );
};

export default VideosBrowse;
