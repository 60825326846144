import { Fragment, FunctionComponent } from 'react';
import { ErrorObject, ErrorStatus } from '../error-store/error-store';
import { removeError } from '../error-store/error-actions';
import { Alert } from 'assets/components/alert';
import { useTheme } from 'assets/theme';

const timers: Record<string, ReturnType<typeof setTimeout>> = {};
const timeoutTime = 10000;

export const ErrorsContainer: FunctionComponent<ErrorsContainerProps> = ({
  errorObject,
  excludedErrors = [],
}) => {
  const theme = useTheme();

  const removeErrorDelayed = (selectedObject: string) => {
    if (selectedObject in timers) {
      clearTimeout(timers[selectedObject]);
    }
    const timerId: ReturnType<typeof setTimeout> = setTimeout(() => {
      removeError(selectedObject);
    }, timeoutTime);
    timers[selectedObject] = timerId;
  };

  return (
    <>
      {Object.keys(errorObject).map((selectedObject: string) => {
        if (excludedErrors.includes(selectedObject)) {
          return <Fragment key={selectedObject}></Fragment>;
        } else if (errorObject[selectedObject].status === ErrorStatus.ERROR) {
          removeErrorDelayed(selectedObject);
          return (
            <Fragment key={selectedObject}>
              <Alert
                title={errorObject[selectedObject].message}
                intent="error"
                style={{ marginBottom: theme.getSpacing(2) }}
              />
            </Fragment>
          );
        }

        return <Fragment key={selectedObject}></Fragment>;
      })}
    </>
  );
};

interface ErrorsContainerProps {
  errorObject: ErrorObject;
  excludedErrors?: string[];
}
