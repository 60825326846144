import React, { FunctionComponent, PropsWithChildren } from 'react';
import { useTheme } from '../../theme';

/* TODO: its worth investigating https://github.com/kristerkari/react-native-svg-transformer
 * and its example usage https://github.com/kristerkari/react-native-svg-expo-example once its
 * a bit more stable so that we may import svg files directly
 */

export const Icon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  icon,
  size = 24,
  color,
  testID,
}) => {
  const theme = useTheme();

  return React.createElement<any>(icon, {
    size,
    color: color ?? theme.palette.black,
    testID,
  });
};

export interface IconProps {
  icon: FunctionComponent;
  size?: number;
  color?: string;
  testID?: string;
}
