import React from 'react';
import { Menu, Provider } from 'react-native-paper';
import OutsidePressHandler from 'react-native-outside-press';
import type { IconSource } from 'react-native-paper/lib/typescript/components/Icon';

import { ThreedotsIcon } from '../../../icons';
import { IconButton } from '../../icon-button';
import { useTheme } from '../../../theme';

export const ToolbarMenu: React.FC<ToolbarMenuProps> = ({
  options,
  anchorPosition = 'right',
  dismissOnClickAway,
}) => {
  const theme = useTheme();

  const [visible, setVisible] = React.useState(false);

  const openCloseMenu = () => setVisible((v) => !v);

  const closeMenu = () => setVisible(false);

  const handleOnPress = (onPress: () => void) => {
    if (onPress) onPress();
    closeMenu();
  };

  return (
    <OutsidePressHandler
      onOutsidePress={closeMenu}
      disabled={!dismissOnClickAway || !visible}
    >
      <Provider theme={theme}>
        <Menu
          visible={visible}
          onDismiss={openCloseMenu}
          style={{ top: 0, left: anchorPosition === 'right' ? 30 : -195 }}
          statusBarHeight={0}
          anchor={
            <IconButton
              icon={ThreedotsIcon}
              logger={{ id: 'ThreedotsIcon' }}
              onPress={openCloseMenu}
              size={24}
              color={theme.palette.gray[700]}
              style={{
                margin: 0,
                padding: 0,
                height: 'unset',
                width: 'unset',
              }}
            />
          }
        >
          {options.map((op, index) => (
            <Menu.Item
              key={index}
              {...op}
              onPress={() => handleOnPress(op.onPress)}
              titleStyle={{ width: 100 }}
            />
          ))}
        </Menu>
      </Provider>
    </OutsidePressHandler>
  );
};

export interface ToolbarMenuProps {
  options: Array<ToolbarMenuItemProps>;
  anchorPosition?: 'left' | 'right';
  dismissOnClickAway?: boolean;
}

export const ToolbarMenuIconActionButtonsTestIDs = {
  label: 'toolbar-menu-label',
};

export interface ToolbarMenuItemProps {
  onPress: () => void;
  title: string;
  icon?: IconSource;
  disabled?: boolean;
}
