import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View, Linking } from 'react-native';

import { Text } from 'assets/components/text';
import { Button } from 'assets/components/button';
import { makeStyles } from 'assets/theme';
import { DownloadIcon } from 'assets/icons';

import { INSTRUCTIONS_PDF_URL } from '../forms-import-stepper.content';

export const InstructionsStep: FunctionComponent = () => {
  const styles = useStyles();
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Instructions</Text>

      <Text style={styles.contentText}>
        Accessing an existing JotForm account in Lumistry requires an API key
        found in the Jotform settings.
      </Text>

      <Text style={styles.contentText}>
        The .pdf below contains a short, step-by-step guide for obtaining this
        key.
      </Text>

      <View style={styles.buttonContainer}>
        <Button
          hierarchy="secondary-gray"
          size="small"
          logger={{ id: 'download-submission-button' }}
          onPress={() => Linking.openURL(INSTRUCTIONS_PDF_URL)}
          icon={DownloadIcon}
          loading={false}
        >
          Open PDF With Instructions
        </Button>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
  },
  title: {
    ...theme.fonts.medium,
    fontSize: 20,
    fontWeight: '600',
    paddingBottom: theme.getSpacing(2),
  },
  contentText: {
    ...theme.fonts.regular,
    fontSize: 20,
    lineHeight: 30,
    fontWeight: '400',
    paddingBottom: theme.getSpacing(2),
  },
  buttonContainer: {
    flexDirection: 'row',
    paddingTop: theme.getSpacing(2),
  },
}));

export default InstructionsStep;
