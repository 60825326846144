import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from 'assets/components/text';
import Select, {
  OptionProps,
  SingleValueProps,
  MultiValueProps,
  PlaceholderProps,
  GroupBase,
  Props,
  components,
} from 'react-select';
import { makeStyles, useTheme } from 'assets/theme';
import { DropDownSelectTestIDs } from './advanced-drop-down-test-ids';

export interface AdvancedDropDownProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends Omit<Props<Option, IsMulti, Group>, 'components'> {
  label?: string;

  singleValueTemplate?: (
    props: SingleValueProps<Option, IsMulti, Group>,
  ) => JSX.Element;

  multiValueTemplate?: (
    props: MultiValueProps<Option, IsMulti, Group>,
  ) => JSX.Element;

  placeholderTemplate?: (
    props: PlaceholderProps<Option, IsMulti, Group>,
  ) => JSX.Element;

  optionTemplate?: (props: OptionProps<Option, IsMulti, Group>) => JSX.Element;
}

/**
 * This is just a wrapper of react-select. We have applied common styles and functionalities
 * @docs https://react-select.com/
 * @returns `JSX.Element`
 */
export const AdvancedDropDown = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  label,
  optionTemplate,
  singleValueTemplate,
  multiValueTemplate,
  placeholderTemplate,
  ...selectProps
}: AdvancedDropDownProps<Option, IsMulti, Group>) => {
  const styles = useStyles();
  const theme = useTheme();
  const { styles: selectStyles, ...otherSelectProps } = selectProps;

  const { dropdownIndicator, control, option, ...otherSelectStyles } = {
    ...selectStyles,
  };

  return (
    <>
      <View>
        {label && (
          <Text style={styles.label} testID={DropDownSelectTestIDs.label}>
            {label}
          </Text>
        )}
      </View>
      <Select
        menuPlacement="auto"
        menuPortalTarget={document.body}
        aria-label="Select"
        styles={{
          dropdownIndicator: (base, state) => ({
            ...base,
            transition: 'all .2s ease',
            transform: state.selectProps.menuIsOpen
              ? ('rotate(180deg)' as any)
              : null,
            ...dropdownIndicator?.(base, state),
          }),
          control: (base, state) => ({
            ...base,
            borderRadius: theme.roundness,
            fontFamily: theme.fonts.regular.fontFamily,
            boxShadow: state.isFocused
              ? `0px 1px 2px ${theme.palette.primary[100]}, 0px 0px 0px 4px ${theme.palette.gray[50]}`
              : 'none',
            borderColor: state.isFocused
              ? theme.palette.primary[500]
              : theme.palette.gray[300],
            ':hover': {
              borderColor: state.isFocused
                ? theme.palette.primary[500]
                : theme.palette.gray[300],
            },
            ...selectStyles?.control?.(base, state),
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor:
              state.isFocused || state.isSelected
                ? theme.palette.primary[50]
                : undefined,
            color: state.isSelected ? theme.palette.primary[500] : undefined,
            ...selectStyles?.option?.(base, state),
          }),
          ...otherSelectStyles,
        }}
        components={{
          IndicatorSeparator: () => null,
          Option: ({
            children,
            ...props
          }: React.PropsWithChildren<OptionProps<Option, IsMulti, Group>>) => {
            return (
              <components.Option {...props}>
                {optionTemplate?.(
                  props as OptionProps<Option, IsMulti, Group>,
                ) ?? children}
              </components.Option>
            );
          },
          SingleValue: ({
            children,
            ...props
          }: React.PropsWithChildren<
            SingleValueProps<Option, IsMulti, Group>
          >) => {
            return (
              <components.SingleValue {...props}>
                {singleValueTemplate?.(
                  props as SingleValueProps<Option, IsMulti, Group>,
                ) ?? children}
              </components.SingleValue>
            );
          },
          MultiValue: ({
            children,
            ...props
          }: React.PropsWithChildren<
            MultiValueProps<Option, IsMulti, Group>
          >) => {
            return (
              <components.MultiValue {...props}>
                {multiValueTemplate?.(
                  props as MultiValueProps<Option, IsMulti, Group>,
                ) ?? children}
              </components.MultiValue>
            );
          },
          Placeholder: ({
            children,
            ...props
          }: React.PropsWithChildren<
            PlaceholderProps<Option, IsMulti, Group>
          >) => {
            return (
              <components.Placeholder {...props}>
                {placeholderTemplate?.(
                  props as PlaceholderProps<Option, IsMulti, Group>,
                ) ?? children}
              </components.Placeholder>
            );
          },
        }}
        {...otherSelectProps}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.gray[700],
    fontSize: 14,
    marginBottom: theme.getSpacing(1),
  },
}));
