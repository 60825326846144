import { useLoadingState } from './loading-store';

export const setLoading = (selectedObject: string, isLoading: boolean) => {
  const { loadingObject } = useLoadingState.getState();
  const newData = {
    ...loadingObject,
    [selectedObject]: {
      isLoading: isLoading,
    },
  };
  useLoadingState.setState({ loadingObject: newData });
};
