import create from 'zustand';
import { ApiError, AsyncStatus } from '../../store/types';

export const useLoginState = create<LoginState>((set) => ({
  status: 'idle',
  data: undefined,
}));

interface LoginState {
  status: AsyncStatus;
  error?: ApiError;
}
