import React, { FunctionComponent } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { CheckboxBase } from 'assets/components/checkbox/CheckboxBase';
import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';

export const CheckboxFilter: FunctionComponent<CheckboxFilterProps> = ({
  active,
  name,
  value,
  disabled,
  onPress,
}) => {
  const styles = useStyles();

  return (
    <TouchableOpacity onPress={() => onPress(value)} disabled={disabled}>
      <View style={[styles.filter, disabled && styles.disabled]}>
        <CheckboxBase onPress={() => onPress(value)} isChecked={active} />
        <Text style={styles.text}>{name}</Text>
      </View>
    </TouchableOpacity>
  );
};

export interface CheckboxFilterProps {
  active: boolean;
  name: string;
  value: string | number;
  disabled?: boolean;
  onPress: (filter: string | number) => void;
}

const useStyles = makeStyles((theme) => ({
  filter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: theme.getSpacing(1),
    paddingHorizontal: theme.getSpacing(3),
    cursor: 'pointer',
  },
  disabled: {
    opacity: 0.5,
  },
  text: {
    color: theme.palette.gray[700],
    fontWeight: '500',
    marginLeft: theme.getSpacing(1),
  },
}));
