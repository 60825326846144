import { useNewChatModalState } from './new-chat-modal-store';

export const setTemplatesFilter = (filter: string) => {
  useNewChatModalState.setState({ templatesFilter: filter });
};

export const setShowEmojis = (showEmojis: boolean) => {
  useNewChatModalState.setState({ showEmojis: showEmojis });
};

export const setErrorFields = (errorFields: Record<string, string>) => {
  useNewChatModalState.setState({ errorFields: errorFields });
};

export const setInputFieldsData = (inputFieldsData: Record<string, any>) => {
  useNewChatModalState.setState({ inputFieldsData: inputFieldsData });
};

export const setCursorPosition = (cursorPosition: Record<string, number>) => {
  useNewChatModalState.setState({ cursorPosition: cursorPosition });
};
