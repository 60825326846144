import { FunctionComponent, PropsWithChildren } from 'react';
import CredentialsSteps from './steps/CredentialsSteps';
import InstructionsStep from './steps/InstructionsStep';
import FormsImportStep from './steps/FormsImportStep';
import ImportPlanStep from './steps/ImportPlanStep';
import ImportForms from './steps/ImportForms';

interface ImportStepContent {
  id: string;
  title: string;
  component: FunctionComponent;
  buttonText: string;
  hasNavigationButtons: boolean;
}

export const INSTRUCTIONS_PDF_URL =
  'https://storage.googleapis.com/static.dev.lumistry.com/forms_static/forms-import--instructions--v1.pdf';

export const IMPORT_STEPPER_CONTENT: ImportStepContent[] = [
  {
    id: 'instructions',
    title: 'Instructions',
    component: InstructionsStep,
    buttonText: 'Next',
    hasNavigationButtons: true,
  },
  {
    id: 'credentials',
    title: 'Credentials',
    component: CredentialsSteps,
    buttonText: 'Next',
    hasNavigationButtons: true,
  },
  {
    id: 'formsImport',
    title: 'Import',
    component: FormsImportStep,
    buttonText: 'Next',
    hasNavigationButtons: true,
  },
  {
    id: 'importPlan',
    title: 'Import Plan',
    component: ImportPlanStep,
    buttonText: 'Import',
    hasNavigationButtons: true,
  },
  {
    id: 'importForms',
    title: 'Import Forms',
    component: ImportForms,
    buttonText: 'Close',
    hasNavigationButtons: false,
  },
];
