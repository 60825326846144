import { useTheme } from '../../theme';
import { ButtonHierarchy, ButtonSizes } from './Button';

export function useButtonVariantMappings() {
  const theme = useTheme();

  const buttonBackgroundColorMappings = new Map<
    ButtonHierarchy,
    { disabled: string; enabled: string }
  >([
    [
      'primary',
      {
        enabled: theme.colors.brandedPrimary,
        disabled: theme.colors.brandedPrimaryDisabled,
      },
    ],
    [
      'pharmacy-primary',
      {
        enabled: theme.colors.pharmacyPrimary,
        disabled: theme.colors.pharmacyPrimaryDisabled,
      },
    ],
    [
      'secondary',
      { enabled: theme.palette.white, disabled: theme.palette.white },
    ],
    [
      'pharmacy-secondary',
      { enabled: theme.palette.white, disabled: theme.palette.white },
    ],
    [
      'secondary-alt',
      {
        enabled: theme.colors.brandedSecondary,
        disabled: theme.colors.brandedSecondaryDisabled,
      },
    ],
    [
      'secondary-gray',
      { enabled: theme.palette.white, disabled: theme.palette.white },
    ],
    [
      'tertiary',
      {
        enabled: theme.palette.transparent,
        disabled: theme.palette.transparent,
      },
    ],
    [
      'tertiary-gray',
      { enabled: theme.palette.white, disabled: theme.palette.white },
    ],
    [
      'destructive',
      { enabled: theme.palette.error[600], disabled: theme.palette.error[200] },
    ],
    [
      'destructive-secondary',
      {
        enabled: theme.palette.white,
        disabled: theme.palette.white,
      },
    ],
  ]);

  const buttonBorderColorMappings = new Map<
    ButtonHierarchy,
    { disabled: string; enabled: string }
  >([
    [
      'primary',
      {
        enabled: theme.colors.brandedPrimary,
        disabled: theme.colors.brandedPrimaryDisabled,
      },
    ],
    [
      'pharmacy-primary',
      {
        enabled: theme.colors.pharmacyPrimary,
        disabled: theme.colors.pharmacyPrimaryDisabled,
      },
    ],
    [
      'secondary',
      {
        enabled: theme.colors.brandedPrimary,
        disabled: theme.colors.brandedPrimaryDisabled,
      },
    ],
    [
      'pharmacy-secondary',
      {
        enabled: theme.colors.pharmacySecondary,
        disabled: theme.colors.pharmacySecondaryDisabled,
      },
    ],
    [
      'secondary-alt',
      {
        enabled: theme.colors.brandedSecondary,
        disabled: theme.colors.brandedSecondaryDisabled,
      },
    ],
    [
      'secondary-gray',
      { enabled: theme.palette.gray[500], disabled: theme.palette.gray[200] },
    ],
    [
      'tertiary',
      {
        enabled: theme.palette.transparent,
        disabled: theme.palette.transparent,
      },
    ],
    [
      'tertiary-gray',
      {
        enabled: theme.palette.transparent,
        disabled: theme.palette.transparent,
      },
    ],
    [
      'destructive',
      { enabled: theme.palette.error[600], disabled: theme.palette.error[200] },
    ],
    [
      'destructive-secondary',
      {
        enabled: theme.palette.error[600],
        disabled: theme.palette.error[200],
      },
    ],
  ]);

  const buttonTextColorMappings = new Map<
    ButtonHierarchy,
    { disabled: string; enabled: string }
  >([
    [
      'primary',
      { enabled: theme.palette.white, disabled: theme.palette.white },
    ],
    [
      'pharmacy-primary',
      { enabled: theme.palette.white, disabled: theme.palette.white },
    ],
    [
      'secondary',
      {
        enabled: theme.colors.brandedPrimary,
        disabled: theme.colors.brandedTextDisabled,
      },
    ],
    [
      'pharmacy-secondary',
      {
        enabled: theme.colors.pharmacySecondary,
        disabled: theme.colors.pharmacySecondaryDisabled,
      },
    ],
    [
      'secondary-alt',
      {
        enabled: theme.colors.brandedSecondary,
        disabled: theme.colors.brandedSecondaryDisabled,
      },
    ],
    [
      'secondary-gray',
      { enabled: theme.palette.gray[700], disabled: theme.palette.gray[300] },
    ],
    [
      'tertiary',
      {
        enabled: theme.colors.brandedPrimary,
        disabled: theme.colors.brandedPrimaryDisabled,
      },
    ],
    [
      'tertiary-gray',
      { enabled: theme.palette.gray[500], disabled: theme.palette.gray[300] },
    ],
    [
      'destructive',
      { enabled: theme.palette.white, disabled: theme.palette.white },
    ],
    [
      'destructive-secondary',
      { enabled: theme.palette.error[600], disabled: theme.palette.error[200] },
    ],
  ]);

  const buttonModeMappings = new Map<ButtonHierarchy, 'contained' | 'outlined'>(
    [
      ['primary', 'contained'],
      ['pharmacy-primary', 'contained'],
      ['secondary', 'outlined'],
      ['secondary-alt', 'outlined'],
      ['secondary-gray', 'outlined'],
      ['tertiary', 'outlined'],
      ['tertiary-gray', 'outlined'],
      ['destructive', 'outlined'],
      ['destructive-secondary', 'outlined'],
    ],
  );

  const buttonHeightMappings = new Map<ButtonSizes, number>([
    ['small', 9],
    ['medium', 11],
    ['large', 12],
    ['extra-large', 14],
    ['extra-extra-large', 17],
  ]);

  const buttonTextSizeMappings = new Map<ButtonSizes, number>([
    ['small', 14],
    ['medium', 14],
    ['large', 16],
    ['extra-large', 16],
    ['extra-extra-large', 18],
  ]);

  return {
    buttonBackgroundColorMappings,
    buttonBorderColorMappings,
    buttonTextColorMappings,
    buttonModeMappings,
    buttonHeightMappings,
    buttonTextSizeMappings,
  };
}
