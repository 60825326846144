import axios, { AxiosInstance, AxiosError } from 'axios';
import { DataGridRemoteRequest } from '../../types';
import { IServerSideGetRowsRequest } from '@ag-grid-community/core';

export class DataGridService {
  remoteUrl: string;
  axiosInstance: AxiosInstance;

  constructor(remoteUrl: string, axiosInstance?: AxiosInstance) {
    this.remoteUrl = remoteUrl;
    this.axiosInstance =
      axiosInstance ??
      axios.create({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

    this.axiosInstance.defaults.transformResponse = (params) =>
      this.transformDataGridResponse(params);

    this.axiosInstance.interceptors.response.use(
      (res) => res,
      this.errorHandler,
    );
  }

  /**
   * Transforms ServerSideGetRowsRequest to DataGridRemoteRequest
   * Override if you need to
   * @param requestParams IServerSideGetRowsRequest
   * @returns `DataGridRemoteRequest` (marked as unknown in order to let the possibility to override for different types of datasources, as default it returns `DataGridRemoteRequest`)
   */
  transformDataGridRequest(requestParams: IServerSideGetRowsRequest): unknown {
    const requestData: DataGridRemoteRequest = {
      pagination: this.transformDataGridPaginationModel(requestParams) as any,
      // TODO: implement filter resolve
      filter: this.transformDataGridFilterModel(requestParams) as any,
      // TODO: implement sorting resolve
      sort: this.transformDataGridSortingModel(requestParams) as any,
    };

    return requestData;
  }

  /**
   * The real response payload that comes from the server
   * In case we need to do some response transformation after received the request
   * Override if you need to
   * @param value
   * @returns
   */
  transformDataGridResponse(value: any): any {
    return typeof value === 'string' ? JSON.parse(value) : value;
  }

  /**
   * In case we want to handle differently DataGrid specific errors
   * Override if you need to
   * @param error
   * @returns
   */
  errorHandler(error: AxiosError): Promise<AxiosError> {
    return Promise.reject(error);
  }

  /**
   * Making the real api request
   * Override if you need to
   * @param requestData as any in order to support any kind of different datasource requests
   * @returns is any in order to support any kind of different datasource responses
   */
  async makeDataRequest<T>(requestData: any): Promise<T> {
    const { data } = await this.axiosInstance.get<T>(this.remoteUrl, {
      method: 'GET',
      params: requestData,
    });

    return data;
  }

  /**
   * TBD
   */
  transformDataGridFilterModel(value: IServerSideGetRowsRequest): unknown {
    const { filterModel } = value;
    return filterModel;
  }

  /**
   * TBD
   */
  transformDataGridSortingModel(value: IServerSideGetRowsRequest): unknown {
    const { sortModel } = value;
    return sortModel;
  }

  /**
   * TBD
   */
  transformDataGridPaginationModel(value: IServerSideGetRowsRequest): unknown {
    const { startRow, endRow } = value;

    return {
      startRow: startRow,
      endRow: endRow,
    };
  }
}
