import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Button } from '../../../../../../packages/assets/components/button';
import { IconButton } from '../../../../../../packages/assets/components/icon-button';
import { Text } from '../../../../../../packages/assets/components/text';
import {
  ChevronLeftIcon,
  TrashIcon,
} from '../../../../../../packages/assets/icons';
import { makeStyles } from '../../../../../../packages/assets/theme';
import { ScheduleDrawerNavigationProp } from '../../../layout/ScheduleDrawer';
import { ServiceForm } from '../../../schedule/service-form/ServiceForm';
import {
  deleteAppointmentType,
  submitForm,
} from '../../../schedule/service-form/service-form-actions';
import PharmacyConfirmationModal from '../../../components/PharmacyConfirmationModal';
import { useServiceFormState } from '../../../schedule/service-form/service-form-store';

export const Service: FunctionComponent<ServiceProps> = (props) => {
  const styles = useStyles();
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();
  const route = useRoute<any>();
  const serviceId = route.params?.serviceId;
  const edit = !!serviceId;
  const [showModal, setShowModal] = useState(false);
  const { status } = useServiceFormState();

  const handleDeleteClick = async () => {
    setShowModal(false);
    await deleteAppointmentType(serviceId);
    navigation.navigate('services');
  };

  return (
    <View style={styles.container}>
      <PharmacyConfirmationModal
        show={showModal}
        onAccepted={() => handleDeleteClick()}
        onDismiss={() => setShowModal(false)}
        message={`Are you sure you want to delete the service?`}
      />
      <View style={styles.header}>
        <IconButton
          size={25}
          logger={{ id: 'go-back-service-form-button' }}
          icon={ChevronLeftIcon}
          onPress={() => navigation.navigate('services')}
          style={styles.backButton}
        />
        <Text style={styles.title} selectable>
          Services
        </Text>
        {edit && (
          <Button
            hierarchy="tertiary-gray"
            style={{
              borderColor: 'transparent',
            }}
            mode="outlined"
            size="small"
            logger={{ id: 'delete-service-button' }}
            icon={TrashIcon}
            loading={status === 'loading'}
            disabled={status === 'loading'}
            onPress={() => setShowModal(true)}
          >
            Delete
          </Button>
        )}
        <Button
          hierarchy="pharmacy-primary"
          size="small"
          logger={{ id: 'save-service-button' }}
          onPress={() => submitForm()}
          loading={status === 'loading'}
          disabled={status === 'loading'}
        >
          Save
        </Button>
      </View>
      <View>
        <ServiceForm />
      </View>
    </View>
  );
};

export interface ServiceProps {}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    margin: theme.getSpacing(4),
    paddingBottom: theme.getSpacing(4),
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.getSpacing(4),
    gap: theme.getSpacing(2),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[300],
    paddingBottom: theme.getSpacing(3),
  },
  title: {
    fontSize: 25,
    flexGrow: 1,
  },
  backButton: {
    margin: 0,
    marginLeft: -10,
  },
}));
