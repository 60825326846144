import { View, Text, StyleSheet } from 'react-native';
import { Form } from 'assets/layout';

interface InfoGroupProps {
  title: string;
  children?: JSX.Element | JSX.Element[];
}

function InfoGroup({ title, children }: InfoGroupProps) {
  return (
    <View style={styles.group}>
      <Form.Row>
        <Form.Column style={styles.columnLine}>
          <Text style={styles.title}>{title}</Text>
        </Form.Column>
      </Form.Row>
      <View>{children}</View>
    </View>
  );
}

const styles = StyleSheet.create({
  title: {
    alignSelf: 'flex-start',
    weight: '700',
    size: '16',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: 24,
    paddingBottom: 8,
  },
  group: {
    marginTop: 20,
  },
  columnLine: {
    borderBottomWidth: 1,
    borderBottomColor: '#E1EAEF',
  },
});

export default InfoGroup;
