import { AxiosRequestConfig } from 'axios';
import { ROLE_API_BASE_URL } from '../common/constants';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import {
  DefaultApi as RoleServiceClient,
  DetailedUserDto,
  UserRoleDto,
  LocationRole,
} from '@digitalpharmacist/role-service-client-axios';
import PharmacyService from './PharmacyService';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';

export interface IRoleService {}

export class RoleService extends BaseApiService implements IRoleService {
  private roleServiceClient: RoleServiceClient;
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.roleServiceClient = new RoleServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async userRoleGetUsersDetailsByPharmacyId(
    pharmacyId: string,
  ): Promise<DetailedUserDto[]> {
    const { data } =
      await this.roleServiceClient.userRoleGetUsersDetailsByPharmacyId(
        pharmacyId,
      );
    const users = data;
    return users;
  }

  async userRoleGetRolesByUserId(userId: string): Promise<UserRoleDto[]> {
    const { data } =
      await this.roleServiceClient.userRoleGetRolesByUserId(userId);
    const users = data;
    return users;
  }

  async userLocationRoleCreate(
    role: LocationRole,
    userId: string,
    locationId: string,
    pharmacyId: string,
  ): Promise<UserRoleDto> {
    const location: PharmacyLocationDto =
      await PharmacyService.pharmacyLocationFindOne(locationId, pharmacyId);

    const { data } =
      await this.roleServiceClient.userRoleCreateLocationUserRole(location.id, {
        role: role,
        user_id: userId,
      });

    const user = data;
    return user;
  }
}

export default new RoleService(
  ROLE_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
