import { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IPatientForm } from '../PatientFormModal/types';
import {
  updateLocationPatientRecord,
  updatePatientRecord,
} from './editPatientActions';
import usePatientStore from '../../screens/patients/patient-store';
import {
  LocationPatientRecordDtoWithFullName,
  PatientRecords,
} from '../../screens/patients/patient-types';
import {
  Gender,
  LocationPatientRecordDto,
  UpdateLocationPatientRecordDto,
  UpdatePatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import PatientFormModal from '../PatientFormModal/PatientFormModal';
import { getText } from 'assets/localization/localization';
import states from '../PatientFormModal/states';
import { PREFERRED_LANGUAGE_VALUES } from '../PatientFormModal/languages';
import { formatPhoneNumber } from '../../../../../packages/assets/utils/phoneNumber';
import { formatDate } from '../../common/datetime-utils';
export interface EditPatientModalProps {
  locationPatientRecord: LocationPatientRecordDtoWithFullName;
  show: boolean;
  patientNumberInList: number;
  patientDetails: PatientRecords;
  setShowEditModal: (arg: boolean) => void;
}

const EditPatientModal: FunctionComponent<EditPatientModalProps> = ({
  locationPatientRecord,
  show,
  setShowEditModal,
  patientNumberInList,
  patientDetails,
}) => {
  const {
    updatePatientByIndex,
    sidebarDetails,
    setSidebarDetails,
    setPatientRecord,
  } = usePatientStore();
  const methods = useForm<IPatientForm>({ mode: 'onChange' });

  useEffect(() => {
    const country: 'CA' | 'US' =
      patientDetails.address?.country == 'CA' ? 'CA' : 'US';

    const state = states[country].find(
      (item) => item.value == patientDetails.address?.state,
    );

    /* eslint-disable */
    const preferred_language = PREFERRED_LANGUAGE_VALUES.find(
      (item) => item.value == patientDetails?.preferred_language,
    ) ?? { value: '', label: '' };

    methods.reset({
      first_name: patientDetails.first_name,
      last_name: patientDetails.last_name,
      street1: patientDetails.address?.address1,
      street2: patientDetails.address?.address2,
      state,
      email: patientDetails.email,
      gender: patientDetails.gender,
      postal_code: patientDetails.address?.postal_code,
      city: patientDetails.address?.city,
      country,
      date_of_birth: formatDate(patientDetails.date_of_birth),
      phone: formatPhoneNumber(patientDetails.phone),

      preferred_language,
      no_allergies: patientDetails?.allergies?.length > 0 ? false : true,
      allergies:
        patientDetails?.allergies?.length > 0
          ? patientDetails?.allergies.join(',')
          : undefined,
      conditions:
        patientDetails?.medical_conditions?.length > 0
          ? patientDetails.medical_conditions.join(',')
          : undefined,
      prefers_med_sync: patientDetails?.prefers_med_sync ?? undefined,
      prefers_easy_open_bottle_caps:
        patientDetails?.prefers_easy_open_bottle_caps ?? undefined,
      notify_by_sms: patientDetails?.notify_by_sms ?? undefined,
      notify_by_voice: patientDetails?.notify_by_voice ?? undefined,
      notify_by_email: patientDetails?.notify_by_email ?? undefined,
      photo_id_url: patientDetails?.photo_id_url ?? undefined,
    });
  }, [patientDetails]);
  /* eslint-enable */

  async function onSubmit(data: IPatientForm) {
    const isValid = validateEditPatientFrom(data);
    if (!isValid) {
      return;
    }

    const locationPatientRecordToUpdate = populateLocationPatientRecord(data);

    const updatedLocationPatientRecord = await updateLocationPatientRecord(
      locationPatientRecord.location_id,
      locationPatientRecord.id,
      locationPatientRecordToUpdate,
    );

    if (updatedLocationPatientRecord) {
      const patientRecordToUpdate = populatePatientRecord({
        locationPatientRecordToUpdate,
        data,
      });

      if (updatedLocationPatientRecord.patient_record_id) {
        updatePatientRecordAndState(
          updatedLocationPatientRecord.patient_record_id,
          patientRecordToUpdate,
        );
      }

      updateLocationPatientInState(updatedLocationPatientRecord);
    }

    setShowEditModal(false);
  }

  function populateLocationPatientRecord(
    data: IPatientForm,
  ): UpdateLocationPatientRecordDto {
    return {
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      date_of_birth: formatDate(data.date_of_birth, 'YYYY-MM-DD'),
      gender:
        Gender.Male == data.gender
          ? Gender.Male
          : Gender.Female == Gender.Female
          ? Gender.Female
          : undefined,
      email: data.email,
      address: data.street1
        ? {
            address1: data.street1,
            address2: data.street2,
            city: data.city,
            state: data.state ? data.state.value : undefined,
            postal_code: data.postal_code,
            country: data.country,
          }
        : undefined,
    };
  }

  interface PopulatePatientArgs {
    locationPatientRecordToUpdate: UpdateLocationPatientRecordDto;
    data: IPatientForm;
  }

  function populatePatientRecord(
    args: PopulatePatientArgs,
  ): UpdatePatientRecordDto {
    const { data, locationPatientRecordToUpdate } = args;
    return {
      ...locationPatientRecordToUpdate,
      preferred_language: data.preferred_language
        ? data.preferred_language.value
        : undefined,
      allergies: data.allergies?.length ? data.allergies.split(',') : [],
      medical_conditions: data.conditions ? data.conditions.split(',') : [],
      prefers_med_sync: data.prefers_med_sync,
      prefers_easy_open_bottle_caps: data.prefers_easy_open_bottle_caps,
      notify_by_sms: data.notify_by_sms,
      notify_by_voice: data.notify_by_voice,
      notify_by_email: data.notify_by_email,
    };
  }

  type FormDataKey = keyof IPatientForm;

  function validateEditPatientFrom(data: IPatientForm) {
    const patient: PatientRecords = {
      ...patientDetails,
    };

    const formKeys = Object.keys(data);
    for (const key of formKeys) {
      const formDataKey = key as FormDataKey;

      const isErased =
        data[formDataKey] != patient[key as keyof PatientRecords] &&
        data[formDataKey] == '';

      const hasAllergies = !data.no_allergies;

      if (typeof data[formDataKey] == 'string' && isErased) {
        if (key == 'allergies' && hasAllergies) {
          continue;
        }

        const isSure = confirm(`Are you sure you want to erase ${key}?`);

        return isSure;
      }
    }

    return true;
  }

  async function updatePatientRecordAndState(
    id: string,
    data: UpdatePatientRecordDto,
  ) {
    const updatedPatientRecord = await updatePatientRecord(id, data);

    if (updatedPatientRecord) {
      setPatientRecord(updatedPatientRecord);
    }
  }

  async function updateLocationPatientInState(
    updatedLocationPatientRecord: LocationPatientRecordDto,
  ) {
    const newLocationPatientRecord: LocationPatientRecordDtoWithFullName = {
      ...updatedLocationPatientRecord,
      full_name: `${updatedLocationPatientRecord.first_name} ${updatedLocationPatientRecord.last_name}`,
    };

    updatePatientByIndex(patientNumberInList, newLocationPatientRecord);
    setSidebarDetails({
      ...sidebarDetails,
      locationPatientRecord: newLocationPatientRecord,
    });
  }

  return (
    <PatientFormModal
      title={getText('edit-patient')}
      methods={methods}
      show={show}
      setShowPatientFormModal={setShowEditModal}
      onSubmit={onSubmit}
      disableShowMoreButton
    />
  );
};

export default EditPatientModal;
