import { Platform } from 'react-native';
import * as Sentry from 'sentry-expo';

export const logError = (error: Error) => {
  if (Platform.OS === 'web') {
    Sentry.Browser.captureException(error);
  } else {
    Sentry.Native.captureException(error);
  }
  console.error(error);
};

export const logInfo = (info: string) => {
  if (Platform.OS === 'web') {
    Sentry.Browser.captureMessage(info);
  } else {
    Sentry.Native.captureMessage(info);
  }
  console.info(info);
};

export const logEvent = (eventName: string, data?: {}) => {
  // Do we need this function anymore since we're using Amplitude?
  // Should we use this function to log events to Amplitude?
  // Right now buttons are all using this, so we could send button clicks somewhere
};

export const setCurrentScreen = async (screen: string | undefined) => {
  if (!screen) {
    return;
  }
  // Where should these be sent to? Amplitude?
  // logEvent("screen_view", { screen_name: screen }); // commenting out because console throws errors since this only works on a built app
};
