import { IMessage, User } from 'react-native-gifted-chat';
import {
  AuthorType,
  ConversationDto,
  DirectMessagePatientDto,
  MessageTemplateDto,
  DirectMessageDto,
  ClientAttachmentDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { IFile } from '../../api/types';

export enum BaseFilterValues {
  ALL = 1,
  UNREAD = 2,
  UNREAD_BY_USER = 3,
  TEMPLATES = 4,
}

export enum MultiFilterValues {
  NO_FILTERS = 0,
  INCLUDES_ME = 1,
  CREATED_BY_ME = 2,
  TODAY = 3,
  LAST_SEVEN_DAYS = 4,
}

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

interface IConversationsSorting {
  label: string;
  value: string;
  order: Order;
  isDate: boolean;
  field: string;
}

export interface InboxState {
  patients: DirectMessagePatientDto[];
  selectedPatient: ISelectedPatient | null;
  selectedUserNames: Record<string, string>;
  selectedConversation: string | null;
  viewedConversations: string[];
  conversationsSorting: IConversationsSorting;
  messagesGroups: ConversationDto[];
  selectedMessages: IMessage[];
  rawConversations: DirectMessagePatientDto[];
  isOpenNewChatModal: boolean;
  patientFilter: string;
  templates: MessageTemplateDto[];
  messagesPagination: MessagesPagination;
  showAddPatientModal: boolean;

  baseFilter: BaseFilterValues;
  multiFilters: MultiFilterValues[];
  disabled: boolean;
  count: {
    unread?: number;
  };
}

export interface EmittedMessage {
  id: string;
  location_patient_id: string;
  conversation_id: string;
  author_id: string;
  author_type: AuthorType;
  content: string;
  created_at: Date;
  updated_at: Date;
}

export interface TypedMessage {
  text: string;
  user: User;
  attachments: IFile[];
}

export interface EmittedConversation extends ConversationDto {
  content: string;
  content_created_at: string;
  updated_at: string;
  created_at: string;
}

export interface IMessageExtended extends IMessage {
  attachments: IComposedAttachments;
}

interface IDBAttachment extends ClientAttachmentDto {
  id: string;
  updated_at: string;
  created_at: string;
}

export interface DirectMessageExtended extends DirectMessageDto {
  attachments: IDBAttachment[];
}

export interface IComposedAttachments {
  files: ClientAttachmentDto[];
  images: ClientAttachmentDto[];
}

export interface IUploadFilesResult {
  isError: boolean;
  filesData: ClientAttachmentDto[];
}

export interface Attachment {
  id: string;
  name: string;
  stored_filename: string;
  created_at: string;
  updated_at: string;
}

export type File = Attachment;

export interface Image extends Attachment {
  url: string;
}

export interface MessagesPagination {
  limit: number;
  count: number;
}

export interface GetMessagesResult {
  messages: IMessageExtended[];
  count: number;
}

export interface ISelectedPatient extends IRecipient {
  date_of_birth?: string;
  phone?: string;
  is_verified: boolean;
}

export interface IRecipient {
  location_patient_id: string;
  first_name: string;
  last_name: string;
}

export interface EmittedUpdatedUserStatus {
  patient_viewed_all_messages: boolean;
  pharmacy_viewed_all_messages: boolean;
  conversation_id: string;
}
