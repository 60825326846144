import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { setSearchTerm } from './tasks-filters-actions';

function useDebounceSearchTerm(
  delay?: number,
): [
  value: string | undefined,
  setValue: Dispatch<SetStateAction<string | undefined>>,
] {
  const [searchValue, setSearchValue] = useState<string | undefined>();

  useEffect(() => {
    if (searchValue !== undefined) {
      const timer = setTimeout(() => {
        setSearchTerm(searchValue);
      }, delay || 500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [searchValue, delay]);

  return [searchValue, setSearchValue];
}

export default useDebounceSearchTerm;
