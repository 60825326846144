import moment from 'moment';

export const getDateTimeFilename = (name: string, extension: string) => {
  const dateTime = moment().format('MMMDDYYYY_HHmm');
  return `${name.replaceAll(' ', '_')}_${dateTime.toLowerCase()}.${extension}`;
};

export const downloadFile = (data: Blob, name: string) => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(data);
  link.target = '_blank';
  link.download = name;
  link.href = url;
  link.click();
  URL.revokeObjectURL(url);
};
