// This is an example guard only to demo the route guards.
// Should be removed when a real guard is implemented.
export const ExampleGuard: Promise<boolean> = new Promise((resolve, reject) => {
  // Condition for the guard;
  const hasAccess = true;

  if (hasAccess) {
    // Guard should return true if the condition is met...
    resolve(true);
  } else {
    // ...and return false when it's not.
    resolve(false);
  }
});
