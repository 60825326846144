import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import { Menu, Item, useContextMenu } from 'react-contexify';
import { useTasksDataTableState } from './tasks-data-table-store';
import { MenuOptionItem } from './TaskContextMenu';
import {
  CheckCircleIcon,
  MinusCircleIcon,
  PauseCircleIcon,
  PlayCircleIcon,
} from 'assets/icons';
import { makeStyles, useTheme } from 'assets/theme';
import { StyleSheet } from 'react-native';
import { TaskStatus } from '@digitalpharmacist/tasks-service-client-axios';
import PharmacyConfirmationModal from '../../components/PharmacyConfirmationModal';
import { updateTask } from './tasks-data-table-actions';

export const TaskStatusContextMenu: FunctionComponent<
  PropsWithChildren<TaskStatusContextMenuProps>
> = ({ menuId }) => {
  const theme = useTheme();
  const styles = useStyles();
  const [showResolveConfirmation, setShowResolveConfirmation] = useState(false);
  const { hideAll } = useContextMenu();
  const { contextMenuTaskDetails } = useTasksDataTableState();

  return (
    <>
      {contextMenuTaskDetails && (
        <PharmacyConfirmationModal
          show={showResolveConfirmation}
          onAccepted={() => {
            setShowResolveConfirmation(false);
            hideAll();
            updateTask(contextMenuTaskDetails.id, {
              status: TaskStatus.Resolved,
            });
          }}
          onDismiss={() => {
            setShowResolveConfirmation(false);
          }}
          message={`Are you sure you want to resolve the task: "${contextMenuTaskDetails.summary}"?`}
        />
      )}

      <Menu id={menuId}>
        {contextMenuTaskDetails && (
          <>
            <Item
              onClick={() => {
                updateTask(contextMenuTaskDetails.id, {
                  status: TaskStatus.Unresolved,
                });
              }}
            >
              <MenuOptionItem
                icon={MinusCircleIcon}
                iconSize={14}
                color={theme.palette.gray[500]}
                iconColorSelected={theme.palette.gray[500]}
                label="Not started"
                containerStyle={styles.itemStyle}
                selected={
                  contextMenuTaskDetails?.status === TaskStatus.Unresolved
                }
                selectedIconSize={14}
              />
            </Item>
            <Item
              onClick={() => {
                updateTask(contextMenuTaskDetails.id, {
                  status: TaskStatus.OnHold,
                });
              }}
            >
              <MenuOptionItem
                icon={PauseCircleIcon}
                iconSize={14}
                color={theme.palette.primary[400]}
                iconColorSelected={theme.palette.primary[400]}
                label="On hold"
                containerStyle={styles.itemStyle}
                selected={contextMenuTaskDetails?.status === TaskStatus.OnHold}
                selectedIconSize={14}
              />
            </Item>
            <Item
              onClick={() => {
                updateTask(contextMenuTaskDetails.id, {
                  status: TaskStatus.InProgress,
                });
              }}
            >
              <MenuOptionItem
                icon={PlayCircleIcon}
                iconSize={14}
                color={theme.palette.warning[400]}
                iconColorSelected={theme.palette.warning[400]}
                label="In progress"
                containerStyle={styles.itemStyle}
                selected={
                  contextMenuTaskDetails?.status === TaskStatus.InProgress
                }
                selectedIconSize={14}
              />
            </Item>
            <Item onClick={() => setShowResolveConfirmation(true)}>
              <MenuOptionItem
                icon={CheckCircleIcon}
                iconSize={14}
                color={theme.palette.success[500]}
                iconColorSelected={theme.palette.success[500]}
                label="Resolved"
                containerStyle={styles.itemStyle}
                selected={
                  contextMenuTaskDetails?.status === TaskStatus.Resolved
                }
                selectedIconSize={14}
              />
            </Item>
          </>
        )}
      </Menu>
    </>
  );
};

export interface TaskStatusContextMenuProps {
  menuId: string;
}

const useStyles = makeStyles((theme) => ({
  itemStyle: {
    paddingHorizontal: 0,
  },
}));
