import { BookingStatus } from '@digitalpharmacist/appointment-service-client-axios';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Pressable, TouchableOpacity, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useProSidebar } from 'react-pro-sidebar';
import { Icon } from '../../../../../packages/assets/components/icon';
import { IconButton } from '../../../../../packages/assets/components/icon-button';
import { Text } from '../../../../../packages/assets/components/text';
import {
  DocumentIcon,
  EditIcon,
  FileOffIcon,
  MessageSquareIcon,
  UserXIcon,
} from '../../../../../packages/assets/icons';
import { useFocusEffect } from '@react-navigation/native';
import { getText } from '../../../../../packages/assets/localization/localization';
import { makeStyles, useTheme } from '../../../../../packages/assets/theme';
import {
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_UTC_OFFSET,
  formatDate,
  formatDateTimeWithTimezone,
} from '../../common/datetime-utils';
import { ColoredBadge } from '../../components/ColoredBadge';
import PharmacyConfirmationModal from '../../components/PharmacyConfirmationModal';
import { PharmacySidebar } from '../../components/PharmacySidebar';
import { cancelBooking } from './appointments-list-actions';
import { useAppointmentsListState } from './appointments-list-store';
import { ScheduleDrawerNavigationProp } from '../../layout/ScheduleDrawer';
import moment from 'moment';
import { CollapsibleText } from '../../components/CollapsibleText';
import SubmissionModal from './SubmissionModal';
import {
  calculateFormIconTooltip,
  minutesDifference,
} from './appointments-list.utils';
import { Tooltip } from '../../components/Tooltip';
import { TooltipWrapper } from 'react-tooltip';

const SectionHeader = ({
  label,
  internalUse,
  tooltipId,
  onPress,
}: {
  label: string;
  internalUse?: boolean;
  tooltipId?: string;
  onPress?: () => void;
}) => {
  const styles = useStyles();

  return (
    <View style={[styles.row, styles.sectionHeader]}>
      <Text style={styles.sectionHeaderLabelText}>{label.toUpperCase()}</Text>
      {internalUse && (
        <Text style={styles.sectionHeaderInternalText}>
          {getText('internal-use-only')}
        </Text>
      )}
      {onPress !== undefined && (
        <TouchableOpacity
          style={[styles.sectionHeaderButton]}
          onPress={onPress}
        >
          {tooltipId ? (
            <TooltipWrapper tooltipId={tooltipId}>
              <Text style={styles.sectionHeaderButtonText}>
                {getText('edit')}
              </Text>
            </TooltipWrapper>
          ) : (
            <Text style={styles.sectionHeaderButtonText}>
              {getText('edit')}
            </Text>
          )}
        </TouchableOpacity>
      )}
    </View>
  );
};

const PropertyRow = ({
  label,
  value,
  textColor,
  collapsible,
  onPress,
}: {
  label: string;
  value: string;
  textColor?: string;
  collapsible?: boolean;
  onPress?: () => void;
}) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <View style={styles.propertyRow}>
      <Text style={styles.propertyRowLabel}>{label}</Text>
      {onPress !== undefined ? (
        <TouchableOpacity onPress={onPress}>
          <Text style={styles.propertyRowValuePressable}>{value}</Text>
        </TouchableOpacity>
      ) : (
        <>
          {collapsible ? (
            <CollapsibleText
              text={value}
              color={textColor ? textColor : theme.palette.gray[700]}
            />
          ) : (
            <Text
              style={[styles.propertyRowValue, { color: textColor }]}
              selectable
            >
              {value}
            </Text>
          )}
        </>
      )}
    </View>
  );
};

const InfoRow = ({ label, value }: { label: string; value: any }) => {
  const styles = useStyles();
  return (
    <View style={styles.infoRow}>
      <Text style={styles.infoRowLabel}>{label}</Text>
      <Text style={styles.infoRowValue} selectable>
        {value}
      </Text>
    </View>
  );
};

const FormLink = ({
  text,
  status,
  onPress,
  formId,
  isSubmitted,
}: {
  text: string;
  status: string;
  formId: string;
  isSubmitted: boolean;
  onPress: () => void;
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const leftIcon = status === 'ENABLED' ? DocumentIcon : FileOffIcon;

  const { icon, iconColor, tooltip } = calculateFormIconTooltip(
    status,
    isSubmitted,
  );

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.alignBaseline}>
        <View style={styles.formLink} nativeID={formId}>
          <Icon
            icon={leftIcon}
            color={
              isSubmitted ? theme.palette.primary[600] : theme.palette.gray[500]
            }
            size={16}
          />
          <Text
            style={[
              styles.formLinkText,
              !isSubmitted && styles.unavailableForm,
            ]}
          >
            {text}
          </Text>
          {icon !== undefined && (
            <Icon icon={icon} color={iconColor} size={14} />
          )}
        </View>
      </View>
      <Tooltip place="top" text={tooltip} anchorId={formId} />
    </TouchableOpacity>
  );
};

export const AppointmentDetailsSidebar: FunctionComponent<
  AppointmentDetailsSidebarProps
> = ({ onCancel, onCollapse }) => {
  const { appointmentDetails, appointmentType, patientRecord } =
    useAppointmentsListState();
  const theme = useTheme();
  const styles = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [modalParams, setModalParams] = useState<{
    submissionId: string;
    formId: string;
  }>();

  const [show, setShow] = useState(false);
  const { collapseSidebar } = useProSidebar();
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();

  const statusLabels = new Map<BookingStatus, string>([
    [BookingStatus.Accepted, getText('not-started')],
    [BookingStatus.Cancelled, getText('canceled')],
    [BookingStatus.Pending, getText('pending')],
    [BookingStatus.Rejected, getText('rejected')],
  ]);

  const statusColors = new Map<BookingStatus, string>([
    [BookingStatus.Accepted, theme.palette.success[500]],
    [BookingStatus.Cancelled, theme.palette.warning[500]],
    [BookingStatus.Cancelled, theme.palette.primary[500]],
    [BookingStatus.Rejected, theme.palette.error[500]],
  ]);

  const getAge = (dateOfBirth: string) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);

    return today.getFullYear() - birthDate.getFullYear();
  };

  const formatPhone = (phoneNumber: string) => {
    const cleanedNumber = phoneNumber.slice(2);
    const match = cleanedNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  };

  const handleCancelBooking = async () => {
    if (appointmentDetails) {
      setShowModal(false);
      collapseSidebar(true);
      await cancelBooking(appointmentDetails.id);
      onCancel(appointmentDetails.id);
    }
  };

  const getBadge = (date: string) => {
    const dateDiff = moment().diff(moment(date), 'days');

    if (dateDiff === 0) {
      return (
        <ColoredBadge
          label={getText('today')}
          color={theme.palette.gray[900]}
          backgroundColor={theme.palette.warning[300]}
          textStyle={styles.badgeText}
        />
      );
    }

    if (dateDiff === -1) {
      return (
        <ColoredBadge
          label={getText('tomorrow')}
          color={theme.palette.gray[900]}
          backgroundColor={theme.palette.gray[100]}
          textStyle={styles.badgeText}
        />
      );
    }
  };

  const handleOpenSubmissionModal = (formId: string) => {
    const submission = appointmentDetails?.submissions.find(
      (submission) => submission.form_id === formId,
    );

    if (!submission) return;

    setModalParams({
      submissionId: submission.submission_id,
      formId: submission.form_id,
    });
    setShow(true);
  };

  const handleCloseSubmissionModal = () => {
    setModalParams(undefined);
    setShow(false);
  };

  useFocusEffect(
    useCallback(() => {
      return () => {
        collapseSidebar(true);
      };
    }, []),
  );

  return (
    <>
      <PharmacyConfirmationModal
        show={showModal}
        message={getText('cancel-appointment-confirmation')}
        onAccepted={handleCancelBooking}
        onDismiss={() => setShowModal(false)}
      />
      <PharmacySidebar
        title={getText('appointment-panel')}
        dataExists={!!appointmentDetails && !!appointmentType}
        defaultCollapsed={true}
        rootStyle={{
          marginTop: -theme.getSpacing(4),
          height: `calc(100% + ${theme.getSpacing(4) * 2}px)`,
          marginLeft: theme.getSpacing(4),
          borderRight: 'none',
        }}
        onCollapse={onCollapse}
      >
        <>
          {appointmentDetails && (
            <View>
              <View style={styles.row}>
                {getBadge(appointmentDetails.startTime)}
                <View style={styles.buttonsRow}>
                  <TooltipWrapper tooltipId="appointment-details-cancel-tooltip">
                    <IconButton
                      onPress={() => setShowModal(true)}
                      icon={UserXIcon}
                      logger={{ id: 'cancel-appointment' }}
                      color={theme.palette.gray[500]}
                      size={17}
                    />
                  </TooltipWrapper>
                  <TooltipWrapper tooltipId="appointment-details-message-tooltip">
                    <IconButton
                      onPress={() => alert('Not implemented yet')}
                      icon={MessageSquareIcon}
                      logger={{ id: 'message-appointment' }}
                      color={theme.palette.gray[500]}
                      size={15}
                    />
                  </TooltipWrapper>
                  <Pressable
                    onPress={() =>
                      navigation.navigate('edit-appointment', {
                        appointmentId: appointmentDetails.id,
                      })
                    }
                  >
                    <TooltipWrapper tooltipId="appointment-details-edit-tooltip">
                      <View style={styles.row}>
                        <IconButton
                          onPress={() =>
                            navigation.navigate('edit-appointment', {
                              appointmentId: appointmentDetails.id,
                            })
                          }
                          icon={EditIcon}
                          logger={{ id: 'edit-appointment' }}
                          color={theme.palette.gray[500]}
                          size={15}
                        />
                        <Text style={styles.editText}>{getText('edit')}</Text>
                      </View>
                    </TooltipWrapper>
                  </Pressable>
                </View>
              </View>
              <View style={styles.titleContainer}>
                <Text style={styles.title}>{appointmentDetails.title}</Text>
              </View>
              <PropertyRow
                label={getText('time')}
                value={formatDateTimeWithTimezone(
                  appointmentDetails.startTime,
                  DEFAULT_UTC_OFFSET, // TODO: Change to pharmacy timezone when it's implemented
                  DEFAULT_DATE_TIME_FORMAT,
                )}
              />
              <PropertyRow
                label={getText('duration')}
                value={`${minutesDifference(
                  appointmentDetails.endTime,
                  appointmentDetails.startTime,
                )} ${getText('minutes')}`}
              />
              {/* TODO: Hard-coded for now, as it's not implemented yet on the b/e */}
              <PropertyRow
                label={getText('venue')}
                value={getText('venue-in-person')}
              />
              <PropertyRow
                label={getText('patient')}
                value={`${appointmentDetails.patient_record_first_name} ${
                  appointmentDetails.patient_record_last_name
                } (${getText('age')} ${getAge(
                  appointmentDetails.patient_record_date_of_birth,
                )})`}
                onPress={() => alert('Not implemented yet')}
              />
              <PropertyRow
                label={getText('status')}
                value={statusLabels.get(appointmentDetails.status) || ''}
                textColor={statusColors.get(appointmentDetails.status)}
              />
              {appointmentDetails.description && (
                <PropertyRow
                  label={getText('service-description')}
                  value={appointmentDetails.description}
                  textColor={theme.palette.gray[500]}
                  collapsible={true}
                />
              )}
              <SectionHeader label={getText('additional-info')} />
              <InfoRow
                label={getText('date-of-birth')}
                value={`${formatDate(
                  appointmentDetails.patient_record_date_of_birth,
                )}, ${getText('age')} ${getAge(
                  appointmentDetails.patient_record_date_of_birth,
                )}`}
              />
              {patientRecord?.phone && (
                <InfoRow
                  label={getText('phone')}
                  value={formatPhone(patientRecord.phone)}
                />
              )}
              {patientRecord?.email && (
                <InfoRow label={getText('email')} value={patientRecord.email} />
              )}
              <SectionHeader label={getText('forms')} />
              {appointmentType?.forms && appointmentType.forms.length > 0 ? (
                appointmentType.forms.map((form) => (
                  <FormLink
                    key={form.form_id}
                    status={form.form_status as string}
                    text={form.form_name}
                    onPress={() => handleOpenSubmissionModal(form.form_id)}
                    formId={form.form_id}
                    isSubmitted={
                      !!appointmentDetails.submissions.find(
                        (submission) => submission.form_id === form.form_id,
                      )
                    }
                  />
                ))
              ) : (
                <Text style={styles.infoRowValue}>
                  {getText('no-forms-required')}
                </Text>
              )}

              <SectionHeader
                label={getText('notes')}
                internalUse={true}
                tooltipId="appointment-details-edit-tooltip"
                onPress={() =>
                  navigation.navigate('edit-appointment', {
                    appointmentId: appointmentDetails.id,
                  })
                }
              />
              {appointmentDetails.pharmacy_notes && (
                <View style={styles.notesContainer}>
                  <Text style={styles.notesText}>
                    {appointmentDetails.pharmacy_notes}
                  </Text>
                </View>
              )}
              <Tooltip
                id="appointment-details-edit-tooltip"
                text={getText('edit-reschedule-this-appointment')}
              />
              <Tooltip
                id="appointment-details-cancel-tooltip"
                text={getText('cancel-this-appointment')}
              />
              <Tooltip
                id="appointment-details-message-tooltip"
                text={getText('message-this-patient')}
              />
            </View>
          )}
        </>
      </PharmacySidebar>

      {modalParams ? (
        <SubmissionModal
          handleClose={handleCloseSubmissionModal}
          show={show}
          submissionId={modalParams.submissionId}
          formId={modalParams.formId}
        />
      ) : null}
    </>
  );
};

export interface AppointmentDetailsSidebarProps {
  onCancel: (bookingId: string) => void;
  onCollapse?: () => void;
}

const useStyles = makeStyles((theme) => ({
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  badgeText: {
    fontSize: 12,
    paddingLeft: theme.getSpacing(1),
    paddingRight: theme.getSpacing(1),
  },
  buttonsRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  editText: {
    color: theme.palette.gray[500],
    fontSize: 14,
  },
  titleContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.getSpacing(3),
  },
  title: {
    fontSize: 24,
    color: theme.palette.gray[900],
    fontWeight: '600',
  },
  propertyRow: {
    marginTop: theme.getSpacing(2),
  },
  propertyRowLabel: {
    ...theme.fonts.medium,
    fontSize: 16,
    color: theme.palette.gray[900],
    marginBottom: theme.getSpacing(0.5),
  },
  propertyRowValue: {
    color: theme.palette.gray[700],
  },
  propertyRowValuePressable: {
    color: theme.palette.primary[600],
  },
  sectionHeader: {
    marginTop: theme.getSpacing(4),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[300],
    borderBottomStyle: 'solid',
    paddingBottom: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
    flexDirection: 'row',
  },
  sectionHeaderLabelText: {
    fontSize: 12,
    fontWeight: '600',
    color: theme.palette.gray[900],
  },
  sectionHeaderInternalText: {
    color: theme.palette.gray[500],
    fontSize: 13,
    fontWeight: '400',
    marginLeft: 'auto',
  },
  sectionHeaderButton: {
    marginLeft: theme.getSpacing(1),
  },
  sectionHeaderButtonText: {
    color: theme.palette.primary[600],
    paddingRight: theme.getSpacing(1),
    fontSize: 13,
    fontWeight: '400',
  },
  infoRow: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingBottom: theme.getSpacing(1),
  },
  infoRowValue: {
    fontWeight: '400',
    fontSize: 14,
    color: theme.palette.gray[700],
    paddingLeft: theme.getSpacing(1),
    wordBreak: 'break-all',
  },
  infoRowLabel: {
    ...theme.fonts.medium,
    fontSize: 14,
    color: theme.palette.gray[900],
    lineHeight: 20,
    width: 120,
    minWidth: 120,
  },
  formLink: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(1),
    alignItems: 'flex-end',
  },
  formLinkText: {
    color: theme.palette.primary[600],
    marginLeft: theme.getSpacing(0.5),
    marginRight: theme.getSpacing(2),
  },
  notesContainer: {
    padding: theme.getSpacing(1),
    backgroundColor: theme.palette.gray[50],
  },
  notesText: {
    color: theme.palette.gray[600],
  },
  alignBaseline: {
    alignItems: 'baseline',
  },
  unavailableForm: {
    color: theme.palette.gray[500],
  },
}));
