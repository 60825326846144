import { logError } from 'assets/logging/logger';
import { getText } from '../../../../../packages/assets/localization/localization';
import AppointmentService from '../../api/AppointmentService';
import PatientService from '../../api/PatientService';
import { useToast } from '../../common/hooks/useToast';
import { useAppStateStore } from '../../store/app-store';
import { useAppointmentsListState } from './appointments-list-store';

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : getText('error-cancel-appointment');

  logError(error);
  useAppointmentsListState.setState({
    error: {
      message: message,
    },
    status: 'error',
  });

  toast('Error', { type: 'error', content: message });
};

export const cancelBooking = async (bookingId: string) => {
  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    await AppointmentService.cancelBooking(pharmacyId, locationId, bookingId);
  } catch (error) {
    errorOccurred(error);
  }
};

export const showAppointmentDetails = async (bookingId: string) => {
  useAppointmentsListState.setState({
    appointmentDetailsStatus: 'loading',
    appointmentDetails: undefined,
    appointmentType: undefined,
    patientRecord: undefined,
  });

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    const bookingResponse = await AppointmentService.getBooking(
      pharmacyId,
      locationId,
      bookingId,
    );

    const appointmentTypeResponse = AppointmentService.findAppointmentTypeById(
      pharmacyId,
      locationId,
      bookingResponse.appointment_type_id,
    );

    const patientRecordResponse = PatientService.getPatientRecord(
      bookingResponse.patient_record_id,
    );

    useAppointmentsListState.setState({
      appointmentDetailsStatus: 'idle',
      appointmentDetails: bookingResponse,
      appointmentType: await appointmentTypeResponse,
      patientRecord: await patientRecordResponse,
    });
  } catch (error) {
    errorOccurred(error, getText('error-loading-appointment'));
  }

  return null;
};
