import create from 'zustand';
import { UserDetails, ViewSidebarState } from './user-types';

const useViewSidebarStore = create<ViewSidebarState>((set) => ({
  showSidebar: false,
  setShowSidebar: (showSidebar: boolean) =>
    set((state: ViewSidebarState) => ({
      ...state,
      showSidebar,
    })),
  showConfirmDeleteModal: false,
  setShowConfirmDeleteModal: (showConfirmDeleteModal: boolean) =>
    set((state: ViewSidebarState) => ({
      ...state,
      showConfirmDeleteModal,
    })),
  userDetails: undefined,
  setUserDetails: (userDetails: UserDetails) =>
    set((state: ViewSidebarState) => ({
      ...state,
      userDetails,
    })),
}));

export default useViewSidebarStore;
