import React, { FunctionComponent, PropsWithChildren } from 'react';
import { ActivityIndicator } from 'react-native-paper';
import { getText } from '../../localization/localization';
import { useTheme } from '../../theme';

export const LoadingIndicator: FunctionComponent<
  PropsWithChildren<LoadingIndicatorProps>
> = ({ size = 50, color }) => {
  const theme = useTheme();

  return (
    <ActivityIndicator
      accessibilityLabel={getText('loading')}
      size={size}
      color={color ?? theme.colors.brandedPrimary}
    />
  );
};

export interface LoadingIndicatorProps {
  color?: string;
  size?: number;
}
