/* tslint:disable */
/* eslint-disable */
/**
 * user
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PatientFeedback
 */
export interface PatientFeedback {
  /**
   * “app” or “store”
   * @type {string}
   * @memberof PatientFeedback
   */
  target: string;
  /**
   * User input from the text area
   * @type {string}
   * @memberof PatientFeedback
   */
  feedback: string;
  /**
   * If user allows followups
   * @type {boolean}
   * @memberof PatientFeedback
   */
  allowContact: boolean;
  /**
   * The id of the pharmacy
   * @type {string}
   * @memberof PatientFeedback
   */
  pharmacyId: string;
  /**
   * The id of the location
   * @type {string}
   * @memberof PatientFeedback
   */
  locationId?: string;
  /**
   * The patient id of the user submitting
   * @type {string}
   * @memberof PatientFeedback
   */
  patientId?: string;
  /**
   * The version of the user's operating system
   * @type {string}
   * @memberof PatientFeedback
   */
  osVersion: string;
  /**
   * The version of the app
   * @type {string}
   * @memberof PatientFeedback
   */
  appVersion: string;
  /**
   * The name of the app (pharmacy name if branded)
   * @type {string}
   * @memberof PatientFeedback
   */
  appName: string;
  /**
   * Device type of the user submitting
   * @type {string}
   * @memberof PatientFeedback
   */
  device: string;
}

export function PatientFeedbackFromJSON(json: any): PatientFeedback {
  return PatientFeedbackFromJSONTyped(json, false);
}

export function PatientFeedbackFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatientFeedback {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    target: json['target'],
    feedback: json['feedback'],
    allowContact: json['allowContact'],
    pharmacyId: json['pharmacyId'],
    locationId: !exists(json, 'locationId') ? undefined : json['locationId'],
    patientId: !exists(json, 'patientId') ? undefined : json['patientId'],
    osVersion: json['osVersion'],
    appVersion: json['appVersion'],
    appName: json['appName'],
    device: json['device'],
  };
}

export function PatientFeedbackToJSON(value?: PatientFeedback | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    target: value.target,
    feedback: value.feedback,
    allowContact: value.allowContact,
    pharmacyId: value.pharmacyId,
    locationId: value.locationId,
    patientId: value.patientId,
    osVersion: value.osVersion,
    appVersion: value.appVersion,
    appName: value.appName,
    device: value.device,
  };
}
