import create from 'zustand';
import { BookingDto } from '@digitalpharmacist/appointment-service-client-axios';

import { ApiError, AsyncStatus } from '../../store/types';

export const useAppointmentsCalendarState = create<AppointmentsCalendarState>(
  () => ({
    status: 'idle',
    error: undefined,
    appointments: undefined,
    dateRange: undefined,
    totalCount: undefined,
  }),
);

interface DateRange {
  startDate: string;
  endDate: string;
}

interface AppointmentsCalendarState {
  status: AsyncStatus;
  error?: ApiError;
  appointments?: BookingDto[];
  dateRange?: DateRange;
  totalCount?: number;
}
