// A function to evaluate all passed guards.
// It returns true if all passed guards pass or false if any of them fails.
// Parameters:
//   - guards - an array of route guards as promises returning booleans
export const applyGuards = async (
  guards: Promise<boolean>[],
): Promise<boolean> => {
  if (guards.length > 0) {
    try {
      return Promise.all(guards).then((results) => {
        return results.reduce((a, b) => a && b, true);
      });
    } catch (e) {
      return false;
    }
  }

  return true;
};
