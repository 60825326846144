import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  createStackNavigator,
  StackHeaderProps,
} from '@react-navigation/stack';
import {
  isLocationSelected,
  isUserAuthorized,
  useUserState,
} from '../store/user-store';
import { PharmacyHeader } from 'assets/components/pharmacy-header';
import { Dashboard } from '../screens/dashboard/Dashboard';
import { Login } from '../screens/login/Login';
import type { StackNavigationProp } from '@react-navigation/stack';
import { FormsDrawer } from '../layout/FormsDrawer';
import { ResetLink } from '../screens/reset-password/ResetLink';
import { ResetLinkSent } from '../screens/reset-password/ResetLinkSent';
import { ResetPassword } from '../screens/reset-password/ResetPassword';
import { CreatePassword } from '../screens/create-password/CreatePassword';
import { UpdatePassword } from '../screens/expiring-password/UpdatePassword';
import { getText } from 'assets/localization/localization';
import { TasksDrawer } from '../layout/TasksDrawer';
import { SettingsDrawer } from '../layout/SettingsDrawer';
import Patients from '../screens/patients/Patients';
import { ExpiringSoon } from '../screens/expiring-password/ExpiringSoon';
import RefillSubmissions from '../screens/refill-submissions/RefillSubmissions';
import { ScheduleDrawer } from '../layout/ScheduleDrawer';
import { MessagesDrawer } from '../layout/MessagesDrawer';
import { SelectLocation } from '../screens/select-location-login/SelectLocation';
import { useAppStateStore } from '../store/app-store';
import { VideosDrawer } from '../layout/VideosDrawer';
import { PharmacyStoreSelector } from '../components/select-store/PharmacyStoreSelector';

const Stack = createStackNavigator<RootNavigationParamList>();

export const RootNavigation: FunctionComponent<
  PropsWithChildren<RootNavigationProps>
> = ({}) => {
  const isAuthorized = useUserState((x) => isUserAuthorized(x.data));

  const isSelectedLocation = useUserState((x) => isLocationSelected(x.data));

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        header: RootNavigationHeader,
      }}
    >
      {isAuthorized && isSelectedLocation ? (
        <Stack.Group>
          <Stack.Screen
            name="dashboard"
            component={Dashboard}
            options={{ title: getText('dashboard') }}
          />
          <Stack.Screen
            name="messages"
            component={MessagesDrawer}
            options={{ title: getText('messages') }}
          />
          <Stack.Screen
            name="tasks"
            component={TasksDrawer}
            options={{ title: getText('tasks') }}
          />
          <Stack.Screen
            name="schedule"
            component={ScheduleDrawer}
            options={{ title: getText('schedule') }}
          />
          <Stack.Screen
            name="forms"
            component={FormsDrawer}
            options={{ title: getText('forms') }}
          />
          <Stack.Screen
            name="patients"
            component={Patients}
            options={{ title: getText('patients') }}
          />
          <Stack.Screen
            name="refill-submissions"
            component={RefillSubmissions}
            options={{ title: getText('refill-submissions') }}
          />
          <Stack.Screen
            name="videos"
            component={VideosDrawer}
            options={{ title: getText('videos') }}
          />
          <Stack.Screen
            name="settings"
            component={SettingsDrawer}
            options={{ title: getText('settings') }}
          />
        </Stack.Group>
      ) : isAuthorized && !isSelectedLocation ? (
        <Stack.Screen
          name="store-location"
          component={SelectLocation}
          options={{ title: getText('store-location') }}
        />
      ) : (
        <Stack.Group>
          <Stack.Screen
            name="login"
            component={Login}
            options={{ title: getText('login') }}
          />
          <Stack.Screen
            name="create-password"
            component={CreatePassword}
            options={{ title: getText('create-password') }}
          />
          <Stack.Screen
            name="reset-link"
            component={ResetLink}
            options={{ title: getText('reset-link') }}
          />
          <Stack.Screen
            name="reset-link-sent"
            component={ResetLinkSent}
            options={{ title: getText('reset-link-sent') }}
          />
          <Stack.Screen
            name="expiring-soon"
            component={ExpiringSoon}
            options={{ title: getText('expiring-soon') }}
          />
          <Stack.Screen
            name="update-password"
            component={UpdatePassword}
            options={{ title: getText('update-password') }}
          />
          <Stack.Screen
            name="reset-password"
            component={ResetPassword}
            options={{ title: getText('reset-password') }}
          />
        </Stack.Group>
      )}
    </Stack.Navigator>
  );
};

interface RootNavigationProps {}

export type RootNavigationParamList = {
  dashboard: undefined;
  messages: undefined;
  forms: undefined;
  schedule: undefined;
  tasks: undefined;
  patients: undefined;
  login: undefined;
  'store-location': undefined;
  'reset-link': undefined;
  'reset-link-sent': undefined;
  'reset-password': undefined;
  'create-password': undefined;
  'update-password': undefined;
  'expiring-soon': undefined;
  'refill-submissions': undefined;
  settings: undefined;
  videos: undefined;
};

export type RootStackNavigationProp =
  StackNavigationProp<RootNavigationParamList>;

const RootNavigationHeader: FunctionComponent<
  PropsWithChildren<StackHeaderProps>
> = (props) => {
  const { data } = useUserState();
  const fullName = `${data?.firstName} ${data?.lastName}`.trim();
  const isSelectedLocation = useUserState((x) => isLocationSelected(x.data));

  return (
    <PharmacyHeader
      isUserLoggedIn={!!isUserAuthorized(data) && !!isSelectedLocation}
      userName={fullName}
      navigation={props.navigation}
      screens={screens}
      pharmacyStoreSelector={<PharmacyStoreSelector />}
    />
  );
};

export const screens = [
  { name: 'dashboard', label: getText('dashboard') },
  { name: 'messages', label: getText('messages') },
  { name: 'tasks', label: getText('tasks') },
  { name: 'schedule', label: getText('schedule') },
  { name: 'forms', label: getText('forms') },
  { name: 'patients', label: getText('patients') },
];
