import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
} from 'react';
import { View } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';

import { Form } from 'assets/layout';
import { TextField } from 'assets/components/text-field';
import { useForm } from 'assets/form';
import { updateStepData } from '../forms-import-stepper-actions';
import { useFormsImportStepperState } from '../forms-import-stepper-store';

export const CredentialsSteps: FunctionComponent = () => {
  const styles = useStyles();
  const { steps } = useFormsImportStepperState();
  const defaultValues = steps.credentials;

  const methods = useForm({
    defaultValues: defaultValues,
    mode: 'onChange',
    delayError: 400,
  });

  useFocusEffect(
    useCallback(() => {
      const subscription = methods.watch((value) => {
        updateStepData('credentials', {
          ...value,
          invalid: !methods.formState?.isValid,
        });
      });

      return () => subscription.unsubscribe();
    }, [methods.watch]),
  );

  useFocusEffect(
    useCallback(() => {
      if (methods.formState?.isValid === defaultValues.invalid) {
        updateStepData('credentials', {
          ...defaultValues,
          invalid: !methods.formState?.isValid,
        });
      }
    }, [methods.formState?.isValid]),
  );

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Credentials</Text>

      <Text style={styles.contentText}>
        The API key you provide will give Lumistry access to your Jotform data
        including forms and submissions. Your data will be kept secure and will
        only be used for the purpose of importing the forms you specify into the
        Lumistry platform.
      </Text>

      <Form methods={methods}>
        <Form.Row>
          <Form.Column style={styles.inputWrapper}>
            <TextField
              name="apiKey"
              label="API Key *"
              testID="edit-form-element--subheader"
              rules={{
                required: 'API key is required',
              }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.inputWrapper}>
            <TextField
              name="customDomain"
              label="Custom Domain (Optional)"
              testID="edit-form-element--subheader"
              rules={{
                pattern: {
                  value:
                    /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/,
                  message: 'Must be a valid domain',
                },
              }}
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
  },
  title: {
    ...theme.fonts.medium,
    fontSize: 20,
    fontWeight: '600',
    paddingBottom: theme.getSpacing(2),
  },
  contentText: {
    ...theme.fonts.regular,
    color: theme.palette.gray[600],
    paddingBottom: theme.getSpacing(2),
  },
  inputWrapper: {
    flexDirection: 'column',
    flexGrow: 1,
    gap: 10,
  },
}));

export default CredentialsSteps;
