import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { applyGuards } from './guard-utils';

// RouteGuard component - used for guarding routes. It renders a component depending on access permissions.
// Parameters:
//   - guards - an array of route guards to evaluate
//   - component - a component that should be rendered when user has access to a route
//   - noAccessComponent - a component that should be render when user has no access to a route
export const RouteGuard: FunctionComponent<RouteGuardProps> = ({
  guards,
  component,
  noAccessComponent,
}) => {
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    applyGuards(guards)
      .then(setHasAccess)
      .catch(() => setHasAccess(false));
  }, []);

  return <>{hasAccess ? component : noAccessComponent}</>;
};

export interface RouteGuardProps {
  guards: Promise<boolean>[];
  component: ReactElement;
  noAccessComponent: ReactElement;
}
