import React, { FunctionComponent, PropsWithChildren } from 'react';
import { useTheme } from 'assets/theme';
import { StyleProp, ViewStyle, View } from 'react-native';

export const FormContent: FunctionComponent<
  PropsWithChildren<FormContentProps>
> = ({ children, style }) => {
  const theme = useTheme();
  return (
    <View style={[{ marginBottom: theme.getSpacing(2) }, style]}>
      {children}
    </View>
  );
};

export interface FormContentProps {
  style?: StyleProp<ViewStyle>;
}
