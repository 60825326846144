import { useNavigation } from '@react-navigation/native';
import { makeStyles } from 'assets/theme';
import { useCallback } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { VideoModel, VideosLocation } from '../../api/VideoService';
import {
  VideosDrawerNavigationParamList,
  VideosDrawerNavigationProp,
} from '../../layout/VideosDrawer';
import { useVideosState } from '../../store/videos-store';
import VideoBrowseTemplate from './VideoBrowseTemplate';

const VideosBrowseCollection: React.FC = () => {
  const styles = useStyles();

  const navigation = useNavigation<VideosDrawerNavigationProp>();

  const { videos, locale } = useVideosState();

  const viewVideo = useCallback(
    (id: string, locale: VideosLocation) => {
      navigation.navigate('detail', {
        video_id: id,
        collection_page: 'browse' as keyof VideosDrawerNavigationParamList,
        locale: locale,
      });
    },
    [navigation],
  );

  return (
    <View style={styles.collection}>
      {videos?.map((video: VideoModel) => (
        <TouchableOpacity onPress={() => viewVideo(video.id, locale)}>
          <VideoBrowseTemplate
            key={video.id}
            id={video.id}
            accountId={video.value.account_id}
            videoId={video.value.video_id}
            playerId={video.value.player_id}
            title={video.name}
            category={video.category}
          />
        </TouchableOpacity>
      ))}
    </View>
  );
};

export default VideosBrowseCollection;

const useStyles = makeStyles((theme) => ({
  collection: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: theme.getSpacing(1),
  },
}));
