import React, { FunctionComponent } from 'react';

import SearchFilter from '../../components/videos/SearchFilter';
import VideosListCollection from '../../components/videos/VideosListCollection';
import { useTheme } from 'assets/theme';
import { VideoListScreenRouteProp } from '../../layout/VideosDrawer';

export const VideosList: FunctionComponent<VideoListScreenRouteProp> = () => {
  const theme = useTheme();
  return (
    <>
      <SearchFilter />
      <VideosListCollection />
    </>
  );
};

export default VideosList;
