import { useEffect, useRef, useState } from 'react';
import {
  AuthorType,
  DirectMessagePatientDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import {
  setIncomeMessage,
  setNewConversation,
  setPatients,
  setRawConversations,
  setUpdatedUserStats,
  setViewedConversations,
} from '../inbox-store/inbox-actions';
import { Socket, io } from 'socket.io-client';
import { useUserState } from '../../../store/user-store';
import {
  EmittedConversation,
  EmittedMessage,
  EmittedUpdatedUserStatus,
} from '../types';
import { useInboxState } from '../inbox-store/inbox-store';
import { useAppStateStore } from '../../../store/app-store';
import usersService from '../../../api/UsersService';
import { INBOX_SOCKET_URL } from '../../../common/constants';
import { StorageKeys } from '../../../../enums/storage-keys';
import AsyncStorage from '@react-native-async-storage/async-storage';
import unifiedCommsService from '../../../api/UnifiedCommsService';
import { compare, groupConversationsByPatient } from '../utils';

export const useSockets = () => {
  const { locationId } = useAppStateStore();
  const [socket, setSocket] = useState<Socket>();
  const { data: user } = useUserState();
  const { selectedPatient, conversationsSorting } = useInboxState();

  const [typingMember, setTypingMember] = useState<UserTyping | null>();
  const typingTimer = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    const init = async () => {
      const token = await AsyncStorage.getItem(StorageKeys.AccessToken);
      const socketInit = io(INBOX_SOCKET_URL, {
        query: {
          client: AuthorType.Pharmacy,
        },
        auth: {
          token,
        },
        transports: ['websocket'],
      });
      setSocket(socketInit);
    };
    init();
  }, []);

  useEffect(() => {
    const onMessagesReceived = async (message: EmittedMessage) => {
      const author = await getAuthor(message.author_id, message.author_type);
      setIncomeMessage(message, author);
    };

    const onTypingReceived = (userTyping: UserTyping) => {
      if (userTyping.id === user?.id) {
        return;
      }

      clearTimeout(typingTimer.current);
      setTypingMember(userTyping);

      typingTimer.current = setTimeout(() => {
        setTypingMember(null);
      }, 1000);
    };

    const onNewConversation = async (conversation: EmittedConversation) => {
      const rawConversationsData: DirectMessagePatientDto[] =
        await unifiedCommsService.getAllPatientsWithConversationsByLocation(
          locationId,
        );

      const groupedConversations = groupConversationsByPatient(
        rawConversationsData,
      ).sort((currentConversation, nextConversation) =>
        compare(
          currentConversation,
          nextConversation,
          conversationsSorting.field,
          conversationsSorting.order,
          conversationsSorting.isDate,
        ),
      );
      const newViewedConversations = rawConversationsData
        .filter((conversation) => conversation.pharmacy_viewed_all_messages)
        .map((conversation) => conversation.conversation_id);

      setRawConversations(rawConversationsData);
      setPatients(groupedConversations);
      setViewedConversations(newViewedConversations);

      const author = await getAuthor(
        conversation.author_id,
        conversation.author_type,
      );
      setNewConversation(conversation, author);
    };

    const onUpdatedUserStatus = (
      updatedUserStatus: EmittedUpdatedUserStatus,
    ) => {
      setUpdatedUserStats(updatedUserStatus);
    };

    socket?.on('new_conversation', onNewConversation);
    socket?.on('typing', onTypingReceived);
    socket?.on('message', onMessagesReceived);
    socket?.on('updated_viewed_user_status', onUpdatedUserStatus);
    return () => {
      socket?.off('new_conversation', onNewConversation);
      socket?.off('message', onMessagesReceived);
      socket?.off('typing', onTypingReceived);
      socket?.on('updated_viewed_user_status', onUpdatedUserStatus);
    };
  }, [socket]);

  useEffect(() => {
    socket?.emit('join', {
      type: AuthorType.Pharmacy,
      id: locationId,
    });
    return () => {
      socket?.emit('leave', {
        type: AuthorType.Pharmacy,
        id: locationId,
      });
    };
  }, [socket, locationId]);

  const onType = (conversation_id: string) => {
    if (user && selectedPatient?.location_patient_id) {
      const userTyping: UserTyping = {
        id: user.id!,
        name: `${user.firstName} ${user.lastName}`,
        conversation_id: conversation_id,
        location_id: locationId,
        location_patient_id: selectedPatient.location_patient_id,
        author_type: AuthorType.Pharmacy,
      };
      socket?.emit('typing', userTyping);
    }
  };

  return {
    typingMember,
    onType,
  };
};

async function getAuthor(author_id: string, author_type: AuthorType) {
  let author;
  try {
    if (author_type == AuthorType.Pharmacy) {
      author = await usersService.getPharmacistUser(author_id);
    } else {
      author = await usersService.getUser(author_id);
    }
  } catch (err) {}
  return author;
}

export interface UserTyping {
  id: string;
  name: string;
  conversation_id: string;
  author_type: string;
  location_id: string;
  location_patient_id: string;
}
