import React from 'react';
import { Dimensions, Image, View } from 'react-native';
import { Modal, Portal } from 'react-native-paper';
import { FC, useEffect, useState } from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import { IconButton } from 'assets/components/icon-button';
import { DownloadIcon, XIcon } from 'assets/icons';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

interface ImageModalProps {
  imageModalState: IImageModalState;
  setImageModalState: (args: IImageModalState) => void;
  downloadAttachment: (storedFilename: string) => Promise<void>;
}

interface IImageModalState {
  show: boolean;
  uri: string;
  stored_filename: string;
}

const WIDTH_PERCENT = 0.7;
const HEIGHT_PERCENT = 0.7;

const ImageModal: FC<ImageModalProps> = ({
  imageModalState,
  setImageModalState,
  downloadAttachment,
}) => {
  const styles = useStyles();
  const theme = useTheme();

  const [dimensions, setDimensions] = useState<IDimensions>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (!imageModalState) {
      return;
    }

    Image.getSize(imageModalState.uri, (width: number, height: number) => {
      const windowHeight = Dimensions.get('window').height * HEIGHT_PERCENT;
      const windowWidth = Dimensions.get('window').width * WIDTH_PERCENT;

      const isPictureHeightBiggerThanWindowHeight = height > windowHeight;
      const isPictureWidthBiggerThanWindowWidth = width > windowWidth;

      const isHeightBigger = height > width;

      if (
        isPictureHeightBiggerThanWindowHeight &&
        isPictureWidthBiggerThanWindowWidth
      ) {
        if (isHeightBigger) {
          setDimensions({
            height: windowHeight,
            width: width * (windowHeight / height),
          });
        } else {
          setDimensions({
            width: windowWidth,
            height: windowHeight,
          });
        }
      } else if (isPictureHeightBiggerThanWindowHeight) {
        setDimensions({
          height: windowHeight,
          width: width * (windowHeight / height),
        });
      } else if (isHeightBigger) {
        setDimensions({
          width: width * (windowHeight / height),
          height,
        });
      } else {
        setDimensions({
          width,
          height: windowHeight,
        });
      }
    });
  }, [imageModalState]);

  if (!imageModalState) {
    return null;
  }

  return (
    <Portal>
      <Modal
        visible={imageModalState.show}
        contentContainerStyle={styles.imageContainer}
        onDismiss={() =>
          setImageModalState({ show: false, uri: '', stored_filename: '' })
        }
      >
        <View style={styles.modalHeader}>
          <IconButton
            logger={{ id: 'image-download-button' }}
            style={{
              height: theme.getSpacing(4) + theme.getSpacing(0.5),
              backgroundColor: theme.palette.white,
            }}
            onPress={() =>
              setImageModalState({
                show: false,
                uri: '',
                stored_filename: '',
              })
            }
            icon={XIcon}
          ></IconButton>
          <IconButton
            logger={{ id: 'image-download-button' }}
            style={{
              height: theme.getSpacing(4) + theme.getSpacing(0.5),
              backgroundColor: theme.palette.white,
            }}
            onPress={() =>
              void downloadAttachment(imageModalState.stored_filename)
            }
            icon={DownloadIcon}
          ></IconButton>
        </View>
        <TransformWrapper initialScale={1}>
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <React.Fragment>
              <TransformComponent contentStyle={styles.pictureContainer}>
                <img src={imageModalState.uri} style={styles.picture} />
              </TransformComponent>
              <div style={styles.tools}>
                <button onClick={() => zoomIn()}>+</button>
                <button onClick={() => zoomOut()}>-</button>
                <button onClick={() => resetTransform()}>x</button>
              </div>
            </React.Fragment>
          )}
        </TransformWrapper>
      </Modal>
    </Portal>
  );
};

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    backgroundColor: theme.palette.black,
    padding: theme.getSpacing(2),
    margin: theme.getSpacing(2),
    borderRadius: theme.roundness,
    justifyContent: 'center',
    alignSelf: 'center',
    height: Dimensions.get('window').height,
    width: Dimensions.get('window').width,
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  pictureContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: Dimensions.get('window').height,
    width: Dimensions.get('window').width,
  },
  picture: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: Dimensions.get('window').height,
    maxWidth: Dimensions.get('window').width,
  },
  tools: {},
}));

interface IDimensions {
  width: number;
  height: number;
}

export default ImageModal;
