import create from 'zustand';
import { SidebarDetails, ViewSidebarState } from './refill-submission-types';

// create state store
const useViewSidebarStore = create<ViewSidebarState>((set) => ({
  showSidebar: false,
  setShowSidebar: (showSidebar) =>
    set((state) => ({
      ...state,
      showSidebar: showSidebar ? true : false,
    })),
  sidebarDetails: undefined,
  setSidebarDetails: (sidebarDetails: SidebarDetails) =>
    set((state) => ({
      ...state,
      sidebarDetails: sidebarDetails,
    })),
}));

export default useViewSidebarStore;
