type AdvancedDropDownFieldOption = { label: string; value: string };

export const PREFERRED_LANGUAGE_VALUES: AdvancedDropDownFieldOption[] = [
  { label: 'English', value: 'english' },
  { label: 'Spanish', value: 'spanish' },
  { label: 'Chinese', value: 'chinese' },
  { label: 'French', value: 'French' },
  { label: 'Vietnamese', value: 'vietnamese' },
  { label: 'Arabic', value: 'arabic' },
  { label: 'Korean', value: 'korean' },
  { label: 'Russian', value: 'Russian' },
  { label: 'German', value: 'German' },
];
