import React, { FunctionComponent, PropsWithChildren } from 'react';

export const TabContent: FunctionComponent<
  PropsWithChildren<TabContentProps>
> = ({ isActive, children }) => {
  if (!isActive) {
    return null;
  }

  return <>{children}</>;
};

export interface TabContentProps {
  isActive: boolean;
  children?: React.ReactNode;
}
