import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const RepeatIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 20"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        d="M14.1667 0.833328L17.5 4.16666M17.5 4.16666L14.1667 7.49999M17.5 4.16666H5.83333C4.94928 4.16666 4.10143 4.51785 3.47631 5.14297C2.85119 5.76809 2.5 6.61594 2.5 7.49999V9.16666M5.83333 19.1667L2.5 15.8333M2.5 15.8333L5.83333 12.5M2.5 15.8333H14.1667C15.0507 15.8333 15.8986 15.4821 16.5237 14.857C17.1488 14.2319 17.5 13.384 17.5 12.5V10.8333"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
