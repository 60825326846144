import create from 'zustand';

export const useErrorState = create<ErrorState>(() => ({
  errorObject: {},
}));

export enum ErrorStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

interface ApiError {
  status: ErrorStatus;
  message: string;
}

export type ErrorObject = Record<string, ApiError>;

interface ErrorState {
  errorObject: ErrorObject;
}
