import { Language } from '@digitalpharmacist/unified-communications-service-client-axios';
import create from 'zustand';
import VideoService, { VideoModel, VideosLocation } from '../api/VideoService';

const alphabetChars = Array.from({ length: 26 }, (_, index) =>
  String.fromCharCode(65 + index),
);

export const languageVideoMap: Record<VideosLocation, Language> = {
  'en-US': 'en',
  'es-US': 'es',
};

export interface VideosState {
  search?: string | null | undefined;
  locale: VideosLocation;
  page: number;
  category: number;
  filter?: (typeof alphabetChars)[number] | null;
  totalCount: number;
  videos: VideoModel[];
  setSearch: (search: string | null | undefined) => void;
  setLocale: (locale: VideosLocation) => void;
  setPage: (page: number) => void;
  setCategory: (category: number) => void;
  setFilter: (filter: (typeof alphabetChars)[number] | null) => void;
  setTotalCount: (totalCount: number) => void;
  getVideos: () => void;
}

export const useVideosState = create<VideosState>((set, get) => ({
  locale: 'en-US',
  category: 0,
  page: 1,
  totalCount: 0,
  videos: [],
  setSearch: (search: string | null | undefined) => {
    const { getVideos } = get();
    set((state) => ({ ...state, search, page: 1 }));
    getVideos();
  },
  setLocale: (locale: VideosLocation) => {
    const { getVideos } = get();

    set({ locale });
    getVideos();
  },
  setPage: (page: number) => {
    const { getVideos } = get();
    set({ page });
    getVideos();
  },
  setCategory: (category: number) => {
    const { getVideos } = get();
    set((state) => ({ ...state, category, page: 1 }));
    getVideos();
  },
  setFilter: (filter: (typeof alphabetChars)[number] | null) => {
    const { getVideos } = get();
    set((state) => ({ ...state, filter, page: 1 }));
    getVideos();
  },
  setTotalCount: (totalCount: number) => set({ totalCount }),
  getVideos: async () => {
    const { category, locale, page, search, filter } = get();

    const response = await VideoService.getVideosList(
      search,
      locale,
      page,
      category,
      filter,
    );

    set({
      videos:
        response.items.map((x) => x[languageVideoMap[locale]] as VideoModel) ??
        [],
      totalCount: response.total_count ?? 0,
    });
  },
}));
