import React, {
  createContext,
  useContext,
  FunctionComponent,
  PropsWithChildren,
} from 'react';
import { BaseApiConfiguration } from '../types';

const RestServiceContext = createContext<BaseApiConfiguration | null>(null);

const notImplementedPromiseVoid = async () => Promise.resolve();
const notImplementedPromiseString = async () => Promise.resolve('');

export const RestServiceProvider: FunctionComponent<
  PropsWithChildren<BaseApiConfiguration>
> = ({ children, ...props }) => {
  const value: BaseApiConfiguration = {
    getAccessToken: props.getAccessToken ?? notImplementedPromiseString,
    setAccessToken: props.setAccessToken ?? notImplementedPromiseVoid,
    retryRefreshToken: props.retryRefreshToken ?? notImplementedPromiseString,
    signOut: props.signOut ?? notImplementedPromiseVoid,
    onError: props.onError ?? notImplementedPromiseVoid,
  };

  return (
    <RestServiceContext.Provider value={value}>
      {children}
    </RestServiceContext.Provider>
  );
};

export const useRestService = () => {
  return useContext(RestServiceContext);
};
