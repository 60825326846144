import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerNavigationProp,
  DrawerItem,
} from '@react-navigation/drawer';
import { Icon } from 'assets/components/icon';
import {
  ClipboardIcon,
  ClockIcon,
  CalendarIcon,
  UserXIcon,
  CalendarAltIcon,
} from 'assets/icons';
import NewAppointmentModal from '../schedule/new-appointment-modal/NewAppointmentModal';
import { Availability } from '../screens/schedule/availability/Availability';
import { Services } from '../screens/schedule/services/Services';
import { AvailabilitiesList } from '../screens/schedule/availability/AvailabilitiesList';
import { Service } from '../screens/schedule/service/Service';
import { Appointment } from '../screens/schedule/appointment/Appointment';
import { Appointments } from '../screens/schedule/appointments/Appointments';
import { CancelledAppointments } from '../screens/schedule/appointments/CancelledAppointments';
import { useTheme } from 'assets/theme';
import { Divider } from 'react-native-paper';
import { getText } from 'assets/localization/localization';
import { AppointmentsCalendar } from '../screens/schedule/appointments/AppointmentsCalendar';
import { navigationDrawerStyle } from '../common/theme';

const DrawerNavigator =
  createDrawerNavigator<ScheduleDrawerNavigationParamList>();

export const ScheduleDrawer: FunctionComponent<
  PropsWithChildren<ScheduleDrawerProps>
> = (props) => {
  return (
    <DrawerNavigator.Navigator
      screenOptions={{
        drawerType: 'permanent',
        headerShown: false,
        sceneContainerStyle: {
          overflow: 'scroll',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
      initialRouteName="calendar"
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      <DrawerNavigator.Screen
        name="calendar"
        options={{
          drawerItemStyle: { display: 'none' },
        }}
        component={AppointmentsCalendar}
      />
      <DrawerNavigator.Screen
        name="appointments"
        options={{
          drawerItemStyle: { display: 'none' },
        }}
        component={Appointments}
      />
      <DrawerNavigator.Screen
        name="appointments-cancellations"
        options={{
          drawerItemStyle: { display: 'none' },
        }}
        component={CancelledAppointments}
      />
      <DrawerNavigator.Screen
        name="availabilities"
        options={{
          ...navigationDrawerStyle,
          drawerLabel: 'Availability',
          drawerIcon: ({ color, size }) => (
            <Icon icon={ClockIcon} color={color} size={size} />
          ),
        }}
        component={AvailabilitiesList}
      />

      <DrawerNavigator.Screen
        name="services"
        options={{
          ...navigationDrawerStyle,
          drawerLabel: 'Services',
          drawerIcon: ({ color, size }) => (
            <Icon icon={ClipboardIcon} color={color} size={size} />
          ),
        }}
        component={Services}
      />

      <DrawerNavigator.Screen
        name="new-availability"
        options={{
          drawerItemStyle: { display: 'none' },
        }}
        component={Availability}
      />
      <DrawerNavigator.Screen
        name="edit-availability"
        options={{
          drawerItemStyle: { display: 'none' },
        }}
        component={Availability}
      />
      <DrawerNavigator.Screen
        name="new-service"
        options={{
          drawerItemStyle: { display: 'none' },
        }}
        component={Service}
      />
      <DrawerNavigator.Screen
        name="edit-service"
        options={{
          drawerItemStyle: { display: 'none' },
        }}
        component={Service}
      />
      <DrawerNavigator.Screen
        name="new-appointment"
        options={{
          drawerItemStyle: { display: 'none' },
        }}
        component={Appointment}
      />
      <DrawerNavigator.Screen
        name="edit-appointment"
        options={{
          drawerItemStyle: { display: 'none' },
        }}
        component={Appointment}
      />
    </DrawerNavigator.Navigator>
  );
};

interface ScheduleDrawerProps {}

export type ScheduleDrawerNavigationParamList = {
  availabilities: undefined;
  'appointments-cancellations': undefined;
  appointments: undefined;
  calendar: undefined;
  'new-availability': undefined;
  services: undefined;
  'edit-availability': {
    availabilityId: string;
  };
  'new-service': undefined;
  'edit-service': {
    serviceId: string;
  };
  'new-appointment': undefined;
  'edit-appointment': {
    appointmentId: string;
  };
};

export type ScheduleDrawerNavigationProp =
  DrawerNavigationProp<ScheduleDrawerNavigationParamList>;

function CustomDrawerContent(props: any) {
  const theme = useTheme();

  return (
    <>
      <DrawerContentScrollView {...props}>
        <NewAppointmentModal />
        <DrawerItem
          label={getText('calendar')}
          icon={({ color, size }) => (
            <Icon icon={CalendarAltIcon} color={color} size={size} />
          )}
          focused={props?.state.index === 0}
          onPress={() => props?.navigation?.navigate('calendar')}
          activeBackgroundColor={
            navigationDrawerStyle.drawerActiveBackgroundColor
          }
          activeTintColor={navigationDrawerStyle.drawerActiveTintColor}
          style={navigationDrawerStyle.drawerItemStyle}
          labelStyle={navigationDrawerStyle.drawerLabelStyle}
        />
        <DrawerItem
          label={getText('upcoming')}
          icon={({ color, size }) => (
            <Icon icon={CalendarIcon} color={color} size={size} />
          )}
          focused={props?.state.index === 1}
          onPress={() => props?.navigation?.navigate('appointments')}
          activeBackgroundColor={
            navigationDrawerStyle.drawerActiveBackgroundColor
          }
          activeTintColor={navigationDrawerStyle.drawerActiveTintColor}
          style={navigationDrawerStyle.drawerItemStyle}
          labelStyle={navigationDrawerStyle.drawerLabelStyle}
        />
        <DrawerItem
          label={getText('cancellations')}
          icon={({ color, size }) => (
            <Icon icon={UserXIcon} color={color} size={size} />
          )}
          focused={props?.state.index === 2}
          onPress={() =>
            props?.navigation?.navigate('appointments-cancellations')
          }
          activeBackgroundColor={
            navigationDrawerStyle.drawerActiveBackgroundColor
          }
          activeTintColor={navigationDrawerStyle.drawerActiveTintColor}
          style={navigationDrawerStyle.drawerItemStyle}
          labelStyle={navigationDrawerStyle.drawerLabelStyle}
        />
        <Divider
          style={{
            width: 'calc(100% - 20px)',
            marginHorizontal: 'auto',
            marginVertical: theme.getSpacing(1),
          }}
        />
        <DrawerItemList {...props} />
      </DrawerContentScrollView>
    </>
  );
}
