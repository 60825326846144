import { makeStyles } from 'assets/theme';
import { UserTyping } from '../hooks/useSockets';
import { View, Text } from 'react-native';

interface WritingBarProps {
  typingMember?: UserTyping | null;
  conversationId: string;
}

const WritingBar = ({ typingMember, conversationId }: WritingBarProps) => {
  const styles = useStyles();

  if (!typingMember) {
    return null;
  }

  const arr = typingMember.name.split(' ');
  const Abbreviation =
    arr[0].charAt(0).toLocaleUpperCase() + arr[1].charAt(0).toLocaleUpperCase();
  return conversationId === typingMember.conversation_id ? (
    <View style={styles.writerBarContainer}>
      <View style={{ flexDirection: 'row' }}>
        <View style={styles.avatar}>
          <Text>{Abbreviation}</Text>
        </View>
        <View style={styles.box}>
          <View
            style={[styles.bubbleContainer, { marginLeft: 0, marginRight: 60 }]}
          >
            <Text style={[styles.bubbleText]}>...</Text>
          </View>
          <Text>Typing</Text>
        </View>
      </View>
    </View>
  ) : null;
};

export default WritingBar;

const useStyles = makeStyles((theme) => ({
  bubbleContainer: {
    marginLeft: 60,
    backgroundColor: theme.palette.gray[100],
    padding: theme.getSpacing(1),
    borderRadius: 8,
  },
  bubbleText: {
    ...theme.fonts.regular,
    color: theme.palette.black,
  },
  writerBarContainer: {
    padding: 7,
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: theme.palette.gray[100],
  },
  box: {
    padding: 7,
    flexDirection: 'column',
  },
}));
