import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Button } from 'assets/components/button';
import { AvailabilityForm } from '../../../schedule/availability-form/AvailabilityForm';
import { IconButton } from 'assets/components/icon-button';
import { ChevronLeftIcon, TrashIcon } from 'assets/icons';
import { ScheduleDrawerNavigationProp } from '../../../layout/ScheduleDrawer';
import { Text } from 'assets/components/text';
import {
  deleteSchedule,
  submitAvailabilityForm,
} from '../../../schedule/availability-form/availability-form-actions';
import { useAvailabilityFormState } from '../../../schedule/availability-form/availability-form-store';
import { resetAvailabilitiesList } from '../../../schedule/availabilities-list/availabilities-list-actions';
import { makeStyles } from '../../../../../../packages/assets/theme';
import PharmacyConfirmationModal from '../../../components/PharmacyConfirmationModal';

export const Availability: FunctionComponent<AvailabilityProps> = (props) => {
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();
  const { status } = useAvailabilityFormState();
  const route = useRoute<any>();
  const availabilityId = route.params?.availabilityId;
  const edit = !!availabilityId;
  const styles = useStyles();
  const [showModal, setShowModal] = useState(false);

  const handleDeleteClick = async () => {
    setShowModal(false);
    await deleteSchedule(availabilityId);
    resetAvailabilitiesList();
    navigation.navigate('availabilities');
  };

  return (
    <View style={styles.container}>
      <PharmacyConfirmationModal
        show={showModal}
        onAccepted={() => handleDeleteClick()}
        onDismiss={() => setShowModal(false)}
        message={`Are you sure you want to delete the availability?`}
      />
      <View style={styles.header}>
        <IconButton
          size={25}
          logger={{ id: 'go-back-availability-form-button' }}
          icon={ChevronLeftIcon}
          onPress={() => navigation.navigate('availabilities')}
          style={styles.backButton}
        />
        <Text style={styles.title} selectable>
          Availability
        </Text>
        {edit && (
          <Button
            hierarchy="tertiary-gray"
            style={{
              borderColor: 'transparent',
            }}
            mode="outlined"
            size="small"
            logger={{ id: 'delete-availability-button' }}
            icon={TrashIcon}
            loading={status === 'loading'}
            disabled={status === 'loading'}
            onPress={() => setShowModal(true)}
          >
            Delete
          </Button>
        )}
        <Button
          hierarchy="pharmacy-primary"
          size="small"
          logger={{ id: 'save-availability-button' }}
          onPress={() => submitAvailabilityForm()}
          loading={status === 'loading'}
          disabled={status === 'loading'}
        >
          Save
        </Button>
      </View>
      <View>
        <AvailabilityForm />
      </View>
    </View>
  );
};

export interface AvailabilityProps {}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    margin: theme.getSpacing(4),
    paddingBottom: theme.getSpacing(4),
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.getSpacing(4),
    gap: theme.getSpacing(2),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[300],
    paddingBottom: theme.getSpacing(3),
  },
  title: {
    fontSize: 25,
    flexGrow: 1,
  },
  backButton: {
    margin: 0,
    marginLeft: -10,
  },
}));
