import React, { useEffect } from 'react';
import { Modal } from 'assets/components/modal';
import shallow from 'zustand/shallow';

import {
  BulkActionType,
  useTasksDataTableState,
} from './tasks-data-table-store';
import {
  executeBulkAction,
  removeBulkAction,
} from './tasks-data-table-actions';
import { formatUTCToRelative, pluralize } from '../../common/datetime-utils';
import { View } from 'react-native';
import { Form } from 'assets/layout';
import { useForm } from 'assets/form';
import { DateTimePickerField } from '../../components/DateTimePickerField';
import { getText } from '../../../../../packages/assets/localization/localization';

interface SelectDueDateForm {
  due_date: string | undefined;
}

export default function BulkDueDateDialog() {
  const { bulkAction, gridApi } = useTasksDataTableState(
    (state) => ({
      bulkAction: state.bulkAction,
      gridApi: state.gridApi,
    }),
    shallow,
  );

  const affectedItemsCount =
    bulkAction?.affectedIds?.length ?? gridApi?.paginationGetRowCount();

  const methods = useForm<SelectDueDateForm>({
    defaultValues: {
      due_date: new Date().toISOString(),
    },
  });

  const handleSubmit = async () => {
    const selectedDueDate = methods.getValues().due_date;
    bulkAction!.modifier = selectedDueDate;
    bulkAction!.modifierName = formatUTCToRelative(
      selectedDueDate!,
      undefined,
      true,
    );
    executeBulkAction(bulkAction!);
  };

  useEffect(() => {
    methods.setValue('due_date', undefined);
  }, [bulkAction]);

  return (
    <Modal
      title={
        bulkAction &&
        getText('bulk-due-date-confirmation-modal', {
          count: affectedItemsCount,
          type:
            affectedItemsCount && affectedItemsCount > 1
              ? getText('tasks')
              : getText('task'),
        })
      }
      size="sm"
      contentContainerStyle={{
        // Temporary workaround as datepicker won't render if there's no vertical space (up or down)
        minHeight: '380px',
      }}
      cancelButtonProps={{
        onPress: () => removeBulkAction(),
        logger: { id: 'task-bulk-due-date-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        hierarchy: 'pharmacy-primary',
        logger: { id: 'task-bulk-due-date-ok-button-modal' },
      }}
      show={!!bulkAction && bulkAction.type === BulkActionType.DUE_DATE}
    >
      <View>
        <Form methods={methods}>
          <Form.Row>
            <Form.Column>
              <View style={{ width: '100%' }}>
                <DateTimePickerField
                  name="due_date"
                  type="date-and-time-combo"
                  minDate={new Date().toISOString()}
                  showTimeInput={true}
                ></DateTimePickerField>
              </View>
            </Form.Column>
          </Form.Row>
        </Form>
      </View>
    </Modal>
  );
}
