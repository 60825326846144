import { logError, logEvent } from 'assets/logging/logger';
import { useUserState } from '../../store/user-store';
import { useAppStateStore } from '../../store/app-store';
import { useLoginState } from './login-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StorageKeys } from '../../../enums/storage-keys';
import { PharmacyUser } from '../../api';
import { ampli } from '../../common/ampliPharmacist';
import roleService from '../../api/RoleService';
import UsersService from '../../api/UsersService';
import { PharmacistUserLoginRequest } from '@digitalpharmacist/users-service-client-axios';

export const login = async (
  values: PharmacistUserLoginRequest,
): Promise<void> => {
  useLoginState.setState({ error: undefined, status: 'loading' });

  try {
    const { accessToken, refreshToken, userInfo } =
      await UsersService.logIn(values);
    if (accessToken && refreshToken && userInfo) {
      // we should get all the information needed here
      await AsyncStorage.setItem(StorageKeys.AccessToken, accessToken);
      await AsyncStorage.setItem(StorageKeys.RefreshToken, refreshToken);
      useLoginState.setState({ status: 'success' });
      await AsyncStorage.setItem(StorageKeys.UserId, userInfo.id!);
      useUserState.setState({ data: userInfo });

      // TODO: get pharmacy id from the login call
      const pharmacyId = '';
      ampli.userLogin({
        pharmacy_id: pharmacyId,
        status: 'Logged In',
        method: 'lumistry',
      });
      const allUserDetails =
        await roleService.userRoleGetUsersDetailsByPharmacyId(pharmacyId);

      const userDetails = allUserDetails.filter(
        (it) => it.userId === userInfo.id,
      );

      if (userInfo.preferredPharmacyLocationId) {
        useAppStateStore.setState({
          locationId: userInfo.preferredPharmacyLocationId,
        });
      }

      if (
        useAppStateStore.getState().userLocations.length === 0 &&
        userInfo.id
      ) {
        await useAppStateStore.getState().getUserLocations(userInfo.id);
      }
    }
  } catch (e) {
    logEvent('login_failed', { email: values.email });
    useLoginState.setState({
      error: {
        message: e as string,
      },
      status: 'error',
    });
  }
};

export const logout = async (): Promise<void> => {
  try {
    AsyncStorage.clear();
    useUserState.setState({
      data: undefined,
    });
  } catch (error: any) {
    logError(error);
  }
};

export interface LoginForm {
  email: string;
  password: string;
  pharmacyId: string;
}

export interface UserToken {
  accessToken: string;
  idToken: string;
  userInfo: PharmacyUser;
}
