import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const CheckCircleIconFilledIn: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        d="M12 2a9.96 9.96 0 0 1 6.467 2.373l-6.934 6.63-1.908-1.821a2.157 2.157 0 0 0-1.492-.594c-.54 0-1.08.2-1.492.594a2.049 2.049 0 0 0 0 2.977l3.4 3.247c.412.394.952.594 1.493.594.541 0 1.08-.2 1.492-.595l8.022-7.669A9.962 9.962 0 0 1 22 12c0 2.761-1.12 5.261-2.929 7.071S14.761 22 12 22s-5.261-1.12-7.071-2.929S2 14.761 2 12s1.12-5.261 2.929-7.071S9.239 2 12 2z"
        fillRule="evenodd"
      />
    </Svg>
  );
};
