import React, {
  Fragment,
  FunctionComponent,
  PropsWithChildren,
  useState,
} from 'react';
import { Modal } from 'assets/components/modal';
import { View, TouchableOpacity } from 'react-native';

import { TextField } from 'assets/components/text-field';
import { Button } from 'assets/components/button';
import { makeStyles, useTheme } from 'assets/theme';
import { Form } from 'assets/layout';
import FormPreview from './FormPreview';
import { useForm } from 'assets/form';
import { useNewFormModalState } from './new-form-modal-store';
import { NewFormModalTestIDs } from './NewFormModalTestIDs';
import {
  createNewForm,
  getFormTemplates,
  NewForm,
} from './new-form-modal-actions';
import { useNavigation } from '@react-navigation/native';
import { FormsDrawerNavigationProp } from '../../layout/FormsDrawer';
import { PharmacyConfirmationModal } from '../../components/PharmacyConfirmationModal';
import { ampli } from '../../common/ampliPharmacist';
import { toggleModalVisibility } from '../forms-import-stepper/forms-import-stepper-actions';

export const NewFormModal: FunctionComponent<
  PropsWithChildren<NewFormModalProps>
> = () => {
  const styles = useStyles();
  const theme = useTheme();
  const [show, setShow] = useState(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    null,
  );

  const [nextStep, setNextStep] = useState<boolean>(false);

  const { status, error, templates } = useNewFormModalState();
  const methods = useForm<NewForm>({
    defaultValues: {
      name: '',
    },
  });

  const navigation = useNavigation<FormsDrawerNavigationProp>();

  const handleSubmit = async () => {
    const values = methods.getValues();

    ampli.formCreated({
      formAccess: 'public',
      formSavedTime: new Date().toISOString(),
      formStatus: 'active',
      formType: templates?.find((t) => t.id == values.template)?.title || '',
    });

    await createNewForm(values, navigation);
    closeModal();
  };

  const selectFormTemplate = (id: string) => {
    setSelectedTemplateId(id);
    methods.setValue('template', id);
  };

  const openModal = async () => {
    await getFormTemplates();
    setShow(true);
  };

  const closeModal = () => {
    setShowCloseConfirmation(false);
    setShow(false);
    setSelectedTemplateId(null);
    setNextStep(false);
    methods.reset();
  };

  const handleOpenImportFormModal = () => {
    closeModal();
    toggleModalVisibility(true);
  };

  const handleNextStep = () => {
    if (selectedTemplateId === 'import_form') {
      handleOpenImportFormModal();
      return;
    }

    setNextStep(true);
  };

  return (
    <>
      <Button
        hierarchy="pharmacy-primary"
        size="small"
        logger={{ id: 'new-form-button' }}
        style={{
          margin: theme.getSpacing(2),
        }}
        onPress={() => openModal()}
      >
        New form
      </Button>
      <Modal
        title="New Form"
        size="lg"
        cancelButtonProps={{
          onPress: nextStep ? () => setShowCloseConfirmation(true) : closeModal,
          logger: { id: 'new-form-cancel-button-modal' },
        }}
        okButtonProps={{
          onPress: nextStep
            ? methods.handleSubmit(handleSubmit)
            : () => handleNextStep(),
          logger: { id: 'new-form-ok-button-modal' },
          hierarchy: 'pharmacy-primary',
          text: nextStep ? 'Ok' : 'Next',
          disabled:
            selectedTemplateId === null || status === 'loading' ? true : false,
        }}
        show={show}
      >
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        {nextStep ? (
          <Form methods={methods}>
            <Form.Row>
              <Form.Column>
                <TextField
                  label="Name *"
                  name="name"
                  rules={{
                    required: 'Name is required',
                  }}
                  disabled={status === 'loading'}
                  onSubmit={methods.handleSubmit(handleSubmit)}
                  testID={NewFormModalTestIDs.nameInput}
                />
              </Form.Column>
            </Form.Row>
          </Form>
        ) : (
          <View style={styles.formPreviewsContainer}>
            {templates?.length ? (
              templates?.map((template) => (
                <Fragment key={template.id}>
                  <TouchableOpacity
                    onPress={() => {
                      selectFormTemplate(template.id);
                    }}
                  >
                    <FormPreview
                      title={template.title}
                      selected={template.id === selectedTemplateId}
                      accessType={template.accessType}
                    />
                  </TouchableOpacity>
                  {template.title === 'Blank Form' && (
                    <TouchableOpacity
                      key={template.id}
                      onPress={() => selectFormTemplate('import_form')}
                    >
                      <FormPreview
                        title="Import Form"
                        selected={selectedTemplateId === 'import_form'}
                        accessType={template.accessType}
                      />
                    </TouchableOpacity>
                  )}
                </Fragment>
              ))
            ) : (
              <Form.Alert
                title={'No form templates available.'}
                intent="info"
                visible={templates?.length == 0}
              />
            )}
          </View>
        )}
      </Modal>
      <PharmacyConfirmationModal
        show={showCloseConfirmation}
        onAccepted={closeModal}
        onDismiss={() => setShowCloseConfirmation(false)}
        message="By confirming you will lose the current progress on creating a new
          form from a template."
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  formPreviewsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.getSpacing(2),
    overflowX: 'scroll',
  },
}));

interface NewFormModalProps {}

export default NewFormModal;
