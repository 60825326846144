import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'assets/form';
import { StyleSheet, View } from 'react-native';
import { logError } from 'assets/logging/logger';
import { Text } from 'assets/components/text';
import { DrawerScreenProps } from '@react-navigation/drawer';
import { theme, getSpacing } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import { useNavigation } from '@react-navigation/native';
import { SettingsDrawerNavigationProp } from '../../../layout/SettingsDrawer';
import { IconButton } from 'assets/components/icon-button';
import { ArrowLeftIcon, PencilIcon } from 'assets/icons';
import UsersService from '../../../api/UsersService';
import RoleService from '../../../api/RoleService';
import PharmacyService from '../../../api/PharmacyService';
import { PharmacyUserInfo } from '@digitalpharmacist/users-service-client-axios';
import { EntityType, Role } from '@digitalpharmacist/role-service-client-axios';
import { useAppStateStore } from '../../../store/app-store';
import { UserForm } from './UserTypes';
import { UserModal } from './UserModal';
import { roleDisplay } from './UserHelpers';

type LocationNameAndRole = {
  location: string;
  role: Role;
};

export const EditUser: FunctionComponent<EditUserProps> = ({ route }) => {
  const navigation = useNavigation<SettingsDrawerNavigationProp>();
  const { pharmacyId } = useAppStateStore();
  const [pharmacyUserInfo, setPharmacyUserInfo] = useState<PharmacyUserInfo>();
  const [locationNameAndRoles, setLocationNameAndRoles] = useState<
    LocationNameAndRole[]
  >([]);

  const [dedupedRoleList, setDedupedRoleList] = useState<string[]>();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const handleSubmit = () => {
    setShowEditModal(false);
  };

  const methods = useForm<UserForm>();

  const goBack = () => {
    navigation.navigate('users-list');
  };

  const openEditModal = () => {
    alert('Not Implemented Yet');
    // setShowEditModal(true)
  };

  useEffect(() => {
    (async () => {
      const userData = await UsersService.userPharmacistFindById(
        route.params?.userId,
      );
      setPharmacyUserInfo(userData);

      const roleData = await RoleService.userRoleGetRolesByUserId(
        route.params?.userId,
      );

      setLocationNameAndRoles([]);

      for (const role of roleData) {
        if (role.entity_type === EntityType.Location) {
          const dedupedRoleList = [
            ...new Set(roleData.map((role) => roleDisplay(role.role))),
          ];
          setDedupedRoleList(dedupedRoleList);

          try {
            const location = await PharmacyService.pharmacyLocationFindOne(
              role.entity_id,
              pharmacyId,
            );
            const currentLocationNameAndRole: LocationNameAndRole = {
              location: location.name,
              role: role.role,
            };
            setLocationNameAndRoles((locationNameAndRoles) => [
              ...locationNameAndRoles,
              currentLocationNameAndRole,
            ]);

            if (userData.firstName) {
              methods.setValue('firstName', userData.firstName);
            }
            if (userData.lastName) {
              methods.setValue('lastName', userData.lastName);
            }
            if (userData.email) {
              methods.setValue('email', userData.email);
            }
          } catch (err: any) {
            logError(err);
          }
        }
      }
    })();
  }, [route.params?.userId]);

  return (
    <>
      <UserModal
        title={'Edit User'}
        onSubmit={handleSubmit}
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        methods={methods}
      />
      <View style={styles.container}>
        <View>
          <View style={styles.backNav}>
            <IconButton
              size={20}
              logger={{ id: 'go-back-user-button' }}
              icon={ArrowLeftIcon}
              onPress={goBack}
              style={styles.backNavChild}
            />
            <Text style={styles.backNavChild}>{getText('users')}</Text>
          </View>
          <Text style={styles.title} selectable>
            {getText('users')}
          </Text>
        </View>
        <View style={styles.content}>
          <View style={styles.bodyBlock}>
            <View style={styles.leftColumn}>
              <Text style={styles.leftColumnTitle}>
                {getText('information')}
              </Text>
            </View>
            <View style={styles.rightColumn}>
              <View style={styles.rightColumnHeader}>
                <Text style={styles.rightColumnTitle}>
                  {getText('information')}
                </Text>
                <View style={styles.editWrapper}>
                  <IconButton
                    size={15}
                    logger={{ id: 'edit-icon' }}
                    icon={PencilIcon}
                    color={theme.palette.primary[600]}
                    onPress={openEditModal}
                  />
                  <Text onPress={openEditModal} style={styles.edit}>
                    {getText('edit')}
                  </Text>
                </View>
              </View>
              <View>
                <View style={styles.bodyRow}>
                  <Text style={styles.bodyRowLeft}>
                    {getText('first-name-edit-user')}
                  </Text>
                  <Text style={styles.bodyRowRight}>
                    {pharmacyUserInfo?.firstName}
                  </Text>
                </View>
                <View style={styles.bodyRow}>
                  <Text style={styles.bodyRowLeft}>
                    {getText('last-name-edit-user')}
                  </Text>
                  <Text style={styles.bodyRowRight}>
                    {' '}
                    {pharmacyUserInfo?.lastName}
                  </Text>
                </View>
                <View style={styles.bodyRow}>
                  <Text style={styles.bodyRowLeft}>{getText('email')}</Text>
                  <Text style={styles.bodyRowRight}>
                    {' '}
                    {pharmacyUserInfo?.email}
                  </Text>
                </View>
                <View style={styles.bodyRow}>
                  <Text style={styles.bodyRowLeft}>{getText('phone')}</Text>
                  <Text style={styles.bodyRowRight}>Not Implemented</Text>
                </View>
                <View style={styles.bodyRow}>
                  <Text style={styles.bodyRowLeft}>{getText('role')}</Text>
                  <Text style={styles.roles}>
                    {dedupedRoleList?.join(', ')}
                  </Text>
                </View>
                <View style={styles.bodyRow}>
                  <Text style={styles.bodyRowLeft}>{getText('password')}</Text>
                  <Text style={[styles.resetPassword]} onPress={openEditModal}>
                    {getText('reset-password')}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.bodyBlock}>
            <View style={styles.leftColumn}>
              <Text style={styles.leftColumnTitle}>
                {getText('location')} &amp; {getText('role')}
              </Text>
            </View>
            <View style={styles.rightColumn}>
              <View style={styles.rightColumnHeader}>
                <Text style={styles.rightColumnTitle}>
                  {getText('location')}
                </Text>
                <View style={styles.editWrapper}>
                  <IconButton
                    size={15}
                    logger={{ id: 'edit-icon' }}
                    icon={PencilIcon}
                    color={theme.palette.primary[600]}
                    onPress={() => {}}
                  />
                  <Text style={styles.edit}>{getText('edit')}</Text>
                </View>
              </View>
              <View>
                {locationNameAndRoles.map((locationNameAndRole) => (
                  <View style={styles.bodyRow}>
                    <Text style={styles.bodyRowLeft}>
                      {locationNameAndRole.location}
                    </Text>
                    <Text style={styles.roles}>
                      {roleDisplay(locationNameAndRole.role)}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    margin: getSpacing(4),
    marginTop: getSpacing(2),
    height: '100%',
  },
  content: {
    flexGrow: 1,
  },
  title: {
    fontSize: 24,
    fontWeight: '600',
    lineHeight: 26,
    paddingBottom: getSpacing(2),
    borderBottomColor: theme.palette.gray[600],
    borderBottomWidth: 1,
  },
  backNav: {
    display: 'flex',
    alignSelf: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: getSpacing(2),
  },
  backNavChild: {
    width: 'default',
    marginRight: getSpacing(1),
    flex: 1,
    margin: 0,
  },
  bodyBlock: {
    marginTop: getSpacing(4),
    display: 'flex',
    flexDirection: 'row',
  },
  leftColumn: {
    flex: 25,
  },
  rightColumn: {
    flex: 75,
    marginLeft: getSpacing(3),
  },
  leftColumnTitle: {
    fontWeight: '600',
    fontSize: 16,
    marginBottom: getSpacing(2),
  },
  rightColumnHeader: {
    borderBottomColor: theme.palette.gray[600],
    paddingBottom: getSpacing(1),
    borderBottomWidth: 1,
    marginBottom: getSpacing(2),
    maxWidth: '80%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightColumnTitle: {
    fontWeight: '700',
    fontSize: 14,
  },
  editWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  edit: {
    weight: '400',
    color: theme.palette.primary[600],
  },
  resetPassword: {
    weight: '400',
    color: theme.palette.primary[600],
    flex: 65,
  },
  bodyRow: {
    borderBottomColor: theme.palette.gray[200],
    paddingBottom: getSpacing(1),
    borderBottomWidth: 1,
    marginBottom: getSpacing(2),
    marginTop: getSpacing(1),
    maxWidth: '80%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  bodyRowLeft: {
    color: theme.palette.gray[900],
    fontSize: 14,
    flex: 35,
  },
  bodyRowRight: {
    color: theme.palette.gray[400],
    fontSize: 14,
    flex: 65,
  },
  roles: {
    color: theme.palette.gray[400],
    fontSize: 14,
    flex: 65,
    textTransform: 'capitalize',
  },
});

interface EditUserProps extends DrawerScreenProps<any, any> {}
