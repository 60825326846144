import {
  TaskDto,
  TaskSortOrder,
} from '@digitalpharmacist/tasks-service-client-axios';
import { Moment } from 'moment';
import { GSTCOptions, GSTCResult } from 'gantt-schedule-timeline-calendar';
import create from 'zustand';
import { ApiError, AsyncStatus } from '../../store/types';
import { initialFromDate, initialToDate } from './tasks-grid-utils';

export enum TaskSortBy {
  DueDate = 'due_date',
  Assignee = 'assignee',
  Summary = 'summary',
}

export const useTasksGridState = create<TasksGridState>(() => ({
  status: 'idle',
  error: undefined,
  tasks: [],
  gstcState: null,
  gstc: null,
  sidebarCollapsed: true,
  reload: false,
  sortingMenuOpen: false,
  sortBy: TaskSortBy.DueDate,
  order: TaskSortOrder.Asc,
  iconColors: undefined,
  infiniteScrollStatus: 'idle',
  incomingTasks: [],
  isLastPage: false,
  offset: 0,
  limit: 20,
  minDueDate: initialFromDate.startOf('day'),
  maxDueDate: initialToDate.endOf('day'),
}));

interface TasksGridState {
  status: AsyncStatus;
  error?: ApiError;
  tasks?: TaskDto[];
  gstcState: GSTCOptions['state'];
  gstc: GSTCResult | null;
  sidebarCollapsed: boolean;
  reload: boolean;
  sortingMenuOpen: boolean;
  sortBy: TaskSortBy;
  order: TaskSortOrder;
  iconColors?: IconColors;
  infiniteScrollStatus: AsyncStatus;
  incomingTasks: TaskDto[];
  isLastPage: boolean;
  offset: number;
  limit: number;
  minDueDate: Moment;
  maxDueDate: Moment;
}

export interface IconColors {
  on_hold: string;
  in_progress: string;
  resolved: string;
  unresolved: string;
  overdue: string;
  coming_soon: string;
}
