import create from 'zustand';
import { BaseFilterValues, InboxState } from '../types';
import { sortingOptions, MESSAGES_COUNT_LIMIT } from '../data';

export const useInboxState = create<InboxState>(() => ({
  patients: [],
  selectedPatient: null,
  selectedUserNames: {},
  selectedConversation: null,
  viewedConversations: [],
  conversationsSorting: sortingOptions[0],
  messagesGroups: [],
  selectedMessages: [],
  rawConversations: [],
  isOpenNewChatModal: false,
  patientFilter: '',
  templates: [],
  messagesPagination: {
    limit: MESSAGES_COUNT_LIMIT,
    count: 0,
  },
  showAddPatientModal: false,

  baseFilter: BaseFilterValues.ALL,
  multiFilters: [],
  disabled: false,
  count: {},
}));
