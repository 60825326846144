import { getSpacing } from 'assets/theme';
import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { makeStyles, useTheme } from 'assets/theme';
import { DataGrid } from 'assets/components/data-grid';
import { View, Text, Alert } from 'react-native';
import { Menu, Divider } from 'react-native-paper';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { getText } from 'assets/localization/localization';
import { Avatar } from 'assets/components/avatar';
import UserDetailSidebar from './UserDetailSidebar';
import { useProSidebar } from 'react-pro-sidebar';
import { formatDate } from '../../../common/datetime-utils';
import useViewSidebarStore from './view-user-sidebar-store';
import { IconButton } from 'assets/components/icon-button';
import { ThreedotsVerticalIcon } from 'assets/icons';
import { Modal } from 'assets/components/modal';
import { DetailedUserDto } from '@digitalpharmacist/role-service-client-axios';
import RoleService from '../../../api/RoleService';
import { useAppStateStore } from '../../../store/app-store';
import { SettingsDrawerNavigationProp } from '../../../layout/SettingsDrawer';
import { UserModal } from './UserModal';
import { UserForm } from './UserTypes';
import { useForm } from 'assets/form';
import { logError } from 'assets/logging/logger';
import { UserDetails } from './user-types';

type DetailedUserDtoWithFullName = DetailedUserDto & { full_name: string };

export default function Users() {
  const styles = useStyles();
  const theme = useTheme();
  const { pharmacyId } = useAppStateStore();
  const [rowData, setRowData] = useState<DetailedUserDtoWithFullName[]>();
  const {
    userDetails,
    showSidebar,
    setShowSidebar,
    setUserDetails,
    showConfirmDeleteModal,
    setShowConfirmDeleteModal,
  } = useViewSidebarStore();
  const { collapseSidebar } = useProSidebar();
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const methods = useForm<UserForm>();

  useEffect(() => {
    const fetchData = async () => {
      const usersData =
        await RoleService.userRoleGetUsersDetailsByPharmacyId(pharmacyId);
      setRowData(
        usersData.map((row) => ({
          ...row,
          full_name: `${row.firstName} ${row.lastName}`,
        })),
      );
    };

    fetchData()
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      .catch((error) => logError(error));
  }, [pharmacyId]);

  const onSubmit = async () => {
    setIsLoading(true);
    const usersData =
      await RoleService.userRoleGetUsersDetailsByPharmacyId(pharmacyId);
    setRowData(
      usersData.map((row) => ({
        ...row,
        full_name: `${row.firstName} ${row.lastName}`,
      })),
    );
    methods.reset();
    setIsLoading(false);
  };

  const onDelete = () => {
    setShowConfirmDeleteModal(false);
    alert('Not implemented yet');
  };

  const columns: (ColDef | ColGroupDef)[] = [
    {
      field: 'full_name',
      cellRenderer: FullNameRenderer,
    },
    {
      field: 'email',
      cellRenderer: EmailLocationRenderer,
    },
    {
      field: 'last_active',
      cellRenderer: LastActiveRenderer,
      cellStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    {
      field: 'menu',
      cellRenderer: MenuRenderer,
      width: 36,
      cellStyle: { padding: 0 },
    },
  ];

  const [columnDefs] = useState(columns);

  return (
    <>
      <UserModal
        title={'Create User'}
        onSubmit={() => void onSubmit()}
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        methods={methods}
      />
      <View style={styles.container}>
        <View style={styles.flex}>
          <Text style={styles.title}>{getText('users')}</Text>
          {isLoading ? (
            <>
              <View style={styles.infiniteLoadingIndicatorMask}></View>
              <View style={styles.infiniteLoadingIndicator}>
                <LoadingIndicator color={theme.colors.pharmacyPrimary} />
              </View>
            </>
          ) : null}
          {!isLoading ? (
            <DataGrid
              isRichContent={true}
              gridOptions={{
                headerHeight: 0,
                rowData,
                columnDefs: columnDefs,
                enableCellTextSelection: true,
                suppressMovableColumns: true,
                suppressContextMenu: true,
                defaultColDef: { sortable: true, menuTabs: [] },
                pagination: true,
                paginationPageSize: 25,
                loadingOverlayComponent: 'loadingIndicator',
                loadingOverlayComponentParams: {
                  color: theme.colors.pharmacyPrimary,
                },
                components: {
                  loadingIndicator: LoadingIndicator,
                },
                rowSelection: 'single',
                suppressCellFocus: true,
                colResizeDefault: 'shift',
                onCellClicked(event) {
                  if (event.colDef.field !== 'menu') {
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    setUserDetails(event.node.data as UserDetails);
                    collapseSidebar(false);
                    setShowSidebar(true);
                  }
                },
              }}
              gridToolbarProps={{
                inputSearchProps: {
                  size: 'lg',
                  placeholder: getText('search'),
                },
                actionButtonsProps: {
                  maxActionToShow: 1,
                  actionButtons: [
                    {
                      text: getText('new-user'),
                      hierarchy: 'pharmacy-primary',
                      logger: { id: 'new-user-button' },
                      onPress: () => setShowAddModal(true),
                      style: { marginRight: getSpacing(1) },
                    },
                  ],
                },
              }}
            />
          ) : null}
        </View>
        {showSidebar && <UserDetailSidebar />}

        <Modal
          title=""
          cancelButtonProps={{
            onPress: () => setShowConfirmDeleteModal(false),
            logger: { id: 'user-delete-cancel-button' },
          }}
          okButtonProps={{
            onPress: onDelete,
            logger: { id: 'user-delete-confirm-button' },
            hierarchy: 'destructive',
            text: getText('delete'),
          }}
          show={showConfirmDeleteModal}
          size="sm"
        >
          <Text selectable style={styles.confirmationText}>
            {getText('delete-confirmation')} {getText('user').toLowerCase()}:{' '}
            {userDetails?.full_name}
          </Text>
          <Text style={styles.confirmationDescription}>
            {getText('cannot-undo-action')}
          </Text>
        </Modal>
      </View>
    </>
  );
}

const PrimarySecondaryTextRenderer = (props: {
  primaryText: string;
  secondaryText: string;
}) => {
  const styles = useStyles();
  return (
    <View style={styles.columnFlex}>
      <Text style={[styles.primaryText, styles.textEllipsis]}>
        {props.primaryText}
      </Text>
      <Text style={[styles.secondaryText, styles.textEllipsis]}>
        {props.secondaryText}
      </Text>
    </View>
  );
};

const FullNameRenderer = (props: { data: DetailedUserDtoWithFullName }) => {
  const styles = useStyles();
  const theme = useTheme();
  const user = props.data;
  return (
    <View style={styles.iconContainer}>
      <View
        style={{ justifyContent: 'center', marginRight: theme.getSpacing(1) }}
      >
        <Avatar
          name={user.full_name}
          size={32}
          color={theme.palette.gray[200]}
        />
      </View>
      <PrimarySecondaryTextRenderer
        primaryText={user.full_name}
        // secondaryText={user.title} title doesn't exist yet
        secondaryText={''}
      />
    </View>
  );
};

const EmailLocationRenderer = (props: {
  data: DetailedUserDtoWithFullName;
}) => {
  const styles = useStyles();
  const user = props.data;
  const locationCount = user.locations.length;

  let locationLabel =
    locationCount === 1 ? getText('location') : getText('locations');
  locationLabel = locationLabel.toLowerCase();

  return (
    <View style={[styles.padding1]}>
      <PrimarySecondaryTextRenderer
        primaryText={user.email}
        secondaryText={`${locationCount} ${locationLabel}`}
      />
    </View>
  );
};

export const LastActiveRenderer = (props: {
  data: DetailedUserDtoWithFullName;
}) => {
  const styles = useStyles();
  const user = props.data;

  return (
    <Text style={[styles.primaryText, styles.textEllipsis]}>
      {getText('last-active')}:{' '}
      {user.updatedDate ? formatDate(user.updatedDate) : null}
    </Text>
  );
};

const MenuRenderer = (props: { data: DetailedUserDtoWithFullName }) => {
  const user = props.data;
  const navigation = useNavigation<SettingsDrawerNavigationProp>();
  const [showMenu, setShowMenu] = useState(false);
  const { setUserDetails, setShowConfirmDeleteModal } = useViewSidebarStore();

  const openMenu = () => {
    setShowMenu(true);
  };

  const onEdit = () => {
    navigation.navigate('edit-user', {
      userId: user.userId,
    });

    setShowMenu(false);
  };

  const onViewActivityLogs = () => {
    alert('Not implemented yet');
    setShowMenu(false);
  };

  const onResendInvite = () => {
    alert('Not implemented yet');
    setShowMenu(false);
  };

  const onDelete = () => {
    setUserDetails(user);
    setShowConfirmDeleteModal(true);
    setShowMenu(false);
  };

  return (
    <View>
      <Menu
        visible={showMenu}
        onDismiss={() => setShowMenu(false)}
        anchor={
          <IconButton
            onPress={openMenu}
            icon={ThreedotsVerticalIcon}
            logger={{ id: 'user-menu' }}
          />
        }
      >
        <Menu.Item onPress={onEdit} title={getText('edit')} />
        <Menu.Item
          onPress={onViewActivityLogs}
          title={getText('view-activity-logs')}
        />
        <Menu.Item onPress={onResendInvite} title={getText('resend-invite')} />
        <Divider />
        <Menu.Item onPress={onDelete} title={getText('delete')} />
      </Menu>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 24,
    fontWeight: '600',
    lineHeight: 26,
    color: theme.palette.gray[900],
    marginBottom: theme.getSpacing(1),
  },
  flex: {
    flex: 1,
  },
  columnFlex: {
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    justifyContent: 'center',
    marginRight: theme.getSpacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: theme.getSpacing(4),
    height: '100%',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.getSpacing(1),
    color: theme.palette.gray[700],
  },
  padding1: {
    padding: theme.getSpacing(1),
  },
  nameText: {
    lineHeight: 15,
    fontSize: 13,
  },
  primaryText: {
    fontWeight: '600',
    color: theme.palette.gray[900],
  },
  secondaryText: {
    fontWeight: '400',
    color: theme.palette.gray[600],
  },
  lastCell: {
    flex: 1,
    height: 48,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  confirmationText: {
    textAlign: 'left',
    fontSize: 18,
    fontWeight: '400',
    color: theme.palette.gray[900],
    lineHeight: 20,
  },
  confirmationDescription: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: '400',
    color: theme.palette.gray[700],
    lineHeight: 18,
    paddingTop: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(2),
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  infiniteLoadingIndicatorMask: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: theme.palette.white,
    opacity: 0.6,
  },
  infiniteLoadingIndicator: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: [{ translateX: -50 }, { translateY: -50 }],
  },
}));
