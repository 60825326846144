import React from 'react';
import PharmacyConfirmationModal from '../../components/PharmacyConfirmationModal';
import { useTasksDataTableState } from './tasks-data-table-store';
import shallow from 'zustand/shallow';
import {
  executeBulkAction,
  getBulkActionHumanName,
  removeBulkAction,
} from './tasks-data-table-actions';
import { getText } from '../../../../../packages/assets/localization/localization';

export default function BulkActionConfirmationDialog() {
  const { bulkAction, gridApi } = useTasksDataTableState(
    (state) => ({ bulkAction: state.bulkAction, gridApi: state.gridApi }),
    shallow,
  );
  const affectedItemsCount =
    bulkAction?.affectedIds?.length ?? gridApi?.paginationGetRowCount();

  return (
    <PharmacyConfirmationModal
      show={!!bulkAction}
      onAccepted={() => {
        executeBulkAction(bulkAction!);
        removeBulkAction();
      }}
      onDismiss={() => removeBulkAction()}
      message={
        bulkAction
          ? getText('generic-bulk-confirmation-modal', {
              bulkAction: getBulkActionHumanName(bulkAction),
              count: affectedItemsCount,
              type:
                affectedItemsCount && affectedItemsCount > 1
                  ? getText('tasks')
                  : getText('task'),
              changeType: bulkAction.modifierName
                ? ` to "${bulkAction.modifierName}"`
                : '',
            })
          : ''
      }
    />
  );
}
