import {
  LocationPatientRecordDto,
  PatientRecordDto,
  UpdateLocationPatientRecordDto,
  UpdatePatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import patientServiceInstance from '../../api/PatientService';

export const updateLocationPatientRecord = async (
  locationId: string,
  id: string,
  patient: UpdateLocationPatientRecordDto,
): Promise<LocationPatientRecordDto | null> => {
  try {
    const result = await patientServiceInstance.updateLocationPatientRecord(
      locationId,
      id,
      patient,
    );
    return result;
  } catch (err) {
    return null;
  }
};

export const updatePatientRecord = async (
  id: string,
  patient: UpdatePatientRecordDto,
): Promise<PatientRecordDto | null> => {
  try {
    const result = await patientServiceInstance.updatePatientRecord(
      id,
      patient,
    );

    return result;
  } catch (err) {
    return null;
  }
};
