import { useEffect } from 'react';

export const useDebounceEffect = (
  callback: () => void,
  dependencies: ReadonlyArray<unknown>,
  delay = 500,
) => {
  let timer: ReturnType<typeof setTimeout> | null = null;

  const clear = () => {
    if (!timer) return;
    clearTimeout(timer);
    timer = null;
  };

  useEffect(() => {
    clear();

    timer = setTimeout(callback, delay);

    return () => {
      clear();
    };
  }, dependencies);
};
