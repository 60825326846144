import { UpdateAppointmentTypeDto } from '@digitalpharmacist/appointment-service-client-axios';
import { logError } from 'assets/logging/logger';
import AppointmentService from '../../api/AppointmentService';
import { useToast } from '../../common/hooks/useToast';
import { useAppStateStore } from '../../store/app-store';
import { useServicesListState } from './services-list-store';
import { getText } from 'assets/localization/localization';

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : 'An error occurred while trying to load the services. Please try again.';

  logError(error);
  useServicesListState.setState({
    error: {
      message: message,
    },
    status: 'error',
  });

  toast('Error', { type: 'error', content: message });
};

export const getServices = async (filters?: {
  withDisabled?: boolean;
  withoutNextAvailableSlot?: boolean;
}) => {
  const gridApi = useServicesListState.getState().gridApi;

  useServicesListState.setState({ status: 'loading' });
  gridApi?.showLoadingOverlay();

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    const response = await AppointmentService.findAppointmentTypes(
      pharmacyId,
      locationId,
      filters?.withDisabled,
      filters?.withoutNextAvailableSlot,
    );

    useServicesListState.setState({
      status: 'success',
      services: response,
    });
  } catch (error) {
    errorOccurred(error);
  } finally {
    gridApi?.hideOverlay();
  }
};

export const deleteService = async (serviceId: string) => {
  const gridApi = useServicesListState.getState().gridApi;

  useServicesListState.setState({ status: 'loading' });
  gridApi?.showLoadingOverlay();

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    const response = await AppointmentService.deleteAppointmentType(
      pharmacyId,
      locationId,
      serviceId,
    );

    useServicesListState.setState((prevState) => ({
      ...prevState,
      status: 'success',
      services: prevState.services?.filter(
        (service) => service.id !== response.id,
      ),
    }));
  } catch (error) {
    errorOccurred(error);
  } finally {
    gridApi?.hideOverlay();
  }
};

export const setServiceStatus = async (
  enabled: boolean,
  appointmentTypeId: string,
  appointmentType: UpdateAppointmentTypeDto,
) => {
  const { toast } = useToast();

  const services = useServicesListState.getState().services;

  useServicesListState.setState((prevState) => ({
    ...prevState,
    services: prevState.services?.map((service) => {
      if (service.id === appointmentTypeId) {
        return { ...service, enabled };
      }
      return service;
    }),
  }));

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    await AppointmentService.updateAppointmentType(
      pharmacyId,
      locationId,
      appointmentTypeId,
      {
        ...appointmentType,
        enabled,
      },
    );

    toast(getText('service-status-updated'), { type: 'success' });
  } catch (error: any) {
    useServicesListState.setState({
      services,
    });
    errorOccurred(error);
  }
};
