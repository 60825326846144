import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const UserXIcon: FunctionComponent<IconProps> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        d="M13.333 17.5V15.8333C13.333 14.9493 12.9818 14.1014 12.3567 13.4763C11.7316 12.8512 10.8837 12.5 9.99967 12.5H4.16634C3.28229 12.5 2.43444 12.8512 1.80932 13.4763C1.1842 14.1014 0.833008 14.9493 0.833008 15.8333V17.5M14.9997 6.66667L19.1663 10.8333M19.1663 6.66667L14.9997 10.8333M10.4163 5.83333C10.4163 7.67428 8.92396 9.16667 7.08301 9.16667C5.24206 9.16667 3.74967 7.67428 3.74967 5.83333C3.74967 3.99238 5.24206 2.5 7.08301 2.5C8.92396 2.5 10.4163 3.99238 10.4163 5.83333Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
