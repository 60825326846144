/* tslint:disable */
/* eslint-disable */
/**
 * user
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserVerification
 */
export interface UserVerification {
  /**
   * Code corresponding to verifying customer's account
   * @type {string}
   * @memberof UserVerification
   */
  code: string;
  /**
   * ID of customer's account to verify
   * @type {string}
   * @memberof UserVerification
   */
  userId: string;
}

export function UserVerificationFromJSON(json: any): UserVerification {
  return UserVerificationFromJSONTyped(json, false);
}

export function UserVerificationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserVerification {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: json['code'],
    userId: json['userId'],
  };
}

export function UserVerificationToJSON(value?: UserVerification | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    userId: value.userId,
  };
}
