import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { makeStyles, useTheme } from '../../theme';
import { Icon } from '../icon/Icon';
import { CircleBulletIcon } from '../../icons/CircleBulletIcon';
import { EmptyCircleIcon } from '../../icons';

export const BaseRadioButton: FunctionComponent<
  PropsWithChildren<BaseRadioButtonProps>
> = ({ selected, disabled }) => {
  const theme = useTheme();
  const styles = useStyles();
  const currentStateColor = disabled
    ? theme.palette.gray[300]
    : selected
    ? theme.palette.primary[500]
    : theme.palette.gray[700];

  return (
    <View
      style={[
        styles.radioButtonContainer,
        { backgroundColor: selected ? currentStateColor : 'transparent' },
      ]}
    >
      {selected ? (
        <Icon color={theme.palette.white} size={24} icon={CircleBulletIcon} />
      ) : (
        <Icon color={currentStateColor} size={24} icon={EmptyCircleIcon} />
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  radioButtonContainer: {
    borderRadius: 12,
    height: 24,
    width: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export interface BaseRadioButtonProps {
  disabled?: boolean;
  selected?: boolean;
}

export default BaseRadioButton;
