import { AppointmentTypeScheduleDto } from '@digitalpharmacist/appointment-service-client-axios';
import create from 'zustand';
import { ApiError, AsyncStatus } from '../../store/types';
import { AvailabilityOverride } from './AvailabilityForm';

export const useAvailabilityFormState = create<AvailabilityFormState>(() => ({
  submit: false,
  reset: false,
  status: 'idle',
}));

interface AvailabilityFormState {
  name?: string;
  submit: boolean;
  reset: boolean;
  status: AsyncStatus;
  error?: ApiError;
  schedule?: AppointmentTypeScheduleDto;
  overrideData?: AvailabilityOverride;
}
