import React, { FunctionComponent } from 'react';
import { RowClickedEvent } from '@ag-grid-community/core';
import { useNavigation } from '@react-navigation/native';
import { DataGrid } from 'assets/components/data-grid/DataGrid';
import { getSpacing, makeStyles } from 'assets/theme';
import { Text, TouchableOpacity, View } from 'react-native';

import { Icon } from 'assets/components/icon';
import { ShareIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { VideoModel } from '../../api/VideoService';
import {
  VideosDrawerNavigationParamList,
  VideosDrawerNavigationProp,
} from '../../layout/VideosDrawer';
import { useVideosState } from '../../store/videos-store';

const VideosListCollection: FunctionComponent = () => {
  const { videos, locale } = useVideosState();

  const navigation = useNavigation<VideosDrawerNavigationProp>();

  const handleEnRowClickEvent = (event: RowClickedEvent) => {
    const row: VideoModel = event.data as VideoModel;
    navigation.navigate('detail', {
      video_id: row.id,
      collection_page: 'list' as keyof VideosDrawerNavigationParamList,
      locale: locale,
    });
  };

  return (
    <>
      {/* TODO NOT READY*/}
      <DataGrid
        gridOptions={{
          rowData: videos,
          animateRows: true,
          columnDefs: [
            {
              field: 'category.label',
              headerName: getText('videos-category'),
              sortable: false,
              width: 50,
            },
            {
              field: 'name',
              headerName: getText('videos-title'),
              sortable: false,
            },
            {
              field: '',
              headerName: '',
              cellRenderer: ShareButtonRenderer,
              width: 25,
              // valueFormatter: function noRefCheck() {}
              suppressMenu: true,
              suppressColumnsToolPanel: true,
              suppressFiltersToolPanel: true,
              sortable: false,
            },
          ],

          defaultColDef: {
            sortable: false,
          },
          onRowClicked: handleEnRowClickEvent,
        }}
      />
    </>
  );
};

const ShareButtonRenderer = () => {
  const styles = useStyles();
  return (
    <View>
      <TouchableOpacity style={styles.rowView} onPress={() => {}}>
        <Icon icon={ShareIcon} color="#748995" size={16} />
        <Text style={styles.shareText}>{getText('videos-share')}</Text>
      </TouchableOpacity>
    </View>
  );
};

export default VideosListCollection;

const useStyles = makeStyles(() => ({
  rowView: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingTop: getSpacing(1),
  },
  shareText: {
    marginLeft: getSpacing(1),
    fontSize: 14,
    color: '#374D58',
    fontWeight: '400',
    lineHeight: 16,
  },
}));
