import React, { FunctionComponent } from 'react';
import { View } from 'react-native';

import { makeStyles } from 'assets/theme';
import { AppointmentsList } from '../../../schedule/appointments-list/AppointmentsList';

export const Appointments: FunctionComponent<AppointmentsProps> = (props) => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <AppointmentsList />
      </View>
    </View>
  );
};

interface AppointmentsProps {}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: theme.getSpacing(4),
    height: '100%',
  },
  cellContainer: {
    marginTop: theme.getSpacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
}));
