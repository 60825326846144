import { Icon } from 'assets/components/icon';
import { CloudUpload } from 'assets/icons';
import theme, { makeStyles } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import { View } from 'react-native';
import { Text } from 'assets/components/text';
import { LayoutChangeEvent } from 'react-native';

export const FileUploadElement: FunctionComponent<
  PropsWithChildren<FullNameElementProps>
> = () => {
  const styles = useStyles();

  const [iconBorderRadius, setIconBorderRadius] = useState<number>();
  const setBorderRadiusOnLayout = (event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout;
    setIconBorderRadius(height / 2);
  };

  return (
    <View style={styles.wrapper}>
      <View
        style={[styles.iconWrapper, { borderRadius: iconBorderRadius }]}
        onLayout={setBorderRadiusOnLayout}
      >
        <Icon icon={CloudUpload} color={theme.palette.gray['600']} />
      </View>
      <Text style={styles.uploadInstructionTitle}>Browse Files</Text>
      <Text style={styles.uploadInstructionSubTitle}>
        Drag and drop files here
      </Text>
    </View>
  );
};

interface FullNameElementProps {}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexDirection: 'column',
    display: 'flex',
    flexGrow: 1,
    backgroundColor: theme.palette.gray['100'],
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.gray['300'],
    borderRadius: theme.roundness,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 25,
  },
  iconWrapper: {
    padding: theme.getSpacing(1),
    backgroundColor: theme.palette.gray['200'],
  },
  uploadInstructionTitle: {
    color: theme.palette.gray['500'],
    fontSize: 18,
    fontWeight: '700',
    marginTop: theme.getSpacing(1),
  },
  uploadInstructionSubTitle: {
    color: theme.palette.gray['500'],
    fontSize: 14,
    marginTop: theme.getSpacing(0.5),
  },
}));
