import { View, Text } from 'react-native';
import { UserTyping } from '../hooks/useSockets';
import WritingBar from './WritingBar';
import { makeStyles } from 'assets/theme';
import { FC } from 'react';

interface FooterProps {
  typingMember?: UserTyping | null;
  text: string;
  conversationId: string;
}

const Footer: FC<FooterProps> = ({ typingMember, text, conversationId }) => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <WritingBar typingMember={typingMember} conversationId={conversationId} />
      <View style={styles.countBar}>
        <Text style={styles.countText}>{`${text.length}/5000`}</Text>
      </View>
    </View>
  );
};

export default Footer;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  countBar: {
    paddingBottom: 7,
    paddingRight: 10,
  },
  countText: {
    alignSelf: 'flex-end',
  },
}));
