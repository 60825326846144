import { ActionData } from 'gantt-schedule-timeline-calendar';
import { showTaskDetails } from '../tasks-data-table/tasks-data-table-actions';
import {
  collapseDetailsSidebar,
  getInfiniteScrollStatus,
  getStatus,
  isLastPage,
  loadTasks,
} from './tasks-grid-actions';
import { contextMenu, TriggerEvent } from 'react-contexify';

export const rowHoverAction = (element: HTMLElement, data: ActionData) => {
  const onMouseEnter = () => {
    const gridRowEl = document.querySelector(
      `.gstc__chart-timeline-grid-row[data-gstcid="${data.row.id}"]`,
    );
    const columnRowEl = document.querySelector(
      `.gstc__list-column-row[data-gstcid="${data.row.id}"]`,
    );
    columnRowEl?.classList.add('hover');
    gridRowEl?.classList.add('hover');
  };

  const onMouseLeave = () => {
    const gridRowEl = document.querySelector(
      `.gstc__chart-timeline-grid-row[data-gstcid="${data.row.id}"]`,
    );
    const columnRowEl = document.querySelector(
      `.gstc__list-column-row[data-gstcid="${data.row.id}"]`,
    );
    columnRowEl?.classList.remove('hover');
    gridRowEl?.classList.remove('hover');
  };

  element.addEventListener('mouseenter', onMouseEnter);
  element.addEventListener('mouseleave', onMouseLeave);

  return {
    update(element: HTMLElement, newData: unknown) {
      data = newData as ActionData;
    },

    destroy(element: HTMLElement) {
      element.removeEventListener('mouseenter', onMouseEnter);
      element.removeEventListener('mouseleave', onMouseLeave);
    },
  };
};

export const rowClickAction = (element: HTMLElement, data: ActionData) => {
  const onClick = () => {
    showTaskDetails(data.row.activeTask.id);
    collapseDetailsSidebar(false);
  };

  element.addEventListener('click', onClick);

  return {
    update(element: HTMLElement, newData: unknown) {
      data = newData as ActionData;
    },

    destroy(element: HTMLElement) {
      element.removeEventListener('click', onClick);
    },
  };
};

export const headerClickAction = (element: HTMLElement, data: ActionData) => {
  const onClick = (event: Event) => {
    if (
      !(event.target as HTMLElement).closest(
        '.gstc__list-column-header-resizer-container',
      )
    )
      return;

    contextMenu.show({ id: 'sorting', event: event as TriggerEvent });
  };

  element.addEventListener('click', onClick);

  return {
    update(element: HTMLElement, newData: unknown) {
      data = newData as ActionData;
    },

    destroy(element: HTMLElement) {
      element.removeEventListener('click', onClick);
    },
  };
};

export const infiniteScrollAction = (
  element: HTMLElement,
  data: ActionData,
) => {
  return {
    update(element: HTMLElement, newData: unknown) {
      data = newData as ActionData;

      if (
        data.state.data.$data.scroll.vertical.percent >= 95 &&
        getInfiniteScrollStatus() !== 'loading' &&
        getStatus() !== 'loading' &&
        !isLastPage()
      ) {
        loadTasks();
      }
    },
  };
};

export const initialLoadAction = (element: HTMLElement, data: ActionData) => {
  if (!data.state.data.$data.scroll.vertical.visible && !isLastPage()) {
    loadTasks();
  }
};

export const resizeAction = (element: HTMLElement, data: ActionData) => {
  const onResize = () => {
    if (window.innerWidth > 1740) {
      element.style.width = '500px';
      element.style.setProperty('--width', '500px');
    }

    if (window.innerWidth <= 1740) {
      element.style.width = '300px';
      element.style.setProperty('--width', '300px');
    }

    if (window.innerWidth <= 1550) {
      element.style.width = '220px';
      element.style.setProperty('--width', '220px');
    }
  };

  onResize();
  window.addEventListener('resize', onResize);

  return {
    destroy() {
      window.removeEventListener('resize', onResize);
    },
  };
};
