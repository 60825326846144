import React, { useCallback } from 'react';
import { useBottomSheetInternal } from '@gorhom/bottom-sheet';
import { TextInput } from 'react-native-paper';

export const BottomSheetInput: React.FunctionComponent<
  React.ComponentProps<typeof TextInput>
> = ({ onFocus, onBlur, ...rest }) => {
  //#region hooks
  const { shouldHandleKeyboardEvents } = useBottomSheetInternal();
  //#endregion

  //#region callbacks
  const handleOnFocus = useCallback(
    (args: any) => {
      shouldHandleKeyboardEvents.value = true;
      if (onFocus) {
        onFocus(args);
      }
    },
    [onFocus, shouldHandleKeyboardEvents],
  );
  const handleOnBlur = useCallback(
    (args: any) => {
      shouldHandleKeyboardEvents.value = false;
      if (onBlur) {
        onBlur(args);
      }
    },
    [onBlur, shouldHandleKeyboardEvents],
  );
  //#endregion

  return <TextInput onFocus={handleOnFocus} onBlur={handleOnBlur} {...rest} />;
};
