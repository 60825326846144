import { FunctionComponent, PropsWithChildren } from 'react';
import { DrawerScreenProps } from '@react-navigation/drawer';

import { Submission } from '../../forms/submission/Submission';

export const ViewSubmission: FunctionComponent<
  PropsWithChildren<ViewSubmissionProps>
> = ({ route }) => {
  const submissionId = route.params?.submissionId;
  const formId = route.params?.formId;

  return <Submission formId={formId} submissionId={submissionId} />;
};

type ViewSubmissionProps = DrawerScreenProps<any, any>;
{
}
