import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
} from 'react';
import { View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { FormPreviewBlankIcon } from './FormPreviewBlankIcon';
import { FormPreviewTemplateIcon } from './FormPreviewTemplateIcon';
import { Text } from 'assets/components/text';
import { FormImportPreviewIcon } from './FormImportPreviewIcon';
import { GlobeIcon, LockIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';

const calculatePreviewComponent = (
  title: string,
  accessType: string,
): ReactElement => {
  const theme = useTheme();
  const styles = useStyles();

  switch (title) {
    case 'Blank Form':
      return <FormPreviewBlankIcon />;
    case 'Import Form':
      return <FormImportPreviewIcon />;
    default:
      return (
        <>
          <FormPreviewTemplateIcon />
          <View style={styles.formPreviewAccess}>
            <Text style={styles.formPreviewAccessText}>
              {accessType === 'PRIVATE'
                ? getText('private')
                : getText('public')}
            </Text>
            {accessType === 'PRIVATE' ? (
              <LockIcon size={16} color={theme.palette.gray[500]} />
            ) : (
              <GlobeIcon size={16} color={theme.palette.gray[500]} />
            )}
          </View>
        </>
      );
  }
};

export const FormPreview: FunctionComponent<
  PropsWithChildren<FormPreviewProps>
> = (props) => {
  const styles = useStyles();

  return (
    <View style={styles.formPreviewContainer}>
      <View
        style={[
          styles.formPreview,
          props.selected && styles.formPreviewSelected,
        ]}
      >
        {/* TODO: switch to checking for form type, when that's available */}
        {calculatePreviewComponent(props.title, props.accessType)}
      </View>
      <Text
        style={[
          styles.formPreviewTitle,
          props.selected && styles.formPreviewTitleActive,
        ]}
        selectable
      >
        {props.title}
      </Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  formPreviewContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  formPreview: {
    borderWidth: 2,
    borderColor: theme.palette.gray[300],
    width: 120,
    height: 155,
  },
  formPreviewSelected: {
    borderWidth: 2,
    borderColor: theme.colors.pharmacyPrimary,
  },
  formPreviewTitle: {
    maxWidth: 100,
    marginTop: theme.getSpacing(1),
    color: theme.palette.gray[500],
  },
  formPreviewTitleActive: {
    color: theme.palette.gray[700],
  },
  formPreviewAccess: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    padding: theme.getSpacing(1),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formPreviewAccessText: {
    color: theme.palette.gray[500],
  },
}));

interface FormPreviewProps {
  title: string;
  accessType: string;
  selected?: boolean;
}

export default FormPreview;
