import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const YoutubeIcon: FunctionComponent<IconProps> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        stroke="color"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.783 5.35a2.316 2.316 0 0 0-1.616-1.667c-1.434-.35-7.167-.35-7.167-.35s-5.733 0-7.167.384a2.317 2.317 0 0 0-1.616 1.666 24.167 24.167 0 0 0-.384 4.409c-.01 1.489.12 2.976.384 4.441a2.316 2.316 0 0 0 1.616 1.6c1.434.384 7.167.384 7.167.384s5.733 0 7.167-.384a2.317 2.317 0 0 0 1.616-1.666c.26-1.444.389-2.908.384-4.375.009-1.49-.12-2.976-.384-4.442Z"
      />
      <Path
        stroke="color"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m8.125 12.517 4.792-2.725-4.792-2.725v5.45Z"
      />
    </Svg>
  );
};
